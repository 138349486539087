import React, { useState } from "react";
import { Box, IconButton, makeStyles, TextField, Tooltip, Typography } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import * as Yup from "yup";
import { FiPlus, FiMinus } from "react-icons/fi";

export const camelSchema = Yup.object().shape({
  camelCAPVerSuppMSCInboundList: Yup.array()
    .of(
      Yup.string()
        .max(3)
        .test(
          "invalid",
          "CAMEL INBOUND must be a valid value, between 0 and 999",
          (value) => !value || (Number.isInteger(Number(value)) && Number(value) >= 0 && Number(value) <= 999)
        )
        .label("CAMEL INBOUND")
    ),
  camelCAPVerSuppMSCOutboundList: Yup.array()
    .of(
      Yup.string()
        .max(3)
        .test(
          "invalid",
          "CAMEL OUTBOUND must be a valid value, between 0 and 999",
          (value) => !value || (Number.isInteger(Number(value)) && Number(value) >= 0 && Number(value) <= 999)
        )
        .label("CAMEL OUTBOUND")
    ),
});

export const camelInitialValues = {
  camelCAPVerSuppMSCInboundList: [""],
  camelCAPVerSuppMSCOutboundList: [""],
};

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
  lists: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  listTitle: {
    alignSelf: "center",
    fontSize: "1.2rem",
    fontWeight: 500,
    margin: "15px 0px",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",

    "& div": {
      flex: 1,
    },

    "& + div": {
      marginTop: 15,
    },

    "& div:only-child": {
      marginRight: 48,
    },
  },
});

function Ir21TemplateCamel() {
  const { errors, values, setFieldValue } = useFormikContext();
  const classes = useStyles();

  const [extraInboundInputs, setExtraInboundInputs] = useState(
    values?.camelCAPVerSuppMSCInboundList?.length > 0 ? values.camelCAPVerSuppMSCInboundList.length - 1 : 0
  );
  const [extraOutboundInputs, setExtraOutboundInputs] = useState(
    values?.camelCAPVerSuppMSCOutboundList?.length > 0 ? values.camelCAPVerSuppMSCOutboundList.length - 1 : 0
  );

  function handleAddInboundInput() {
    const array = values.camelCAPVerSuppMSCInboundList;
    array.push("");

    setFieldValue("camelCAPVerSuppMSCInboundList", array);
    setExtraInboundInputs((current) => current + 1);
  }

  function handleRemoveInboundInput() {
    const array = values.camelCAPVerSuppMSCInboundList;
    array.pop();

    setFieldValue("camelCAPVerSuppMSCInboundList", array);
    setExtraInboundInputs((current) => current - 1);
  }

  function handleAddOutboundInput() {
    const array = values.camelCAPVerSuppMSCOutboundList;
    array.push("");

    setFieldValue("camelCAPVerSuppMSCOutboundList", array);
    setExtraOutboundInputs((current) => current + 1);
  }

  function handleRemoveOutboundInput() {
    const array = values.camelCAPVerSuppMSCOutboundList;
    array.pop();

    setFieldValue("camelCAPVerSuppMSCOutboundList", array);
    setExtraOutboundInputs((current) => current - 1);
  }

  function hasInboundError(arrayIndex) {
    if (errors) {
      if (errors.camelCAPVerSuppMSCInboundList) {
        if (errors.camelCAPVerSuppMSCInboundList[arrayIndex]) {
          return true;
        }
      }
    }
    return false;
  }

  function hasOutboundError(arrayIndex) {
    if (errors) {
      if (errors.camelCAPVerSuppMSCOutboundList) {
        if (errors.camelCAPVerSuppMSCOutboundList[arrayIndex]) {
          return true;
        }
      }
    }
    return false;
  }

  function getInboundErrorMessage(arrayIndex) {
    if (errors) {
      if (errors.camelCAPVerSuppMSCInboundList) {
        if (typeof errors.camelCAPVerSuppMSCInboundList === "string") {
          return errors.camelCAPVerSuppMSCInboundList;
        } else if (errors.camelCAPVerSuppMSCInboundList[arrayIndex]) {
          return errors.camelCAPVerSuppMSCInboundList[arrayIndex];
        }
      }
    }
    return "";
  }

  function getOutboundErrorMessage(arrayIndex) {
    if (errors) {
      if (errors.camelCAPVerSuppMSCOutboundList) {
        if (typeof errors.camelCAPVerSuppMSCOutboundList === "string") {
          return errors.camelCAPVerSuppMSCOutboundList;
        } else if (errors.camelCAPVerSuppMSCOutboundList[arrayIndex]) {
          return errors.camelCAPVerSuppMSCOutboundList[arrayIndex];
        }
      }
    }
    return "";
  }

  return (
    <>
      <TextField label="TADIG" defaultValue=" " value={values.plmnName} disabled variant="outlined" />
      <Box className={classes.container}>
        <Box className={classes.lists}>
          <Typography className={classes.listTitle}>INBOUND LIST</Typography>
          <Box className={classes.inputContainer}>
            <Field
              as={TextField}
              label="INBOUND"
              name="camelCAPVerSuppMSCInboundList[0]"
              variant="outlined"
              error={hasInboundError(0)}
              helperText={getInboundErrorMessage(0)}
            />
            <Tooltip title="ADD MORE">
              <IconButton onClick={handleAddInboundInput}>
                <FiPlus size="1.5rem" />
              </IconButton>
            </Tooltip>
          </Box>
          {[...Array(Number(extraInboundInputs))].map((value, index) => (
            <Box className={classes.inputContainer}>
              <Field
                as={TextField}
                label="INBOUND"
                name={`camelCAPVerSuppMSCInboundList[${index + 1}]`}
                variant="outlined"
                error={hasInboundError(index + 1)}
                helperText={getInboundErrorMessage(index + 1)}
              />
              {index + 1 === extraInboundInputs && (
                <Tooltip title="REMOVE">
                  <IconButton onClick={handleRemoveInboundInput}>
                    <FiMinus size="1.5rem" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          ))}
        </Box>
        <Box className={classes.lists}>
          <Typography className={classes.listTitle}>OUTBOUND LIST</Typography>
          <Box className={classes.inputContainer}>
            <Field
              as={TextField}
              label="OUTBOUND"
              name="camelCAPVerSuppMSCOutboundList[0]"
              variant="outlined"
              error={hasOutboundError(0)}
              helperText={getOutboundErrorMessage(0)}
            />
            <Tooltip title="ADD MORE">
              <IconButton onClick={handleAddOutboundInput}>
                <FiPlus size="1.5rem" />
              </IconButton>
            </Tooltip>
          </Box>
          {[...Array(Number(extraOutboundInputs))].map((value, index) => (
            <Box className={classes.inputContainer}>
              <Field
                as={TextField}
                label="OUTBOUND"
                name={`camelCAPVerSuppMSCOutboundList[${index + 1}]`}
                variant="outlined"
                error={hasOutboundError(index + 1)}
                helperText={getOutboundErrorMessage(index + 1)}
              />
              {index + 1 === extraOutboundInputs && (
                <Tooltip title="REMOVE">
                  <IconButton onClick={handleRemoveOutboundInput}>
                    <FiMinus size="1.5rem" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}

export default Ir21TemplateCamel;
