import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FiFilter } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ErrorUnexpected } from "../../../const/Messages";
import * as ReportService from "../../../service/ReportService";

/**
 * @author Thiago Guimarães
 * @date 16/04/2021
 */
function ReportErrorSummary({
  vendorId,
  elementTypeId,
  commandId,
  reportTitle,
}) {
  const history = useHistory();
  const [reportList, setReportList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cursor, setCursor] = useState("pointer");
  const exportDataRef = useRef(null);

  useEffect(() => {
    const param = {
      vendorId,
      elementTypeId,
      commandId,
    };

    ReportService.getReportByErrorView(param)
      .then((reports) => setReportList(reports))
      .catch(() => toast.error(ErrorUnexpected))
      .finally(() => setIsLoading(false));
  }, [vendorId, elementTypeId, commandId]); // eslint-disable-line

  function gotoDetail(field) {
    history.push(`${history.location.pathname}/${field}`);
  }

  const [columns] = useState([
    {
      title: "FIELD",
      field: "field",
      render: (row) => (
        <span onClick={() => gotoDetail(row.field)} className="btn btn-link">
          {row.field}
        </span>
      ),
    },
    { title: "COUNT", field: "count" },
  ]);

  function excel() {
    if (exportDataRef.current) {
      exportDataRef.current.disabled = true;
      setCursor("progress");
    }

    const param = {
      vendorId,
      elementTypeId,
      commandId,
    };

    ReportService.getReportByErrorExportData(param)
      .catch(() => {
        toast.error(ErrorUnexpected);
      })
      .finally(() => {
        if (exportDataRef.current) {
          setCursor("pointer");
          exportDataRef.current.disabled = false;
        }
      });
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between align-items-center">
            <h5>{reportTitle}</h5>
            <Button
              variant="primary"
              onClick={excel}
              disabled={false}
              ref={exportDataRef}
              style={{ cursor: cursor }}
            >
              EXPORT DATA
            </Button>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={reportList}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              icons={{
                Filter: () => <FiFilter />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 15,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: `${reportTitle} - Errors By Field`,
                search: false,
                filtering: true,
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ReportErrorSummary;
