import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import { ErrorUnexpected, OperationSuccessfully } from "../../const/Messages";
import * as IgnoredCasesService from "../../service/IgnoredCasesService";
import IgnoredCasesModal from "./IgnoredCasesModal";

/**
 * @author Guilherme Almeida
 * @date 04/06/2021
 */
function IgnoredCases({ vendorId, elementTypeId, commandId, keyLabel, title }) {
  const [ignoredList, setIgnoredList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [titleLabel, setTitleLabel] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    setTitleLabel(title);

    IgnoredCasesService.findAll(commandId)
      .then((list) => setIgnoredList(list))
      .catch(() => toast.error(ErrorUnexpected))
      .finally(() => setIsLoading(false));
  }, [commandId]); // eslint-disable-line

  function handleDelete(id) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        IgnoredCasesService.deleteById(commandId, id)
          .then(() => {
            IgnoredCasesService.findAll(commandId)
              .then((ignoredCasesList) => {
                setIgnoredList(ignoredCasesList);
                toast.success(OperationSuccessfully);
              })
              .catch(() => {
                toast.error(ErrorUnexpected);
              });
          })
          .catch(() => {
            toast.error(ErrorUnexpected);
          });
      }
    });
  }

  function handleModal(obj) {
    setModalData({ fields: obj.fields, values: obj.values });
    setShowModal(true);
  }

  const [columns] = useState([
    {
      title: "ELEMENT",
      field: "elementName",
    },
    {
      title: keyLabel,
      field: "key",
      render: (obj) => {
        const uniqueId = "id" + Math.random().toString(16).slice(2);

        return (
          <span
            data-tip
            data-for={uniqueId}
            className="text-primary font-weight-bold"
            style={{ cursor: "pointer" }}
            onClick={() => handleModal(obj)}
          >
            {obj.key}
            <ReactTooltip place="top" id={uniqueId}>
              <table>
                <tbody>
                  {obj &&
                    obj.fields &&
                    obj.fields.map((fieldName, indexKey) => (
                      <tr key={indexKey}>
                        <td>{fieldName}: </td>
                        <td>
                          {obj.values[indexKey] &&
                          obj.values[indexKey] !== "null"
                            ? obj.values[indexKey]
                            : ""}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </ReactTooltip>
          </span>
        );
      },
    },
    {
      title: "USER NAME",
      field: "userAccountName",
    },
    {
      title: "DESCRIPTION",
      field: "description",
      render: (obj) => {
        const uniqueId = "id" + Math.random().toString(16).slice(2);

        return (
          <span data-tip data-for={uniqueId} data-place="top">
            {obj && obj.description && obj.description.length > 20
              ? obj.description.substr(0, 20) + " ..."
              : obj.description}
            <ReactTooltip place="top" id={uniqueId}>
              {obj.description}
            </ReactTooltip>
          </span>
        );
      },
    },
  ]);

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between align-items-center">
            <h5>Ignored Cases - {titleLabel}</h5>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={ignoredList}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: `ignored-cases`,
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
              actions={[
                {
                  icon: () => <FiTrash2 size={16} color="#007bff" />,
                  tooltip: "DELETE",
                  onClick: (event, rowData) => handleDelete(rowData.id),
                },
              ]}
            />
            <IgnoredCasesModal
              data={modalData}
              setShowModal={setShowModal}
              showModal={showModal}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default IgnoredCases;
