import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { ErrorUnexpected, OperationSuccessfully } from "../../../../../const/Messages";
import * as ImsiChangeRulesService from "../../../../../service/ImsiChangeRulesService";
import Loading from "../../../../Common/Loading/Loading";

const initialValues = {
  imsiReplaced: "",
  imsiIr21: "",
};

/**
 * @author Thiago Guimarães
 * @date 09/04/2021
 */
function ImsiChangeRuleRegister() {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(initialValues);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location && location.state && location.state.id) {
      ImsiChangeRulesService.getById(location.state.id)
        .then((imsiChangeRule) => setValues(imsiChangeRule))
        .catch((error) => {
          history.push("/settings/report/imsi-change-rules", {
            message: ErrorUnexpected,
            messageType: "error",
          });
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []); // eslint-disable-line

  const schema = Yup.object().shape({
    imsiReplaced: Yup.string().required("Changed IMSI name is a required field"),
    imsiIr21: Yup.string().required("IR.21 IMSI is a required field"),
  });

  function handleSave(values, actions) {
    let id = null;

    if (location && location.state && location.state.id) {
      id = location.state.id;
    }

    ImsiChangeRulesService.merge(id, values)
      .then((response) => {
        history.push("/settings/report/imsi-change-rules", {
          message: OperationSuccessfully,
          messageType: "success",
        });
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          toast.error(ErrorUnexpected);
        } else if (error.response.data.fields) {
          error.response.data.fields.forEach((field) => {
            actions.setFieldError(field.name, field.message);
          });
        } else if (error.response.data.title) {
          actions.setFieldError("imsiReplaced", error.response.data.title);
        } else {
          toast.error(ErrorUnexpected);
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  }

  function handleDelete(imsiChangeRule) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ImsiChangeRulesService.deleteById(imsiChangeRule.id).then(() => {
          history.push("/settings/report/imsi-change-rules", {
            message: OperationSuccessfully,
            messageType: "success",
          });
        });
      }
    });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>Imsi Change Rules - Register</h5>
          </div>
          <div className="ibox-content">
            <Formik initialValues={values} validationSchema={schema} onSubmit={handleSave}>
              {({ values, isSubmitting }) => (
                <Form>
                  <div className="form-group row">
                    <label htmlFor="imsiReplaced" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      CHANGED IMSI:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field name="imsiReplaced" className="form-control"></Field>
                      <span className="text-danger">
                        <ErrorMessage name="imsiReplaced" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="imsiIr21" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      IR.21 IMSI:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field name="imsiIr21" className="form-control"></Field>
                      <span className="text-danger">
                        <ErrorMessage name="imsiIr21" />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Link className="mr-1 btn btn-primary" to="/settings/report/imsi-change-rules">
                      Cancel
                    </Link>
                    {location && location.state && location.state.id && (
                      <Button className="mr-1" onClick={() => handleDelete(values)} variant="primary" type="button">
                        Delete
                      </Button>
                    )}
                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                      {location && location.state && location.state.id ? "Update" : "Save"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ImsiChangeRuleRegister;
