import React, { useState, useEffect } from "react";
import Loading from "../../../../Common/Loading/Loading";
import { Col, Row, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import * as OrganisationService from "../../../../../service/OrganisationService";
import { ErrorUnexpected, OperationSuccessfully } from "../../../../../const/Messages";

const initialValues = {
  name: "",
};

/**
  @author Guilherme Almeida
  @date 10/02/2021
 */
export default function ManagementOrganisationRegister({ location }) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(initialValues);

  useEffect(() => {

    if (location && location.state && location.state.id) {
      OrganisationService.getById(location.state.id)
        .then((organisation) => {
          setValues(organisation)
          setLoading(false)
        })
        .catch(() => {
          history.push("/ir21/settings/organisations", {message: ErrorUnexpected , messageType:"error"});
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const schema = Yup.object().shape({
      name: Yup.string().required("Name is a required field"),
  });

  function handleSave(values, actions) {

    let id = null;
    if (location && location.state && location.state.id) {
      id = location.state.id;
    }

    OrganisationService.merge(id, values)
      .then((response) => {
        history.push("/ir21/settings/organisations", { message : OperationSuccessfully , messageType : "success"});
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          actions.setFieldError(
            "name",
            ErrorUnexpected
          );
        } else if (error.response.data.title) {
          actions.setFieldError("name", error.response.data.title);
        }
        actions.setSubmitting(false);
      })
  }

  function handleDelete(id) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        OrganisationService.deleteById(id).then(() => {
          history.push("/ir21/settings/organisations", { message : OperationSuccessfully , messageType : "success"});
        });
      }
    });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>Organisation - Register</h5>
          </div>
          <div className="ibox-content">
            <Formik
              initialValues={values}
              validationSchema={schema}
              onSubmit={handleSave}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="form-group row">
                    <label htmlFor="name" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      Organisation Name:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field
                        className="form-control"
                        name="name"
                        type="text"
                        placeholder="Name"
                      />
                      <span className="text-danger">
                        <ErrorMessage name="name" />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Link
                      className="mr-1 btn btn-primary"
                      to="/ir21/settings/organisations"
                    >
                      Cancel
                    </Link>
                    {location && location.state && location.state.id && (
                      <Button
                        className="mr-1"
                        onClick={() => handleDelete(location.state.id)}
                        variant="primary"
                        type="button"
                      >
                        Delete
                      </Button>
                    )}
                    <Button variant="primary" type="submit">
                      {location && location.state && location.state.id
                        ? "Update"
                        : "Save"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
}