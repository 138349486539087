import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListSubheader,
  Popover,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import {
  IoCheckmarkCircleSharp,
  IoClose,
  IoNotificationsCircle,
  IoTime,
} from "react-icons/io5";
import Moment from "react-moment";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ErrorUnexpected, OperationSuccessfully } from "../../const/Messages";
import * as AlertService from "../../service/AlertService";

/**
 *  @author Thiago Guimarães
 *  @date 16/09/2021
 */
function AlertPopover() {
  const anchorRef = useRef(null);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [totalUnRead, setTotalUnRead] = useState(0);
  const [newAlerts, setNewAlerts] = useState([]);
  const [historyAlerts, setHistoryAlerts] = useState([]);

  useEffect(() => {
    updateData();
  }, [location]);

  const updateData = () => {
    AlertService.countNewsAlerts().then((count) => setTotalUnRead(count));
    AlertService.findLast5Read().then((alerts) => setHistoryAlerts(alerts));
    AlertService.findNews().then((alerts) => setNewAlerts(alerts));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAsRead = (alert) => {
    AlertService.markAsRead(alert.id)
      .then(() => {
        toast.success(OperationSuccessfully);
        updateData();
      })
      .catch(() => toast.error(ErrorUnexpected));
  };

  const NotificationItem = ({ alert }) => {
    return (
      <Box
        style={{
          transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          backgroundColor: "rgba(145, 158, 171, 0.16)",
          padding: "15px 20px",
          marginBottom: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography variant="body2">{`${alert.functionalityName}: ${alert.message}`}</Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <IoTime size={12} />
            <Typography
              variant="caption"
              style={{ marginLeft: 5, fontSize: 10 }}
            >
              <Moment format="DD/MM/yyyy HH:mm:ss">
                {alert.occurrenceDate}
              </Moment>
            </Typography>
          </Box>
        </Box>
        {!alert.readed && (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => handleMarkAsRead(alert)}>
              <IoCheckmarkCircleSharp size={15} color="gray" />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        style={{
          ...(open && {
            bgcolor: "#c1c1c1",
          }),
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <IoNotificationsCircle color="white" size={33} />
        </Badge>
      </IconButton>
      <Popover
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          style: {
            mt: 1.5,
            ml: 0.5,
            overflow: "inherit",
            boxShadow: (theme) => theme.customShadows.z20,
            border: (theme) => `solid 1px ${theme.palette.grey[500_8]}`,
            width: 360,
          },
        }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        style={{ zIndex: 3001, marginLeft: "-3rem" }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            padding: "16px 20px",
          }}
        >
          <Box style={{ flexGrow: 1 }}>
            <Typography variant="subtitle2" style={{ fontSize: "1rem" }}>
              Notifications
            </Typography>
            <Typography variant="body2" color="textSecondary">
              You have {totalUnRead} unread messages
            </Typography>
          </Box>
          <IconButton
            color={open ? "primary" : "default"}
            onClick={handleClose}
            style={{
              ...(open && {
                bgcolor: "#c1c1c1",
              }),
            }}
          >
            <IoClose color="grey" size={25} />
          </IconButton>
        </Box>
        <Divider />
        {(newAlerts.length > 0 || historyAlerts.length > 0) && (
          <div
            style={{
              overflow: "auto",
              maxHeight: `calc(100vh - 13rem)`,
            }}
          >
            <List
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  style={{
                    padding: "8px 20px",
                    fontWeight: 600,
                    lineHeight: 1.5,
                    letterSpacing: "1.1px",
                    fontSize: "0.75rem",
                  }}
                >
                  NEW
                </ListSubheader>
              }
            >
              {newAlerts.slice(0, 5).map((alert) => (
                <NotificationItem key={alert.id} alert={alert} />
              ))}
            </List>
            {newAlerts.length < 5 && (
              <List
                disablePadding
                subheader={
                  <ListSubheader
                    disableSticky
                    style={{
                      padding: "8px 20px",
                      fontWeight: 600,
                      lineHeight: 1.5,
                      letterSpacing: "1.1px",
                      fontSize: "0.75rem",
                    }}
                  >
                    HISTORY
                  </ListSubheader>
                }
              >
                {historyAlerts.slice(0, 5 - newAlerts.length).map((alert) => (
                  <NotificationItem key={alert.id} alert={alert} />
                ))}
              </List>
            )}
          </div>
        )}
        <Box style={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            component={Link}
            to="/alerts"
            onClick={handleClose}
          >
            View All
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default AlertPopover;
