import React, { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import { Col, Row } from "react-bootstrap";
import { BsArrowLeftRight } from "react-icons/bs";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ErrorUnexpected, OperationSuccessfully } from "../../../../const/Messages";
import * as UserService from "../../../../service/UserService";


/**
* @author Guilherme Almeida
* @date 02/09/2021
*/
function VertisUserList() {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    UserService.findAll()
      .then((userList) => {
        setUsers(userList);
      })
      .catch(() => {
        toast.error(ErrorUnexpected);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Role",
      field: "roles[0].name",
      render: (row) => 
        row.roles.map(role=>role.name).join(", ")
      
    },
  ];

  function handleChangeRole(user) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: `Ao confirmar, irá Alterar/Remover a role VERTIS. Cuidado, caso remova sua propria role VERTIS, será incapaz de atribuí-la novamente.`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.changeRole(user.id)
          .then(() => {
            UserService.findAll()
            .then((userList) => {
              toast.success(OperationSuccessfully);
              setUsers(userList);
            })
            .catch(() => {
              toast.error(ErrorUnexpected);
            });
          })
          .catch(() => {
            toast.error(ErrorUnexpected);
          });
      }
    });
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between">
            <h5>Users List</h5>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={users}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                headerStyle: {
                  textTransform: "uppercase",
                }
              }}
              actions={[
                {
                  icon: () => <BsArrowLeftRight size={16} color="#007bff" />,
                  tooltip: "CHANGE ROLE",
                  onClick: (event, rowData) => handleChangeRole(rowData),
                },
              ]}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default VertisUserList;
