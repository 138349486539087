import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ErrorUnexpected } from "../../../../../const/Messages";
import * as ImsiChangeRulesService from "../../../../../service/ImsiChangeRulesService";

/**
 * @author Thiago Guimarães
 * @date 09/04/2021
 */
function ImsiChangeRuleList() {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ImsiChangeRulesService.findAll()
      .then((imsiChangeRules) => setList(imsiChangeRules))
      .catch(() => toast.error(ErrorUnexpected))
      .finally(() => setIsLoading(false));
  }, []); // eslint-disable-line

  function handleDelete(imsiChangeRule) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: `Are you sure you want to delete?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ImsiChangeRulesService.deleteById(imsiChangeRule.id)
          .then(() => {
            setIsLoading(true);

            ImsiChangeRulesService.findAll()
              .then((imsiChangeRules) => setList(imsiChangeRules))
              .catch(() => toast.error(ErrorUnexpected))
              .finally(() => setIsLoading(false));
          })
          .catch(() => {
            toast.error(ErrorUnexpected);
          });
      }
    });
  }

  const columns = [
    { title: "CHANGED IMSI", field: "imsiReplaced" },
    { title: "IR.21 IMSI", field: "imsiIr21" },
  ];

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between align-items-center">
            <h5>Imsi Change Rules - List</h5>
            <div>
              <Link
                className="btn btn-primary"
                to="/settings/report/imsi-change-rules/reg"
              >
                New Rule
              </Link>
            </div>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={list}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "elements",
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
              actions={[
                {
                  icon: () => <FiEdit size={16} color="#007bff" />,
                  tooltip: "EDIT",
                  onClick: (event, rowData) =>
                    history.push("/settings/report/imsi-change-rules/reg", {
                      id: rowData.id,
                    }),
                },
                {
                  icon: () => <FiTrash2 size={16} color="#007bff" />,
                  tooltip: "DELETE",
                  onClick: (event, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ImsiChangeRuleList;
