import { Paper, TextField } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FiArrowLeft, FiDownload, FiRefreshCw } from "react-icons/fi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  ErrorUnexpected,
  OperationSuccessfully,
} from "../../../../../../const/Messages";
import * as OperatorPartnersUploadService from "../../../../../../service/OperatorPartnerUploadService";
import RoamingPartnersUploadUploadModal from "./RoamingPartnersUploadUploadModal";
/**
  @author Guilherme Almeida
  @date 26/06/2024
 */
export default function RoamingPartnersUploadList() {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [list, setList] = useState([]);
  let dateFilter = { start: "", end: "" };
  const tableRef = useRef(null);

  // Carrega os dados ao iniciar a tela e ao fechar um dos modais.
  useEffect(() => {
    if (!showModal) {
      updateData();
    }
  }, [showModal]);

  function updateData() {
    OperatorPartnersUploadService.findAll()
      .then((data) => setList(data))
      .catch(() => toast.error(ErrorUnexpected))
      .finally(() => setIsLoading(false));
  }

  function downloadLogById(id) {
    OperatorPartnersUploadService.download(id).catch((error) => {
      if (error && error.response && error.response.status === 404) {
        toast.error("File not available.");
      } else {
        toast.error(ErrorUnexpected);
      }
    });
  }

  function handleRerunById(id) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to rerun?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        OperatorPartnersUploadService.rerunById(id)
          .then(() => {
            toast.success(OperationSuccessfully);
            updateData();
          })
          .catch((error) => {
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.title
            ) {
              toast.error(error.response.data.title);
            } else {
              toast.error(ErrorUnexpected);
            }
          })
          .finally(() => setIsLoading(false));
      }
    });
  }

  function handleOpenUploadModal() {
    setShowModal(true);
  }

  function handleSampleDownload() {
    if (!waiting) {
      setWaiting(true);

      OperatorPartnersUploadService.downloadSample()
        .catch((error) => {
          if (error && error.response && error.response.status === 404) {
            toast.error("File not available.");
          } else {
            toast.error(ErrorUnexpected);
          }
        })
        .finally(() => setWaiting(false));
    }
  }

  function handleGoBack() {
    history.push("/settings/partners/roaming-partners");
  }

  const columns = [
    {
      title: "DATE",
      field: "date",
      render: (row) => moment(row.date).format("DD/MM/yyyy HH:mm:ss"),
      defaultSort: "desc",
      filterComponent: (props) => (
        <>
          <TextField
            {...props}
            label="START"
            type="date"
            value={dateFilter.start}
            className="mr-2"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              dateFilter = { ...dateFilter, start: event.target.value };
              props.onFilterChanged(props.columnDef.tableData.id, "FILTER");
            }}
          />
          <TextField
            {...props}
            label="END"
            type="date"
            value={dateFilter.end}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              dateFilter = { ...dateFilter, end: event.target.value };
              props.onFilterChanged(props.columnDef.tableData.id, "FILTER");
            }}
          />
        </>
      ),
      customFilterAndSearch: (term, row) => {
        const date = moment(row.date);

        if (term === "FILTER") {
          if (dateFilter && (dateFilter.start || dateFilter.end)) {
            if (dateFilter.start && dateFilter.end) {
              return (
                date.isBetween(dateFilter.start, dateFilter.end, "day") ||
                date.isSame(dateFilter.start, "day") ||
                date.isSame(dateFilter.end, "day")
              );
            } else if (dateFilter.start) {
              return date.isSameOrAfter(dateFilter.start, "day");
            } else if (dateFilter.end) {
              return date.isSameOrBefore(dateFilter.end, "day");
            }
          } else {
            return true;
          }
        } else {
          return date.format("DD/MM/yyyy HH:mm:ss").indexOf(term) !== -1;
        }
      },
      customSort: (obj1, obj2) => new Date(obj1.date) - new Date(obj2.date),
    },
    {
      title: "File Name",
      field: "fileName",
    },
    {
      title: "USER",
      field: "userAccountName",
    },
    {
      title: "STATUS",
      field: "status",
    },
  ];

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between">
            <h5>
              <FiArrowLeft
                className="mr-2"
                size="22"
                color="gray"
                style={{ verticalAlign: "middle", cursor: "pointer" }}
                onClick={handleGoBack}
              />
              Roaming Partners Upload
            </h5>
            <div>
              <Button
                className="mr-2"
                variant="primary"
                type="button"
                onClick={() => handleSampleDownload()}
              >
                Sample
              </Button>
              <Button
                className="mr-2"
                variant="primary"
                type="button"
                onClick={() => handleOpenUploadModal()}
              >
                Upload
              </Button>
            </div>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              tableRef={tableRef}
              data={list}
              columns={columns}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: false,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                search: false,
                filtering: true,
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
              isLoading={isLoading}
              actions={[
                {
                  icon: "refresh",
                  tooltip: "Refresh Data",
                  isFreeAction: true,
                  onClick: () => updateData(),
                },
                (row) => {
                  console.log(row);
                  if (row.filePresent) {
                    return {
                      icon: () => <FiDownload size={16} color="#007bff" />,
                      tooltip: "DOWNLOAD LOG",
                      onClick: (event, rowData) => downloadLogById(rowData.id),
                    };
                  }
                },
                (row) => {
                  if (row.filePresent) {
                    return {
                      icon: () => <FiRefreshCw size={16} color="#007bff" />,
                      tooltip: "RE-RUN",
                      onClick: (event, rowData) => handleRerunById(rowData.id),
                    };
                  }
                },
              ]}
            />
            <RoamingPartnersUploadUploadModal
              show={showModal}
              setShow={setShowModal}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
