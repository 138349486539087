import api from "./api";

export function getAmountElementErrorsToday() {
  return new Promise((resolve, reject) => {
    api
      .get("/elements-error-today")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getAmountLogsProcessedToday() {
  return new Promise((resolve, reject) => {
    api
      .get("/logs-processed-today")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getAmountLogsProcessed(startIsoStringDate, endIsoStringDate) {
  return new Promise((resolve, reject) => {
    api
      .get("/logs-processed", {
        params: { start: startIsoStringDate, end: endIsoStringDate },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getAmountProcessedLogsLastSevenDays() {
  return new Promise((resolve, reject) => {
    api
      .get("/logs-processed-last-seven-days")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getAmountUpdatedIr21(startIsoStringDate, endIsoStringDate) {
  return new Promise((resolve, reject) => {
    api
      .get("/ir21-updated", {
        params: { start: startIsoStringDate, end: endIsoStringDate },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getAmountUpdatedIr21Week() {
  return new Promise((resolve, reject) => {
    api
      .get("/ir21-lastweek")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getAmountUpdatedIr21LastFifteenDays() {
  return new Promise((resolve, reject) => {
    api
      .get("/ir21-last-fifteen-days")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getDashboardWorldData(params) {
  return new Promise((resolve, reject) => {
    api
      .get("/worlmap-data", { params })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getErrorsByElementType() {
  return new Promise((resolve, reject) => {
    api
      .get("/elements-types-errors")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
