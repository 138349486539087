import api from "./api";

export function deleteById(userId) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/users/${userId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/users")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function findById(userId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/users/${userId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetPassword(userId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/users/${userId}/reset`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function merge(user) {
  return new Promise((resolve, reject) => {
    api({
      method: user.id ? "put" : "post",
      url: user.id ? `/users/${user.id}` : "/users",
      data: user,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function update(userId, user) {
  return new Promise((resolve, reject) => {
    api
      .put(`/users/${userId}/profile`, user)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updatePassword(userId, passwords) {
  return new Promise((resolve, reject) => {
    api
      .put(`/users/${userId}/password`, passwords)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

//Valida se o usuario está com o TOKEN valido.
export function validUser() {
  return new Promise((resolve, reject) => {
    api
      .get("/logged-user")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function changeRole(userId) {
  return new Promise((resolve, reject) => {
    api
      .put(`/vertis/users/${userId}/changerole`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function findList() {
  return new Promise((resolve, reject) => {
    api
      .get("/users/list")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}