import api from "./api";

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/vertis/application-log")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function download(fileName) {
  return new Promise((resolve, reject) => {
    api
      .get(`/vertis/application-log/download/${fileName}`, { responseType: "blob" })
      .then((response) => {
        let fileName = "file.text";
        if (response.headers && response.headers["content-disposition"]) {
          fileName = response.headers["content-disposition"].split("filename=")[1].replaceAll('"', "").replaceAll("%20", " ");
        }

        const blob = new Blob([response.data], {
          type: "text/plain",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);    

        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function viewDocument(fileName) {
  return new Promise((resolve, reject) => {
    api
      .get(`/vertis/application-log/download/${fileName}`)
      .then((response) => {

        const blob = new Blob([response.data], {
          type: "text/plain",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        // document.body.removeChild(link);
        // window.URL.revokeObjectURL(url);    

        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}
