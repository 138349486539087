import api from "./api";

export function getNetworkElementReportSummary(summaryParam) {
  return new Promise((resolve, reject) => {
    api
      .get("/reports/network-element/summary", { params: summaryParam })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getNetworkElementReportDetail(snapshotUploadElementId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/reports/network-element/detail/${snapshotUploadElementId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getReportByErrorView(params) {
  return new Promise((resolve, reject) => {
    api
      .get("/reports/error/", { params })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getReportByErrorExportData(params) {
  return new Promise((resolve, reject) => {
    api
      .get(`/reports/error/export`, { responseType: "blob", params })
      .then((response) => {
        let fileName = "file.xls";
        if (response.headers && response.headers["content-disposition"]) {
          fileName = response.headers["content-disposition"]
            .split("filename=")[1]
            .replaceAll('"', "")
            .replaceAll("%20", " ");
        }

        const blob = new Blob([response.data], {
          type: "application/excel",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getReportByErrorDetailView(params) {
  return new Promise((resolve, reject) => {
    api
      .get("/reports/error/detail", { params })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getReportByErrorDetailViewRtExit(params) {
  return new Promise((resolve, reject) => {
    api
      .get("/reports/error/detail/rtexit", { params })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getReportCountryView(countryId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/reports/country/${countryId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getReportDetail(params) {
  return new Promise((resolve, reject) => {
    api
      .get("/reports/detail", { params })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getReportTadig() {
  return new Promise((resolve, reject) => {
    api
      .get("reports/tadig")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getReportTadigDetail(plmnId) {
  return new Promise((resolve, reject) => {
    api
      .get(`reports/tadig/${plmnId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function saveIgnoredCases(param) {
  return new Promise((resolve, reject) => {
    api
      .post("reports/ignored-cases", param)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteIgnoredCases(param) {
  return new Promise((resolve, reject) => {
    api
      .post("reports/ignored-cases/delete", param)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
