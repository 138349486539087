import React from "react";
import "./LoadingBar.css";

function LoadingBar({ percent }) {
  return (
    <div className="d-flex">
      <div className="load-bar" style={{ width: `${percent}%` }}></div>
      <div className="load-bar-inverse" style={{ width: `${100 - percent}%` }}></div>
    </div>
  );
}

export default LoadingBar;
