import api from "./api";

export function getF5eDnsDefaultParameters() {
  return new Promise((resolve, reject) => {
    api
      .get("/f5-edns-default-parameters")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/f5-edns-default-parameters/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function update(values) {
  return new Promise((resolve, reject) => {
    api({
      method: "put",
      url: `/f5-edns-default-parameters`,
      data: values,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
