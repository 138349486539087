import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { FiArrowLeft, FiCheck, FiFilter, FiX } from "react-icons/fi";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import ReportModal from "../../../../../components/Report/Modal/ReportModal";
import {
  ErrorUnexpected,
  OperationSuccessfully,
} from "../../../../../const/Messages";
import * as ReportService from "../../../../../service/ReportService";

/**
 * @author Thiago Guimarães
 * @date 23/02/2021
 */
function ReportDetail() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  const [report, setReport] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (id) {
      ReportService.getNetworkElementReportDetail(id)
        .then((report) => {
          setReport(report);

          // Pega o label da chave do report para adicionar ao cabeçalho da lista.
          if (report) {
            const clone = [...columns];
            clone.splice(2, 0, {
              title: report.keyLabel,
              field: "key",
              render: (obj) => {
                return (
                  <span
                    className="text-primary font-weight-bold"
                    style={{ cursor: "pointer", whiteSpace: "pre" }}
                    onClick={() => handleModal(obj)}
                  >
                    {obj.key}
                  </span>
                );
              },
            });

            setColumns(clone);
          }
        })
        .catch(() => {
          history.push("/report/element-view", {
            message: ErrorUnexpected,
            messageType: "error",
          });
        })
        .finally(() => setIsLoading(false));
    } else {
      // Retorna para a listagem caso o id não esteja carregado.
      handleGoBack();
    }
  }, []); // eslint-disable-line

  function handleGoBack() {
    history.push("/report/element-view");
  }

  function handleRemoveIgnoreCase(rowData) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to notice?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const param = {
          resultId: rowData.resultId,
          elementTypeId: rowData.elementTypeId,
          vendorId: rowData.vendorId,
          commandId: rowData.commandId,
        };

        ReportService.deleteIgnoredCases(param)
          .then(() => {
            toast.success(OperationSuccessfully);
          })
          .catch((error) => {
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.title
            ) {
              toast.error(error.response.data.title);
            } else {
              toast.error(ErrorUnexpected);
            }
          });
      }
    });
  }

  function handleAddIgnoreCase(rowData) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to ignored the next analyse?",
      type: "warning",
      html: `
       <div id="swal2-content" class="swal2-html-container" style="display: block;">Are you sure you want to ignored the next analyse?</div>
       <textarea  id="reason"
                  aria-label="Type your reason here" 
                  class="swal2-textarea" 
                  placeholder="Type your reason here..." 
                  style="display: flex; margin-top: 20.25px; margin-bottom: 20.25px; height: 112px;"></textarea>
        <div>
          <input type="checkbox" id="allElementsRule" name="allElementsRule"/>
          <label for="allElementsRule">Apply rule for all elements?</label>
        </div>`,
      preConfirm: () => {
        const reason = document.querySelector("#reason").value;
        const isAllElements =
          document.querySelector("#allElementsRule").checked;

        if (reason && reason.length > 0) {
          return [reason, isAllElements];
        } else {
          Swal.showValidationMessage(
            '<i class="fa fa-info-circle"></i> The reason is required.'
          );
        }
      },
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const [reason, isAllElements] = result.value;
        const param = {
          resultId: rowData.resultId,
          elementTypeId: rowData.elementTypeId,
          vendorId: rowData.vendorId,
          commandId: rowData.commandId,
          reason,
          isAllElements,
        };

        ReportService.saveIgnoredCases(param)
          .then(() => {
            toast.success(OperationSuccessfully);
          })
          .catch((error) => {
            if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.title
            ) {
              toast.error(error.response.data.title);
            } else {
              toast.error(ErrorUnexpected);
            }
          });
      }
    });
  }

  function handleModal(report) {
    const param = {
      resultId: report.resultId,
      elementTypeId: report.elementTypeId,
      vendorId: report.vendorId,
      commandId: report.commandId,
    };

    ReportService.getReportDetail(param)
      .then((reportDetail) => {
        setModalData(reportDetail);
        setShowModal(true);
      })
      .catch((error) => toast.error(ErrorUnexpected));
  }

  const [columns, setColumns] = useState([
    {
      title: " ",
      field: "error",
      render: (obj) => {
        return obj.error ? <FiX color="red" /> : <FiCheck color="green" />;
      },
      customSort: (obj1, obj2) => {
        if (obj1.error === obj2.error) {
          return 0;
        } else if (obj1.error) {
          return -1;
        } else {
          return 1;
        }
      },
      width: "1%",
      lookup: { true: "With Errors", false: "No Errors" },
    },
    {
      title: "RESULT",
      field: "comparisonResult",
      render: (obj) => obj.comparisonResult.toUpperCase(),
    },
    // A coluna Key está sendo montando no useEffect.
    {
      title: "TADIG",
      field: "plmnName",
    },
    {
      title: "COUNTRY",
      field: "countryName",
    },
    {
      title: "",
      field: "ignored",
      render: (obj) => {
        const ignoredCommand = [2, 21];
        if (!ignoredCommand.includes(obj.commandId) && obj.key) {
          if (obj.ignored) {
            return (
              <>
                <BsEye
                  data-tip
                  data-for="notice"
                  size={20}
                  color="green"
                  onClick={() => handleRemoveIgnoreCase(obj)}
                />
                <ReactTooltip id="notice">NOTICE</ReactTooltip>
              </>
            );
          } else {
            return (
              <>
                <BsEyeSlash
                  data-tip
                  data-for="ignore"
                  size={20}
                  color="red"
                  onClick={() => handleAddIgnoreCase(obj)}
                />
                <ReactTooltip id="ignore">IGNORE</ReactTooltip>
              </>
            );
          }
        }
      },
      lookup: { true: "IGNORE", false: "NOTICE" },
      width: "1%",
    },
  ]);

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>
              <FiArrowLeft
                className="mr-2"
                size="22"
                color="gray"
                style={{ verticalAlign: "middle", cursor: "pointer" }}
                onClick={handleGoBack}
              />
              {report.commandName} - {report.elementName}
            </h5>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={report.reports}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              icons={{
                Filter: () => <FiFilter />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "report-list",
                search: false,
                filtering: true,
                showTitle: false,
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
            />
            <ReportModal
              data={modalData}
              setShowModal={setShowModal}
              showModal={showModal}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ReportDetail;
