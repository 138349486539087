import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  ErrorUnexpected,
  OperationSuccessfully,
} from "../../../../../../const/Messages";
import * as TemplateService from "../../../../../../service/TemplateHuaweiUgwService";

/**
  @author Guilherme Almeida
  @date 16/08/2024
 */
export default function TemplateHuaweiUgwList() {
  const [template, setTemplate] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    refresh();
  }, []); // eslint-disable-line

  function refresh() {
    TemplateService.findAll()
      .then((templateList) => {
        setTemplate(templateList);
      })
      .catch(() => {
        toast.error(ErrorUnexpected);
      })
      .finally(() => setIsLoading(false));
  }

  function handleSave(data) {
    setIsLoading(true);
    return TemplateService.merge(data.id, data)
      .then(() => {
        toast.success(OperationSuccessfully);
        refresh();
      })
      .catch((error) => {
        toast.error(ErrorUnexpected);
      });
  }

  const columns = [
    {
      title: "Element",
      field: "name",
      editable: "never",
    },
    {
      title: "Actual APN",
      field: "actualApn",
      editable: "onUpdate",
    },
  ];

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between align-items-center">
            <h5>Template - Huawei UGW</h5>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={template}
              isLoading={isLoading}
              editable={{
                onRowUpdate: (newData, oldData) => handleSave(newData),
              }}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: false,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "template-ugw-huawei",
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
