import api from "./api";

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/templateericssonhlr")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/templateericssonhlr/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function merge(id, templateEricssonHlr) {

  return new Promise((resolve, reject) => {
    api({
      method: id ? "put" : "post",
      url: id ? `/templateericssonhlr/${id}` : "/templateericssonhlr",
      data: templateEricssonHlr,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteById(id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/templateericssonhlr/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
