import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  makeStyles,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FiFileText, FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import { ErrorUnexpected } from "../../const/Messages";
import * as Ir21Service from "../../service/Ir21Service";

const useStyles = makeStyles((theme) => {
  return {
    general: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    modal: {
      zIndex: "3001 !important",
    },
    modalContent: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",

      width: "100%",
      height: "100%",
      maxHeight: "calc(100vh - 10%)",
      [theme.breakpoints.up("lg")]: {
        maxWidth: "calc(100vw - 70%)",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "calc(100vw - 15%)",
      },
      overflow: "scroll",
    },
    button: {
      //marginRight: "1rem",
      cursor: "pointer",

      transition: "filter 0.2s",
      "&:hover": {
        filter: "brightness(0.8)",
      },
    },
    closeButton: {
      position: "absolute",
      right: "1rem",
      top: "0.5rem",
      color: "#b7b7b7",
      cursor: "pointer",

      transition: "filter 0.2s",
      "&:hover": {
        filter: "brightness(0.8)",
      },
    },
    title: {
      textTransform: "uppercase",
      fontStyle: "italic",
    },
    tableContainer: {
      margin: "1rem auto",
      maxWidth: 600,
      width: "100%",
    },
    categoryBox: {
      "& + div": {
        marginTop: "2rem",
      },
    },
    verticalList: {
      "& span": {
        width: 75,
        fontWeight: 500,
        display: "inline-block",
        marginRight: 5,
      },
    },
  };
});

function Ir21TemplateModal({ modalData, handleClose }) {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (modalData.isOpen) {
      Ir21Service.getIr21ValuesByTadig(modalData.plmnId)
        .then((values) => setData(values))
        .catch(() => toast.error(ErrorUnexpected));
    }
  }, [modalData]);

  function exportData(plmnId) {
    setIsLoading(true);
    Ir21Service.getExportData(plmnId)
      .catch((error) => {
        toast.error(ErrorUnexpected);
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <Modal
      open={modalData.isOpen}
      onClose={handleClose}
      className={classes.modal}
    >
      <Card className={classes.modalContent}>
        <CardHeader
          title={
            <Box>
              <Typography variant="h5">IR.21 Values</Typography>
              <FiX onClick={handleClose} className={classes.closeButton} />
            </Box>
          }
        />
        <Divider />
        <CardContent className={classes.cardContent}>
          <Box className={classes.categoryBox}>
            <Box className={classes.general}>
              <Typography variant="h6" className={classes.title}>
                General
              </Typography>
              {isLoading ? (
                <CircularProgress disableShrink size={18} color="#676a6c" />
              ) : (
                <FiFileText
                  onClick={() => exportData(modalData.plmnId)}
                  className={classes.button}
                  color="#676a6c"
                  size={18}
                  data-tip
                  data-for="tooptip"
                />
              )}
              <ReactTooltip
                id="tooptip"
                type="dark"
                effect="solid"
                place="left"
              >
                <span>Export</span>
              </ReactTooltip>
            </Box>

            <Divider />
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.verticalList}>
                      <span>TADIG</span>
                      {": " + data.plmnName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.verticalList}>
                      <span>Type</span>
                      {": " +
                        (!data.hasIr21 ? "" : data.isIr85 ? "IR.85" : "IR.21")}
                      {data.ir21Template ? " (TEMPLATE)" : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.verticalList}>
                      <span>MCC</span>
                      {": " + (data.e212?.mcc || "")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.verticalList}>
                      <span>MNC</span>
                      {": " + (data.e212?.mnc || "")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.verticalList}>
                      <span>MGT CC</span>
                      {": " + (data.e214?.mgtcc || "")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.verticalList}>
                      <span>MGT NC</span>
                      {": " + (data.e214?.mgtnc || "")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className={classes.categoryBox}>
            <Typography variant="h6" className={classes.title}>
              Map Version
            </Typography>
            <Divider />
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.verticalList}>
                      <span>INBOUND</span>
                      {": " + (data.mapVersion?.inboundRoamingMscVlr || "")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.verticalList}>
                      <span>OUTBOUND</span>
                      {": " + (data.mapVersion?.outboundRoaming || "")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className={classes.categoryBox}>
            <Typography variant="h6" className={classes.title}>
              APN Operator Identifiers
            </Typography>
            <Divider />
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table size="small">
                <TableBody>
                  {data.apnOperatorIdentifierList?.map((apn) => (
                    <TableRow>
                      <TableCell>{apn}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className={classes.categoryBox}>
            <Typography variant="h6" className={classes.title}>
              Camel
            </Typography>
            <Divider />
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.verticalList}>
                      <span>INBOUND</span>
                      {": " + data.camelCAPVerSuppMSCInboundList?.join(", ")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.verticalList}>
                      <span>OUTBOUND</span>
                      {": " + data.camelCAPVerSuppMSCOutboundList?.join(", ")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className={classes.categoryBox}>
            <Typography variant="h6" className={classes.title}>
              Global Title
            </Typography>
            <Divider />
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>CC</TableCell>
                    <TableCell>NDC</TableCell>
                    <TableCell>Range Start</TableCell>
                    <TableCell>Range Stop</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.e164NumberSeriesList?.map((globalTitle) => (
                    <TableRow>
                      <TableCell>{globalTitle.cc}</TableCell>
                      <TableCell>{globalTitle.ndc}</TableCell>
                      <TableCell>{globalTitle.rangeStart}</TableCell>
                      <TableCell>{globalTitle.rangeStop}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className={classes.categoryBox}>
            <Typography variant="h6" className={classes.title}>
              EPC Realms For Roaming
            </Typography>
            <Divider />
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table size="small">
                <TableBody>
                  {data.epcRealmsForRoamingList?.map((realm) => (
                    <TableRow>
                      <TableCell>{realm}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
}

export default Ir21TemplateModal;
