import React, { useEffect, useRef, useState } from 'react';
import { Paper, TextField } from "@material-ui/core";
import { Button, Col, Row } from 'react-bootstrap';
import { FiDownload, FiRefreshCw } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { ErrorUnexpected } from '../../../const/Messages';
import MaterialTable from "material-table";
import LogBatchUploadModal from "./LogBatchUploadModal";
import LogSingleUploadModal from './LogSingleUploadModal';
import LogSamplesModal from './LogSamplesModal';
import moment from 'moment';
import * as NeLogsFileService from "../../../service/NeLogsFileService";
import * as ElementTypeService from "../../../service/ElementTypeService";
import * as VendorService from "../../../service/VendorService";
import Swal from 'sweetalert2';

/**
 * @author Thiago Guimarães
 * @date 01/09/2021
 */
function LogsUpload() {
  const [showBatchModal, setShowBatchModal] = useState(false);
  const [showSingleModal, setShowSingleModal] = useState(false);
  const [showSamplesModal, setShowSamplesModal] = useState(false);
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [vendorList, setVendorList] = useState([]);
  const [elementTypeList, setElementTypeList] = useState([]);
  const tableRef = useRef(null);
  let dateFilter = { start: "", end: "" };

  // Carrega os dados ao iniciar a tela e ao fechar um dos modais.
  useEffect(() => {
    if(!showBatchModal && !showSingleModal){
      updateData();
    }
  }, [showBatchModal, showSingleModal ])

   useEffect(() => {
    VendorService.findAll()
      .then((vendors) => setVendorList(vendors))
      .catch(() => toast.error(ErrorUnexpected));

    ElementTypeService.findAll()
      .then((elementTypes) => setElementTypeList(elementTypes))
      .catch(() => toast.error(ErrorUnexpected));

  }, []); // eslint-disable-line

  function handleOpenBatchUploadModal(){
    setShowBatchModal(true);
  }

  function handleOpenSingleUploadModal(){
    setShowSingleModal(true);
  }

  function handleOpenSamplesModal(){
    setShowSamplesModal(true);
  }

  function downloadLogById(id){
    NeLogsFileService
    .download(id)
    .catch( () => toast.error(ErrorUnexpected));
  }

  function updateData(){
    setIsLoading(true);

    NeLogsFileService.list()
      .then((data) => setList(data))
      .catch(() => toast.error(ErrorUnexpected))
      .finally(() => setIsLoading(false));
  }

  function handleRerunLogById(id) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to rerun?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        NeLogsFileService
        .rerunLogById(id)
        .then(()=> updateData())
        .catch((error) => {
          if (error && error.response && error.response.data && error.response.data.title) {
            toast.error(error.response.data.title);
          } else {
            toast.error(ErrorUnexpected);
          }
        });
      }
    });
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between align-items-center">
            <h5 className="d-flex align-items-center">
              <span>Upload Logs</span>
            </h5>
            <div>
              <Button className="mr-2" variant="primary" type="button" onClick={() => handleOpenBatchUploadModal()}>
                Batch
              </Button>
              <Button className="mr-2" variant="primary" type="button" onClick={() => handleOpenSingleUploadModal()}>
                Individual
              </Button>
              <Button className="mr-2" variant="primary" type="button" onClick={() => handleOpenSamplesModal()}>
                Samples
              </Button>
            </div>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              tableRef={tableRef}
              data={list}
              columns={[
                {
                  title: "DATE",
                  field: "date",
                  render: (row) => moment(row.date).format("DD/MM/yyyy HH:mm:ss"),
                  defaultSort: 'desc',
                  filterComponent: (props) => (
                    <>
                      <TextField
                        {...props}
                        label="START"
                        type="date"
                        value={dateFilter.start}
                        className="mr-2"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(event) => {
                          dateFilter = { ...dateFilter, start: event.target.value };
                          props.onFilterChanged(props.columnDef.tableData.id, "FILTER");
                        }}
                      />
                      <TextField
                        {...props}
                        label="END"
                        type="date"
                        value={dateFilter.end}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(event) => {
                          dateFilter = { ...dateFilter, end: event.target.value };
                          props.onFilterChanged(props.columnDef.tableData.id, "FILTER");
                        }}
                      />
                    </>
                  ),
                  customFilterAndSearch: (term, row) => {
                    const date = moment(row.date);
            
                    if (term === "FILTER") {
            
                      if(dateFilter && (dateFilter.start || dateFilter.end)){
                        if (dateFilter.start && dateFilter.end) {
                          return (
                            date.isBetween(dateFilter.start, dateFilter.end, "day") ||
                            date.isSame(dateFilter.start, "day") ||
                            date.isSame(dateFilter.end, "day")
                          );
                        } else if (dateFilter.start) {
                          return date.isSameOrAfter(dateFilter.start, "day");
                        } else if (dateFilter.end) {
                          return date.isSameOrBefore(dateFilter.end, "day");
                        }
                      }else{
                        return true;
                      }
                    } else {
                      return date.format("DD/MM/yyyy HH:mm:ss").indexOf(term) !== -1;
                    }
                  },
                  customSort: (obj1, obj2) => new Date(obj1.date) - new Date(obj2.date),
                },
                {
                  title: "STATUS",
                  field: "status",
                },
                {
                  title: "USER",
                  field: "userAccountName",
                },
                {
                  title: "VENDOR",
                  field: "vendor.name",
                  lookup: Object.fromEntries(vendorList.map((vendor) => [vendor.name, vendor.name])),
                },
                {
                  title: "ELEMENT TYPE",
                  field: "elementType.name",
                  lookup: Object.fromEntries(elementTypeList.map((elementType) => [elementType.name, elementType.name])),
                },
                {
                  title: "ELEMENT",
                  field: "element.name",
                },
              ]}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: false,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                search: false,
                filtering: true,
                headerStyle: {
                  textTransform: "uppercase",
                }
              }}
              isLoading={isLoading}
              actions={[
                {
                  icon: "refresh",
                  tooltip: "Refresh Data",
                  isFreeAction: true,
                  onClick: () => updateData(),
                },
                (row) => {
                  if (row.filePresent) {
                    return {
                      icon: () => <FiDownload size={16} color="#007bff" />,
                      tooltip: "DOWNLOAD LOG",
                      onClick: (event, rowData) => downloadLogById(rowData.id),
                    };
                  }
                },
                (row) => {
                  if (row.filePresent) {
                    return {
                      icon: () => <FiRefreshCw size={16} color="#007bff" />,
                      tooltip: "RE-RUN",
                      onClick: (event, rowData) => handleRerunLogById(rowData.id),
                    };
                  }
                },
              ]}
            />
            <LogBatchUploadModal show={showBatchModal} setShow={setShowBatchModal} />
            <LogSingleUploadModal show={showSingleModal} setShow={setShowSingleModal} />
            <LogSamplesModal show={showSamplesModal} setShow={setShowSamplesModal} />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default LogsUpload;