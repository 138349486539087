import React from "react";
import { Form, Formik } from "formik";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ErrorUnexpected, OperationSuccessfully } from "../../../../const/Messages";
import * as Yup from "yup";
import * as Ir21Service from "../../../../service/Ir21Service";

/**
 * @author Guilherme Almeida
 * @date 23/02/2021
 */
export default function UploadIr21Modal({ modal, setModal }) {
  const SUPPORTED_XML_FORMATS = ["text/xml"];
  const SUPPORTED_PDF_FORMATS = ["application/pdf"];

  const schema = Yup.object().shape({
    file: Yup.mixed()
      .required("A xml file is required")
      .test("fileFormat", "Unsupported Format", (value) => value && SUPPORTED_XML_FORMATS.includes(value.type)),
    pdfFile: Yup.mixed().test(
      "fileFormat",
      "Unsupported Format",
      (value) => !value || SUPPORTED_PDF_FORMATS.includes(value.type)
    ),
  });

  function handleCloseModal() {
    setModal(false);
  }

  function handleUpload(values, actions) {
    const formData = new FormData();
    formData.append("file", values.file);
    formData.append("pdfFile", values.pdfFile);

    Ir21Service.upload(formData)
      .then(() => {
        toast.success(OperationSuccessfully);
        handleCloseModal(true);
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          toast.error(ErrorUnexpected);

        } else if (error.response.data.fields) {
          const fields = error.response.data.fields;

          fields.forEach((field) => {
            actions.setFieldError(field.name, field.message);
          });

        } else if (error.response.data.title) {
          actions.setFieldError("file", error.response.data.title);
          
        } else {
          toast.error(ErrorUnexpected);
        }
      })
      .finally(() => actions.setSubmitting(false));
  }

  return (
    <Modal show={modal} onHide={handleCloseModal} size="lg" animation={false}>
      <Formik initialValues={{}} validationSchema={schema} onSubmit={handleUpload}>
        {({ values, setFieldValue, isSubmitting, errors }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>File - Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
              <div className="form-group row">
                <label htmlFor="file" className="col-sm-3 col-form-label">
                  XML File:
                </label>
                <div className="custom-file col-sm-6">
                  <input
                    name="file"
                    type="file"
                    accept="application/xml"
                    className="custom-file-input form-control"
                    onChange={(ev) => {
                      setFieldValue("file", ev.target.files[0]);
                    }}
                  />
                  <label className="custom-file-label form-control" htmlFor="file">
                    {values.file ? values.file.name : "Choose file"}
                  </label>
                  <span className="text-danger">{errors.file && errors.file}</span>
                </div>
              </div>

              <div className="form-group row mt-4">
                <label htmlFor="pdfFile" className="col-sm-3 col-form-label">
                  PDF File:
                </label>
                <div className="custom-file col-sm-6">
                  <input
                    name="pdfFile"
                    type="file"
                    accept="application/pdf"
                    className="custom-file-input form-control"
                    onChange={(ev) => {
                      setFieldValue("pdfFile", ev.target.files[0]);
                    }}
                  />
                  <label className="custom-file-label form-control" htmlFor="pdfFile">
                    {values.pdfFile ? values.pdfFile.name : "Choose file"}
                  </label>
                  <span className="text-danger">{errors.pdfFile && errors.pdfFile}</span>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <div className="mt-3 mr-4 d-flex justify-content-end">
                <Button className="mr-1" onClick={handleCloseModal} variant="primary" type="button">
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Upload
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
