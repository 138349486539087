import { createContext, useState, useContext, useEffect } from "react";

const MenuContext = createContext({});

export function MenuProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [subMenu, setSubMenu] = useState(undefined);
  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    setIsOpen(false);
    setSubMenu(undefined);
  }, [currentPage])

  function closeMenu(){
    setIsOpen(false);
    setSubMenu(undefined);
  }

  function openMenu(){
    setIsOpen(true);
  }

  function toogleMenu() {
    setSubMenu(undefined);
    setIsOpen(!isOpen);
  }

  function closeSubMenu(){
    setSubMenu(undefined);
  }

  function openSubMenu(menu){
    setSubMenu(menu);
  }

  return <MenuContext.Provider value={{ isOpen, setIsOpen, toogleMenu, closeMenu, openMenu, closeSubMenu, openSubMenu, subMenu, currentPage, setCurrentPage }}>{children}</MenuContext.Provider>;
}

export const useMenuContext = () => {
  return useContext(MenuContext);
}