import React, { useState, useEffect } from "react";
import Loading from "../../../../../Common/Loading/Loading";
import { Col, Row, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as HuaweiPtsDefaultParametersService from "../../../../../../service/HuaweiPtsDefaultParametersService";
import { ErrorUnexpected, OperationSuccessfully } from "../../../../../../const/Messages";

const initialValues = {
  id: "",
  ignoredCasesLog: false,
  ignoredCasesIr21: false,
  e164AllPrefixes: false,
};

/**
  @author Guilherme Almeida
  @date 13/08/2021
 */
export default function HuaweiPtsDefaultParameters() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    HuaweiPtsDefaultParametersService.getHuaweiPtsDefaultParameters()
        .then((setup) => {
          const data = { 
            ...setup, 
            e164AllPrefixes: setup.e164AllPrefixes ? "true" : "false"  
          };
          setValues(data);
          setLoading(false);
        })
        .catch(() => {
          history.push("/", { message: ErrorUnexpected, messageType: "error" });
        });
  }, []); // eslint-disable-line

  const schema = Yup.object().shape({});

  function handleSave(values, actions) {

    HuaweiPtsDefaultParametersService.update(values)
      .then(() => {
        history.push("/settings/report/template/pts/huawei/default", { message: OperationSuccessfully, messageType: "success" });
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          actions.setFieldError("ignoredCasesLog", ErrorUnexpected);
        } else if (error.response.data.title) {
          actions.setFieldError("ignoredCasesLog", error.response.data.title);
        }
        actions.setSubmitting(false);
      });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>Huawei PTS Default Parameters</h5>
          </div>
          <div className="ibox-content">
            <Formik initialValues={values} validationSchema={schema} onSubmit={handleSave}>
              {() => (
                <Form>
                  <div className="form-group row">
                    <label
                      htmlFor="ignoredCases"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Ignored Cases:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <div className="d-flex align-items-baseline">
                        <Field type="checkbox" name="ignoredCasesLog"/>
                          <label className="form-check-label pl-2">
                            Ignore value from LOG.
                          </label>
                        <span className="text-danger">
                          <ErrorMessage name="ignoredCasesLog" />
                        </span>
                      </div>
                      <div className="d-flex align-items-baseline">
                        <Field type="checkbox" name="ignoredCasesIr21" />
                        <label className="form-check-label pl-2">
                          Ignore value from IR21.
                        </label>
                        <span className="text-danger">
                          <ErrorMessage name="ignoredCasesIr21" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="e164AllPrefixes"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Global Title:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <div className="form-check form-check-inline">
                        <Field type="radio" name="e164AllPrefixes" value="false" id="boxTrue" className="form-check-input"/>
                        <label className="form-check-label" htmlFor="boxTrue">
                          Single prefix
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <Field type="radio" name="e164AllPrefixes" value="true" id="boxFalse" className="form-check-input"/>
                        <label className="form-check-label" htmlFor="boxFalse">
                          All prefixes
                        </label>
                      </div>
                      <span className="text-danger">
                        <ErrorMessage name="e164AllPrefixes" />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Link className="mr-1 btn btn-primary" to="/settings">
                      Cancel
                    </Link>
                    <Button variant="primary" type="submit">
                      Update
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
}
