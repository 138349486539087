import {
  Button,
  Card,
  CardContent,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import LoginLayout from "../../../components/LoginLayout/LoginLayout";
import { ErrorUnexpected } from "../../../const/Messages";
import { Context } from "../../../context/Context";

const useStyles = makeStyles((theme) => {
  return {
    loginBox: {
      position: "absolute",

      [theme.breakpoints.up("sm")]: {
        top: "calc(100vh - 80%)",
        left: "calc(100vw - 90%)",
      },
      [theme.breakpoints.down("xs")]: {
        top: "calc(100vh - 50%)",
        left: "calc(100vw - 50%)",
        transform: "translate(-50%, -50%)",
      },
    },
    titles: {
      marginBottom: "25px",
      color: "white",

      "& h1": {
        [theme.breakpoints.up("sm")]: {
          fontSize: "3rem",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "2.5rem",
        },
      },
    },
    card: {
      width: "100%",
      padding: "10px",
    },
    cardContent: {
      width: "100%",
    },
    form: {
      display: "flex",
      flexDirection: "column",
    },
    input: {
      width: "100%",

      "& + div": {
        marginTop: 15,
      },
    },
    recaptcha: {
      margin: "0 auto",
    },
    recaptchaErro: {
      "& span": {
        color: "#f44336",
        fontSize: "0.75rem",
        margin: "0 14px",
      },
    },
  };
});

function Login() {
  const classes = useStyles();
  const history = useHistory();
  const recaptchaRef = useRef();
  const { signed, handleLogin } = useContext(Context);

  // Previne caso um usuario ja logado tente entrar na rota de login.
  useEffect(() => {
    if (signed) {
      history.push("/");
    }
  }, []); // eslint-disable-line

  let schema = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY
    ? Yup.object().shape({
        email: Yup.string().email().required().label("Email"),
        password: Yup.string().required().label("Password"),
        recaptchaToken: Yup.string().required("Captcha must be resolved"),
      })
    : Yup.object().shape({
        email: Yup.string().email().required().label("Email"),
        password: Yup.string().required().label("Password"),
      });

  async function onSubmit(values, actions) {
    const { email, password, recaptchaToken } = values;

    handleLogin(email, password, recaptchaToken)
      .then((user) => {
        if (user.lastAccess) {
          history.push("/");
        } else {
          history.push("/profile/password", {
            message:
              "Você esta utilizando senha default, é sugerido que altere.",
            messageType: "info",
          });
        }
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.fields
        ) {
          error.response.data.fields.forEach((field) => {
            actions.setFieldError(field.name, field.message);
          });
        } else if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.title
        ) {
          actions.setFieldError("general", error.response.data.title);
        } else {
          toast.error(ErrorUnexpected);
        }
      })
      .finally(() => {
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        actions.setSubmitting(false);
      });
  }

  return (
    <LoginLayout>
      <div className={classes.loginBox}>
        <div className={classes.titles}>
          <Typography variant="h5">
            Roaming Audit Tool - International
          </Typography>
          <Typography variant="body1">Enhance your visibility</Typography>
        </div>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Formik
              initialValues={{
                email: "",
                password: "",
                recaptchaToken: "",
              }}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {({ setFieldValue, errors, isSubmitting }) => (
                <Form className={classes.form}>
                  <Field
                    as={TextField}
                    label="Email"
                    name="email"
                    variant="outlined"
                    className={classes.input}
                    error={!!errors.email || !!errors.general}
                    helperText={errors.email}
                  />
                  <Field
                    as={TextField}
                    label="Password"
                    name="password"
                    type="password"
                    variant="outlined"
                    className={classes.input}
                    error={!!errors.password || !!errors.general}
                    helperText={errors.password || errors.general}
                  />
                  <Typography
                    component={Link}
                    to="/forgotpassword"
                    variant="caption"
                    style={{ margin: 5 }}
                  >
                    Forgot password?
                  </Typography>
                  {process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY && (
                    <div className={classes.recaptcha}>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
                        onChange={(token) => {
                          setFieldValue("recaptchaToken", token);
                        }}
                      />
                    </div>
                  )}
                  <div className={classes.recaptchaErro}>
                    <span>
                      <ErrorMessage name="recaptchaToken" />
                    </span>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Login
                  </Button>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </div>
    </LoginLayout>
  );
}

export default Login;
