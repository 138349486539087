import { Box, Link, makeStyles} from "@material-ui/core";
// import { drawerWidth } from "./StylesConst";
import { CopyRightMessage, CopyRightVersion } from "../../const/Messages";
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => {
  return {
    footerBar: {
      bottom: 0,
      left: 0,
      right: 0,
      padding: '5px 15px',
      position: 'fixed',
      color: '#FFF',
      background:'#009EE2',
      // [theme.breakpoints.up("lg")]: {
      //   width: `calc(100% - ${drawerWidth}px)`,
      //   marginLeft: drawerWidth,
      // },
    },
    link: {
      color: '#FFF',
      '&:hover': {
        color: '#FFF',
      } 
    },
  };
});

function Footer() {

  const classes = useStyles();
  return (
    <footer className={classes.footerBar}>
      <Box display="flex" justifyContent="space-between">
        <div>
          {CopyRightMessage}
        </div>
        <div>
          <Link component={RouterLink} className={classes.link} key="release" underline="none" to="/release">v {CopyRightVersion}</Link>
        </div>
      </Box>
    </footer>
  );
}

export default Footer;