import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Tooltip } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { VscFileCode, VscFilePdf } from "react-icons/vsc";
import { toast } from "react-toastify";
import { ErrorUnexpected } from "../../../../const/Messages.js";
import * as Ir21Service from "../../../../service/Ir21Service.js";

/**
 * @author Thiago Guimarães
 * @date 23/03/2021
 */
function SearchModal({ showModal, setShowModal, organisationId, organisationName }) {
  const [list, setList] = useState([]);
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    setList([]);

    if (organisationId) {
      Ir21Service.findIr21ByOrganisation(organisationId)
        .then((ir21List) =>  setList(ir21List))
        .catch(() => toast.error(ErrorUnexpected))
        .finally();
    }
  }, [organisationId]);

  function handleDownload(id, type) {

    if(!waiting){
      setWaiting(true);
  
      Ir21Service.downloadIr21ById(id, type)
        .catch((error) => {

          if(error && error.response && error.response.status === 404){
            toast.error("File not available.");
          }else{
            toast.error(ErrorUnexpected);
          }
        })
        .finally(() => setWaiting(false));
    }
  }

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>IR21 - {organisationName}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="text-center">Release Date</th>
              <th className="text-center">Raex Version</th>
              <th className="text-center">File Type</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {list.map((row) => (
              <tr key={row.id}>
                <td className="text-center">
                  <Moment format="DD/MM/yyyy HH:mm:ss">{row.raexFileCreationDate}</Moment>
                </td>
                <td className="text-center">{row.tadigRaexVersion}</td>
                <td className="text-center">{row.ir85 ? "IR.85" : "IR.21"}</td>
                <td className="text-center">
                  <Tooltip title="DOWNLOAD PDF" arrow>
                    <span>
                      <VscFilePdf size={23} color="#007BFF" onClick={() => handleDownload(row.id, "pdf")} style={{ cursor: waiting ? "wait" : "pointer", marginRight: 5 }} />
                    </span>
                  </Tooltip>
                  <Tooltip title="DOWNLOAD XML" arrow>
                    <span>
                      <VscFileCode size={23} color="#007BFF" onClick={() => handleDownload(row.id, "xml")} style={{ cursor: waiting ? "wait" : "pointer" }} />
                    </span>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
}

export default SearchModal;
