import React, { useState, useEffect } from "react";
import Loading from "../../../../../../Common/Loading/Loading";
import { Col, Row, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import * as TemplateService from "../../../../../../../service/TemplateEricssonHlrService";
import { ErrorUnexpected, OperationSuccessfully } from "../../../../../../../const/Messages";

const initialValues = {
  gt: "",
  roamrest: false,
};

/**
  @author Guilherme Almeida
  @date 08/03/2021
 */
export default function TemplateEricssonHLRRegister({ location }) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(initialValues);

  useEffect(() => {

    if (location && location.state && location.state.id) {
      TemplateService.getById(location.state.id)
        .then((template) => {
          setValues(template);
          setLoading(false);
        })
        .catch(() => {
          history.push("/settings/report/template/hlr/ericsson/template", { message: ErrorUnexpected, messageType: "error" });
        });
    } else {
      setLoading(false);
    }
  }, []);// eslint-disable-line

  const schema = Yup.object().shape({
    gt: Yup.number().required("Global Title is a required field"),
  });

  function handleSave(values, actions) {
    let id = null;
    if (location && location.state && location.state.id) {
      id = location.state.id;
    }

    TemplateService.merge(id, values)
      .then(() => {
        history.push("/settings/report/template/hlr/ericsson/template", { message: OperationSuccessfully, messageType: "success" });
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          actions.setFieldError("gt", ErrorUnexpected);
        } else if (error.response.data.title) {
          actions.setFieldError("gt", error.response.data.title);
        }
        actions.setSubmitting(false);
      });
  }

  function handleDelete(id) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        TemplateService.deleteById(id).then(() => {
          history.push("/settings/report/template/hlr/ericsson/template", { message: OperationSuccessfully, messageType: "success" });
        });
      }
    });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>Template Ericsson HLR - Register</h5>
          </div>
          <div className="ibox-content">
            <Formik initialValues={values} validationSchema={schema} onSubmit={handleSave}>
              {() => (
                <Form>
                  <div className="form-group row">
                    <label htmlFor="gt" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      Global Title:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field className="form-control" name="gt" type="number" placeholder="Global Title" />
                      <span className="text-danger">
                        <ErrorMessage name="gt" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="roamrest" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      Enabled:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                        <label className="form-check-label pr-2">
                          Roamrest
                        </label>
                          <Field type="checkbox" name="roamrest" />
                      <span className="text-danger">
                        <ErrorMessage name="roamrest" />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Link className="mr-1 btn btn-primary" to="/settings/report/template/hlr/ericsson/template">
                      Cancel
                    </Link>
                    {location && location.state && location.state.id && (
                      <Button
                        className="mr-1"
                        onClick={() => handleDelete(location.state.id)}
                        variant="primary"
                        type="button"
                      >
                        Delete
                      </Button>
                    )}
                    <Button variant="primary" type="submit">
                      {location && location.state && location.state.id ? "Update" : "Save"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
}
