import { AiOutlineGlobal } from "react-icons/ai";
import { BsCardHeading } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";
import { FiFileText, FiHome, FiUpload } from "react-icons/fi";

export const menus = [
  {
    id: "home",
    text: "Home",
    icon: () => <FiHome size={24} />,
    path: "/",
  },
  {
    id: "ir21__settings__global-title",
    text: "Global Title",
    icon: () => <AiOutlineGlobal size={24} />,
    path: "/ir21/settings/global-title",
  },
  {
    id: "report__country-view",
    text: "Report by Country",
    icon: () => <FiFileText size={24} />,
    path: "/report/country-view",
  },
  {
    id: "report__element-view",
    text: "Report by Network Element",
    icon: () => <FiFileText size={24} />,
    path: "/report/element-view",
  },
  {
    id: "report__tadig-view",
    text: "Report by Tadig",
    icon: () => <FiFileText size={24} />,
    path: "/report/tadig-view",
  },
  {
    id: "report__error",
    text: "Report by Error",
    icon: () => <FiFileText size={24} />,
    children: [
      {
        id: "report__error__dra",
        text: "DRA",
        children: [
          {
            id: "report__error__dra__dmrt",
            text: "DMRT",
            path: "/report/error/dra/dmrt",
          },
          {
            id: "report__error__dra__rtdhost",
            text: "RTDHOST",
            path: "/report/error/dra/rtdhost",
          },
          {
            id: "report__error__dra__rtimsi",
            text: "RTIMSI",
            path: "/report/error/dra/rtimsi",
          },
          {
            id: "report__error__dra__rtohost",
            text: "RTOHOST",
            path: "/report/error/dra/rtohost",
          },
          {
            id: "report__error__dra__rtorealm",
            text: "RTOREALM",
            path: "/report/error/dra/rtorealm",
          },
          {
            id: "report__error__dra__rtresult",
            text: "RTRESULT",
            path: "/report/error/dra/rtresult",
          },
          {
            id: "report__error__dra__rtexit",
            text: "RTEXIT",
            path: "/report/error/dra/rtexit",
          },
        ],
      },
      {
        id: "report__error__edns",
        text: "EDNS",
        children: [
          {
            id: "report__error__edns__2g-3g",
            text: "2G/3G",
            path: "/report/error/edns/2g-3g",
          },
          {
            id: "report__error__edns__4g",
            text: "4G",
            path: "/report/error/edns/4g",
          },
        ],
      },
      {
        id: "report__error__glr",
        text: "GLR",
        children: [
          {
            id: "report__error__glr__glr",
            text: "GLR",
            path: "/report/error/glr/glr",
          },
          {
            id: "report__error__glr__glr-wl",
            text: "GLR WL",
            path: "/report/error/glr/glr-wl",
          },
        ],
      },
      {
        id: "report__error__hlr",
        text: "HLR",
        path: "/report/error/hlr/hercp",
      },
      {
        id: "report__error__hss",
        text: "HSS",
        children: [
          {
            id: "report__error__hss__configuration-container",
            text: "CONFIGURATION CONTAINER",
            path: "/report/error/hss/configuration-container",
          },
          {
            id: "report__error__hss__esm-plmn-container",
            text: "ESM PLMN CONTAINER",
            path: "/report/error/hss/esm-plmn-container",
          },
          {
            id: "report__error__hss__dia-cfg-routing-container",
            text: "DIA CFG ROUTING CONTAINER",
            path: "/report/error/hss/dia-cfg-routing-container",
          },
        ],
      },
      {
        id: "report__error__msc",
        text: "MSC",
        path: "/report/error/msc/mgisp",
      },
      {
        id: "report__error__pts",
        text: "PTS",
        children: [
          {
            id: "report__error__pts__sccpgt-k2r-nngt",
            text: "K2R NNGT",
            path: "/report/error/pts/sccpgt-k2r-nngt",
          },
          {
            id: "report__error__pts__sccpgt-vrs-nngt",
            text: "VRS NNGT",
            path: "/report/error/pts/sccpgt-vrs-nngt",
          },
          {
            id: "report__error__pts__sccpgt-vrs-mgt",
            text: "VRS MGT",
            path: "/report/error/pts/sccpgt-vrs-mgt",
          },
          {
            id: "report__error__pts__sccpgt-normal-nngt",
            text: "NORMAL NNGT",
            path: "/report/error/pts/sccpgt-normal-nngt",
          },
          {
            id: "report__error__pts__sccpgt-normal-mgt",
            text: "NORMAL MGT",
            path: "/report/error/pts/sccpgt-normal-mgt",
          },
          {
            id: "report__error__pts__sccpgt-unknown",
            text: "UNKNOWN",
            path: "/report/error/pts/sccpgt-unknown",
          },
        ],
      },
      {
        id: "report__error__mme",
        text: "SGSN/MME",
        children: [
          {
            id: "report__error__mme__connect-plmn",
            text: "CONNECT PLMN",
            path: "/report/error/mme/connect-plmn",
          },
          {
            id: "report__error__mme__imsigt",
            text: "IMSI GT",
            path: "/report/error/mme/imsigt",
          },
          {
            id: "report__error__mme__imsihss",
            text: "IMSI HSS",
            path: "/report/error/mme/imsihss",
          },
        ],
      },
      {
        id: "report__error__sor",
        text: "SOR",
        children: [
          {
            id: "report__error__sor__vlr",
            text: "VLR",
            path: "/report/error/sor/vlr",
          },
          {
            id: "report__error__sor__sgsn",
            text: "SGSN",
            path: "/report/error/sor/sgsn",
          },
          {
            id: "report__error__sor__mme",
            text: "MME",
            path: "/report/error/sor/mme",
          },
        ],
      },
      {
        id: "report__error__ugw",
        text: "UGW",
        children: [
          {
            id: "report__error__ugw__virtual-apn",
            text: "Virtual APN",
            path: "/report/error/ugw/virtual-apn",
          },
        ],
      },
    ],
  },
  {
    id: "settings__partners__roaming-partners",
    text: "Roaming Partners",
    icon: () => <FaHandshake size={24} />,
    path: "/settings/partners/roaming-partners",
  },
  {
    id: "ir21__settings__tadigs",
    text: "Tadigs",
    icon: () => <BsCardHeading size={24} />,
    path: "/ir21/settings/tadigs",
  },
  {
    id: "upload-log",
    text: "Upload Log",
    icon: () => <FiUpload size={24} />,
    path: "/upload-log",
  },
];
