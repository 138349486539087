import api from "./api";

export function countNewsAlerts() {
  return new Promise((resolve, reject) => {
    api
      .get("/alerts/count-new")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/alerts")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function findLast5Read() {
  return new Promise((resolve, reject) => {
    api
      .get("/alerts/last-5-readed")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function findNews() {
  return new Promise((resolve, reject) => {
    api
      .get("/alerts/news")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function markAsRead(alertId) {
  return new Promise((resolve, reject) => {
    api
      .put(`/alerts/readed/${alertId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

