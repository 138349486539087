import api from "./api";

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/organisations")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/organisations/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function merge(id, organisation) {

  return new Promise((resolve, reject) => {
    api({
      method: id ? "put" : "post",
      url: id ? `/organisations/${id}` : "/organisations",
      data: organisation,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteById(id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/organisations/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
