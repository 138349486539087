import api from "./api";

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/plmns")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function findComboBox() {
  return new Promise((resolve, reject) => {
    api
      .get("/plmns/combo-box")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/plmns/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function merge(id, plmn) {
  return new Promise((resolve, reject) => {
    api({
      method: id ? "put" : "post",
      url: id ? `/plmns/${id}` : "/plmns",
      data: plmn,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteById(id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/plmns/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
