import React from "react";
import Ir21Amount from "../../../components/Home/Ir21Amount";
import LogsAnalyzed from "../../../components/Home/LogsAnalyzed";
import MapChart from "../../../components/Home/MapChart";
import NumberErrorsByElementType from "../../../components/Home/NumberErrorsByElementType";

function Home() {

  return (
    <>
      <div className="row row-cols-1">
       <MapChart/>
      </div>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
        <NumberErrorsByElementType />
        <Ir21Amount />
        <LogsAnalyzed />
      </div>
    </>
  );
}

export default Home;
