import React, { useState, useEffect } from "react";
import Loading from "../../../../Common/Loading/Loading";
import Swal from "sweetalert2";
import Select from "react-select";
import { Col, Row, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { ErrorUnexpected, OperationSuccessfully } from "../../../../../const/Messages";
import * as Yup from "yup";
import * as PlmnService from "../../../../../service/PlmnService";
import * as OrganisationService from "../../../../../service/OrganisationService";
import * as CountryService from "../../../../../service/CountryService";

const initialValues = {
  name: "",
  country: {
    id: "",
  },
  organisation: {
    id: "",
  },
};

/**
  @author Guilherme Almeida
  @date 10/02/2021
 */
export default function ManagemenTadigRegister({ location }) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(initialValues);
  const [organisations, setOrganisations] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (location && location.state && location.state.id) {
      PlmnService.getById(location.state.id)
        .then((plmn) => {
          setValues(plmn);
          setLoading(false);
        })
        .catch(() =>
          history.push("/ir21/settings/tadigs", {
            message: ErrorUnexpected,
            messageType: "error",
          })
        );
    } else {
      setLoading(false);
    }

    OrganisationService.findAll()
      .then((organisationList) => {
        setOrganisations(
          organisationList
            .map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            })
            .sort((a, b) => {
              if (a.label > b.label) {
                return 1;
              }
              if (a.label < b.label) {
                return -1;
              }
              return 0;
            })
        );
      })
      .catch(() => toast.error(ErrorUnexpected));

    CountryService.findAll()
      .then((countryList) => {
        setCountries(
          countryList
            .map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            })
            .sort((a, b) => {
              if (a.label > b.label) {
                return 1;
              }
              if (a.label < b.label) {
                return -1;
              }
              return 0;
            })
        );
      })
      .catch(() => toast.error(ErrorUnexpected));
  }, []); // eslint-disable-line

  const schema = Yup.object().shape({
    name: Yup.string().required("Name is a required field"),
    country: Yup.object().shape({
      id: Yup.number().required("Country is a required field"),
    }),
    organisation: Yup.object().shape({
      id: Yup.number().required("Organisation is a required field"),
    }),
  });

  function handleSave(values, actions) {
    let id = null;
    if (location && location.state && location.state.id) {
      id = location.state.id;
    }

    PlmnService.merge(id, values)
      .then((response) => {
        history.push("/ir21/settings/tadigs", {
          message: OperationSuccessfully,
          messageType: "success",
        });
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          actions.setFieldError("name", ErrorUnexpected);
        } else if (error.response.data.title) {
          actions.setFieldError("name", error.response.data.title);
        } else {
          toast.error(ErrorUnexpected);
        }
      })
      .finally(() => actions.setSubmitting(false));
  }

  function handleDelete(id) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        PlmnService.deleteById(id).then(() => {
          history.push("/ir21/settings/tadigs", {
            message: OperationSuccessfully,
            messageType: "success",
          });
        });
      }
    });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5> Tadig - Register</h5>
          </div>
          <div className="ibox-content">
            <Formik initialValues={values} validationSchema={schema} onSubmit={handleSave}>
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="form-group row">
                    <label htmlFor="name" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      Name:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field className="form-control" name="name" type="text" placeholder="Name" />
                      <span className="text-danger">
                        <ErrorMessage name="name" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="organisation"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Organisation:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Select
                        styles={{
                          placeholder: (defaultStyle) => {
                            return { ...defaultStyle, color: "#495057" };
                          },
                        }}
                        placeholder="-- Select Option --"
                        options={organisations}
                        name="organisation"
                        value={organisations.find((organisation) => organisation.value === values.organisation.id)}
                        onChange={(item) => {
                          setFieldValue("organisation.id", item.value);
                        }}
                      />
                      <span className="text-danger">
                        <ErrorMessage name="organisation.id" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="country" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      Country:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Select
                        styles={{
                          placeholder: (defaultStyle) => {
                            return { ...defaultStyle, color: "#495057" };
                          },
                        }}
                        placeholder="-- Select Option --"
                        options={countries}
                        name="country"
                        value={countries.find((country) => country.value === values.country.id)}
                        onChange={(item) => {
                          setFieldValue("country.id", item.value);
                        }}
                      />
                      <span className="text-danger">
                        <ErrorMessage name="country.id" />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Link className="mr-1 btn btn-primary" to="/ir21/settings/tadigs">
                      Cancel
                    </Link>
                    {location && location.state && location.state.id && (
                      <Button
                        className="mr-1"
                        onClick={() => handleDelete(location.state.id)}
                        variant="primary"
                        type="button"
                      >
                        Delete
                      </Button>
                    )}
                    <Button variant="primary" type="submit">
                      {location && location.state && location.state.id ? "Update" : "Save"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
}
