import api from "./api";

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/operatorpartners/upload")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function downloadSample() {
  return new Promise((resolve, reject) => {
    api
      .get(`/operatorpartners/upload/sample`, { responseType: "blob" })
      .then((response) => {
        let fileName = "file.txt";
        if (response.headers && response.headers["content-disposition"]) {
          fileName = response.headers["content-disposition"]
            .split("filename=")[1]
            .replaceAll('"', "")
            .replaceAll("%20", " ");
        }

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        // converte o blob em uma url
        const url = window.URL.createObjectURL(blob);

        // cria uma tag a para clicar e fazer o download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        // aciona o link
        link.click();

        // remove do dom
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        resolve();
      })
      .catch((error) => reject(error));
  });
}

export function upload(data) {
  return new Promise((resolve, reject) => {
    api
      .post("/operatorpartners/upload", data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function download(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/operatorpartners/upload/download/${id}`, { responseType: "blob" })
      .then((response) => {
        let fileName = response.headers["content-disposition"]
          .split("filename=")[1]
          .replaceAll('"', "")
          .replaceAll("%20", " ");

        const blob = new Blob([response.data], {
          type: response.headers["Content-Type"],
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function rerunById(id) {
  return new Promise((resolve, reject) => {
    api
      .put(`/operatorpartners/upload/rerun/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
