import api from "./api";

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/operatorpartners")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAgreements() {
  return new Promise((resolve, reject) => {
    api
      .get("/operatorpartners/agreements")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/operatorpartners/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function merge(id, operatorPartner) {
  return new Promise((resolve, reject) => {
    api({
      method: id ? "put" : "post",
      url: id ? `/operatorpartners/${id}` : "/operatorpartners",
      data: operatorPartner,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteById(id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/operatorpartners/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function createBlockedByTadig(tadig) {
  return new Promise((resolve, reject) => {
    api
      .post(`/operatorpartners/blocked/${tadig}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteBlockedByTadig(tadig) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/operatorpartners/blocked/${tadig}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
