import { useRef, useState } from "react";
import { toast } from "react-toastify";

export function AssociationList({associatedList, disassociatedList, associate, disassociate}){
  const [searchNotAssociated, setSearchNotAssociated] = useState("");
  const [searchAssociated, setSearchAssociated] = useState("");
  const notAssociatedSelect = useRef(null);
  const associatedSelect = useRef(null);

  
  function handleAssociate(){
    if(notAssociatedSelect.current.value){
      const child = disassociatedList.find(plmn => plmn.id == notAssociatedSelect.current.value);
      if(child){
        associate(child);
        return 0;
      }
    }

    toast.error("Select a valid tadig");
  }
  
  function handleDisassociate(){
    if(associatedSelect.current.value){
      const child = associatedList.find(plmn => plmn.id == associatedSelect.current.value);
      if(child){
        disassociate(child);
        return 0;
      }
    }

    toast.error("Select a valid tadig");
  }

  return (
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-4">
                  <div class="input-group mb-3">
                    <span class="input-group-text">Search</span>
                    <input
                      value={searchNotAssociated}
                      onChange={(ev) => setSearchNotAssociated(ev.target.value)}
                      type="text"
                      class="form-control"
                      placeholder="TADIG"
                      id="search-list"
                      minlength="1"
                      maxlength="24"
                    />
                  </div>
                  <span>Not associated list</span>
                  <select ref={notAssociatedSelect} style={{ height: 300, width: "100%" }} multiple id="the-list">
                    {disassociatedList
                      .filter((plmn) =>
                        !searchNotAssociated
                          ? true
                          : plmn.name.toLowerCase().includes(searchNotAssociated.toLowerCase())
                      )
                      .sort((a, b) => {
                        if (a.name > b.name) {
                          return 1;
                        }
                        if (a.name < b.name) {
                          return -1;
                        }
                        return 0;
                      })
                      .map((plmn) => (
                        <option key={plmn.id} value={plmn.id}>
                          {plmn.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div class="col-4">
                  <div
                    style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}
                  >
                    <button class="btn btn-success" type="button" id="add-btn" onClick={handleAssociate}>
                      ASSOCIATE
                    </button>
                    <button class="btn btn-danger" type="button" id="remove-btn" onClick={handleDisassociate}>
                      DISASSOCIATE
                    </button>
                  </div>
                </div>
                <div class="col-4">
                  <div class="input-group mb-3">
                    <span class="input-group-text">Search</span>
                    <input
                      value={searchAssociated}
                      onKeyUp={(ev) => setSearchAssociated(ev.target.value)}
                      type="text"
                      class="form-control"
                      placeholder="TADIG"
                      id="search-built-list"
                      minlength="1"
                      maxlength="24"
                    />
                  </div>
                  <span>Associated list</span>
                  <select ref={associatedSelect} style={{ height: 300, width: "100%" }} multiple id="built-list">
                    {associatedList
                      .filter((plmn) =>
                        !searchAssociated ? true : plmn.name.toLowerCase().includes(searchAssociated.toLowerCase())
                      )
                      .sort((a, b) => {
                        if (a.name > b.name) {
                          return 1;
                        }
                        if (a.name < b.name) {
                          return -1;
                        }
                        return 0;
                      })
                      .map((plmn) => (
                        <option key={plmn.id} value={plmn.id}>
                          {plmn.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div class="row" id="products-div"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}