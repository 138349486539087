import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiCheck, FiEdit, FiTrash2, FiX } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  ErrorUnexpected,
  OperationSuccessfully,
} from "../../../../../../../const/Messages";
import * as TemplateService from "../../../../../../../service/TemplateEricssonHlrService";

/**
  @author Guilherme Almeida
  @date 08/03/2021
 */
export default function TemplateEricssonHLRList() {
  const history = useHistory();
  const [template, setTemplate] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    TemplateService.findAll()
      .then((templateList) => {
        setTemplate(templateList);
      })
      .catch(() => {
        toast.error(ErrorUnexpected);
      })
      .finally(() => setIsLoading(false));
  }, []); // eslint-disable-line

  function handleDelete(id) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        TemplateService.deleteById(id)
          .then(() => {
            TemplateService.findAll()
              .then((templateList) => {
                setTemplate(templateList);
                toast.success(OperationSuccessfully);
              })
              .catch(() => {
                toast.error(ErrorUnexpected);
              });
          })
          .catch(() => {
            toast.error(ErrorUnexpected);
          });
      }
    });
  }

  const columns = [
    {
      title: "Global Title Prefix",
      field: "gt",
    },
    {
      title: "ROAMREST",
      field: "roamrest",
      render: (row) =>
        row.roamrest ? <FiCheck color="green" /> : <FiX color="red" />,
      width: "1%",
    },
  ];

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between align-items-center">
            <h5>Template Ericsson HLR - List</h5>
            <div>
              <Link
                className="btn btn-primary"
                to={{
                  pathname:
                    "/settings/report/template/hlr/ericsson/template/reg",
                  state: { id: "" },
                }}
              >
                New Template
              </Link>
            </div>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={template}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "template-hlr-ericsson",
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
              actions={[
                {
                  icon: () => <FiEdit size={16} color="#007bff" />,
                  tooltip: "EDIT",
                  onClick: (event, rowData) =>
                    history.push(
                      "/settings/report/template/hlr/ericsson/template/reg",
                      { id: rowData.id }
                    ),
                },
                {
                  icon: () => <FiTrash2 size={16} color="#007bff" />,
                  tooltip: "DELETE",
                  onClick: (event, rowData) => handleDelete(rowData.id),
                },
              ]}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
