import api from "./api";

export function deleteById(id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/element/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/element")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getByVendorAndElementType(vendorId, elementTypeId) {
  return new Promise((resolve, reject) => {
    api
      .get("/element/vendor-elementtype", { params: { vendorId, elementTypeId } })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/element/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getElementsLastUploadBy(elementTypeId, vendorId) {
  return new Promise((resolve, reject) => {
    api
      .get("/element/last-upload", { params: { elementTypeId, vendorId } })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getSummaryList() {
  return new Promise((resolve, reject) => {
    api
      .get(`/element/summary`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function merge(id, element) {
  return new Promise((resolve, reject) => {
    api({
      method: id ? "put" : "post",
      url: id ? `/element/${id}` : "/element",
      data: element,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
