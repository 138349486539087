import {
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { IoRemoveCircleOutline, IoSettingsSharp } from "react-icons/io5";
import { Link as RouterLink } from "react-router-dom";
import RoleControl from "../../../components/security/RoleControl";
import { VERTIS } from "../../../const/Roles";

const useStyles = makeStyles((theme) => {
  return {
    flex: {
      display: "flex",
      flexDirection: "column",
    },
    flexRow: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    link: {
      fontSize: 12,
    },
  };
});

export default function SettingsMenu() {
  const classes = useStyles();

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12}>
        <Breadcrumbs>
          <Typography variant="button">Settings</Typography>
        </Breadcrumbs>
      </Grid>
      <Card style={{ height: "100%", width: "100%" }}>
        <CardContent>
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="stretch">
              <Grid item md={3} sm={5} xs={6} lg={2} className={classes.flex}>
                <Grid container spacing={3} alignItems="stretch">
                  <Grid item xs={12} className={classes.flex}>
                    <CardHeader
                      title="GENERAL SETTINGS"
                      subheader=""
                      avatar={
                        <IconButton>
                          <IoSettingsSharp size={40} />
                        </IconButton>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={9} sm={7} xs={6} lg={10} className={classes.flex}>
                <Grid container spacing={2} alignItems="stretch">
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    className={classes.flex}
                  >
                    <Typography variant="subtitle2">REGISTER</Typography>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="networkElement"
                      color="initial"
                      underline="none"
                      to="/settings/network-element"
                    >
                      Network Element
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="organisation"
                      color="initial"
                      underline="none"
                      to="/ir21/settings/organisations"
                    >
                      Organisation
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="realm"
                      color="initial"
                      underline="none"
                      to="/settings/realm"
                    >
                      Realm
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="tadig"
                      color="initial"
                      underline="none"
                      to="/ir21/settings/tadigs"
                    >
                      Tadig
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="tadigAssociation"
                      color="initial"
                      underline="none"
                      to="/ir21/settings/tadigs/association"
                    >
                      Tadig Association
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="roamingPartner"
                      color="initial"
                      underline="none"
                      to="/settings/partners/roaming-partners"
                    >
                      Roaming Partner
                    </Link>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    className={classes.flex}
                  >
                    <Typography variant="subtitle2">GSMA</Typography>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="automaticSyncSetup"
                      color="initial"
                      underline="none"
                      to="/ir21/gsma/automatic-sync-setup"
                    >
                      Automatic Sync Setup
                    </Link>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    className={classes.flex}
                  >
                    <Typography variant="subtitle2">IR.21</Typography>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="search"
                      color="initial"
                      underline="none"
                      to="/ir21/documents/search"
                    >
                      Search
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="upload"
                      color="initial"
                      underline="none"
                      to="/ir21/documents/upload"
                    >
                      Upload
                    </Link>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    className={classes.flex}
                  >
                    <Typography variant="subtitle2">SETUP REPORT</Typography>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="reportDraHuawei"
                      color="initial"
                      underline="none"
                      to="/settings/report/template/dra/huawei/default"
                    >
                      DRA/Huawei
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="reportEdnsF5"
                      color="initial"
                      underline="none"
                      to="/settings/report/template/edns/f5/default"
                    >
                      EDNS/F5
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="reportGlrSixbell"
                      color="initial"
                      underline="none"
                      to="/settings/report/template/glr/sixbell/default"
                    >
                      GLR/Sixbell
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="reportHlrEricsson"
                      color="initial"
                      underline="none"
                      to="/settings/report/template/hlr/ericsson/default"
                    >
                      HLR/Ericsson
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="reportHssEricsson"
                      color="initial"
                      underline="none"
                      to="/settings/report/template/hss/ericsson/default"
                    >
                      HSS/Ericsson
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="reportMscEricsson"
                      color="initial"
                      underline="none"
                      to="/settings/report/template/msc/ericsson/default"
                    >
                      MSC/Ericsson
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="reportPtsEricsson"
                      color="initial"
                      underline="none"
                      to="/settings/report/template/pts/huawei/default"
                    >
                      PTS/Huawei
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="reportSgsnMme"
                      color="initial"
                      underline="none"
                      to="/settings/report/template/sgsn-mme/huawei/default"
                    >
                      SGSN-MME/Huawei
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="reportSorSixbell"
                      color="initial"
                      underline="none"
                      to="/settings/report/template/sor/sixbell/default"
                    >
                      SOR/Sixbell
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="reportUgwHuawei"
                      color="initial"
                      underline="none"
                      to="/settings/report/template/ugw/huawei/default"
                    >
                      UGW/Huawei
                    </Link>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    className={classes.flex}
                  >
                    <Typography variant="subtitle2">TEMPLATE REPORT</Typography>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="templateHlrEricsson"
                      color="initial"
                      underline="none"
                      to="/settings/report/template/hlr/ericsson/template"
                    >
                      HLR/Ericsson
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="templateUgwHuawei"
                      color="initial"
                      underline="none"
                      to="/settings/report/template/ugw/huawei/template"
                    >
                      UGW/Huawei
                    </Link>
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    className={classes.flex}
                  >
                    <Typography variant="subtitle2">SPECIAL CASES</Typography>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="imsiExchangeRules"
                      color="initial"
                      underline="none"
                      to="/settings/report/imsi-change-rules"
                    >
                      IMSI Exchange Rules
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ margin: "20px" }} />
          <Grid item xs={12}>
            <Grid container spacing={3} alignItems="stretch">
              <Grid item md={3} sm={5} xs={6} lg={2} className={classes.flex}>
                <Grid container spacing={3} alignItems="stretch">
                  <Grid item xs={12} className={classes.flex}>
                    <CardHeader
                      title="IGNORED CASES"
                      subheader="Manage registered exception from report."
                      avatar={
                        <IconButton>
                          <IoRemoveCircleOutline size={40} />
                        </IconButton>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={9} sm={7} xs={6} lg={10} className={classes.flex}>
                <Grid container spacing={3} alignItems="stretch">
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={6}
                    xs={12}
                    className={classes.flex}
                  >
                    <Typography variant="subtitle2">DRA/HUAWEI</Typography>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="dmrt"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/dra/huawei/dmrt"
                    >
                      Dmrt
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="rtdhost"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/dra/huawei/rtdhost"
                    >
                      Rtdhost
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="rtimsi"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/dra/huawei/rtimsi"
                    >
                      Rtimsi
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="rtohost"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/dra/huawei/rtohost"
                    >
                      Rtohost
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="rtorealm"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/dra/huawei/rtorealm"
                    >
                      Rtorealm
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="rtresult"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/dra/huawei/rtresult"
                    >
                      Rtresult
                    </Link>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={6}
                    xs={12}
                    className={classes.flex}
                  >
                    <Typography variant="subtitle2">EDNS/F5</Typography>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="2G3G"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/edns/f5/2g-3g"
                    >
                      2G/3G
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="4G"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/edns/f5/4g"
                    >
                      4G
                    </Link>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={6}
                    xs={12}
                    className={classes.flex}
                  >
                    <Typography variant="subtitle2">GLR/SIXBELL</Typography>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="glr"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/glr/sixbell/glr"
                    >
                      GLR
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="glrWl"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/glr/sixbell/glr-wl"
                    >
                      GLR WL
                    </Link>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={6}
                    xs={12}
                    className={classes.flex}
                  >
                    <Typography variant="subtitle2">HSS/ERICSSON</Typography>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="configurationContainer"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/hss/ericsson/configuration-container"
                    >
                      Configuration Container
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="esmPlmnContainer"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/hss/ericsson/esm-plmn-container"
                    >
                      Esm Plmn Container
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="diaCfgRoutingContainer"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/hss/ericsson/dia-cfg-routing-container"
                    >
                      Dia Cfg Routing Container
                    </Link>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={6}
                    xs={12}
                    className={classes.flex}
                  >
                    <Typography variant="subtitle2">MSC/ERICSSON</Typography>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="mgisp"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/msc/ericsson/mgisp"
                    >
                      Mgisp
                    </Link>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={6}
                    xs={12}
                    className={classes.flex}
                  >
                    <Typography variant="subtitle2">PTS/HUAWEI</Typography>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="k2rNngt"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/pts/huawei/sccpgt-k2r-nngt"
                    >
                      K2R NNGT
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="vrsNngt"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/pts/huawei/sccpgt-vrs-nngt"
                    >
                      VRS NNGT
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="vrsMgt"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/pts/huawei/sccpgt-vrs-mgt"
                    >
                      VRS MGT
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="normalNngt"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/pts/huawei/sccpgt-normal-nngt"
                    >
                      Normal NNGT
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="normalMgt"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/pts/huawei/sccpgt-normal-mgt"
                    >
                      Normal MGT
                    </Link>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={6}
                    xs={12}
                    className={classes.flex}
                  >
                    <Typography variant="subtitle2">SGSN-MME/HUAWEI</Typography>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="connectPlmn"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/sgsn-mme/huawei/connect-plmn"
                    >
                      Connect PLMN
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="imsiGt"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/sgsn-mme/huawei/imsi-gt"
                    >
                      IMSI GT
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="imsiHss"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/sgsn-mme/huawei/imsi-hss"
                    >
                      IMSI HSS
                    </Link>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={6}
                    xs={12}
                    className={classes.flex}
                  >
                    <Typography variant="subtitle2">SOR/SIXBELL</Typography>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="vlr"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/sor/sixbell/vlr"
                    >
                      VLR
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="sgsn"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/sor/sixbell/sgsn"
                    >
                      SGSN
                    </Link>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="mme"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/sor/sixbell/mme"
                    >
                      MME
                    </Link>
                  </Grid>
                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={6}
                    xs={12}
                    className={classes.flex}
                  >
                    <Typography variant="subtitle2">UGW/HUAWEI</Typography>
                    <Link
                      className={classes.link}
                      component={RouterLink}
                      key="vlr"
                      color="initial"
                      underline="none"
                      to="/settings/report/ignored-cases/ugw/huawei/virtual-apn"
                    >
                      Virtual APN
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <RoleControl roles={[VERTIS]}>
            <Divider style={{ margin: "20px" }} />
            <Grid item xs={12}>
              <Grid container spacing={3} alignItems="stretch">
                <Grid item md={3} sm={5} xs={6} lg={2} className={classes.flex}>
                  <Grid container spacing={3} alignItems="stretch">
                    <Grid item xs={12} className={classes.flex}>
                      <CardHeader
                        title="ADMIN"
                        subheader="Vertis-DEV"
                        avatar={
                          <IconButton>
                            <IoSettingsSharp size={40} />
                          </IconButton>
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={9}
                  sm={7}
                  xs={6}
                  lg={10}
                  className={classes.flex}
                >
                  <Grid container spacing={2} alignItems="stretch">
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={6}
                      xs={12}
                      className={classes.flex}
                    >
                      <Typography variant="subtitle2">APP</Typography>
                      <Link
                        className={classes.link}
                        component={RouterLink}
                        key="applicationLog"
                        color="initial"
                        underline="none"
                        to="/vertis/log"
                      >
                        Application Log
                      </Link>
                      <Link
                        className={classes.link}
                        component={RouterLink}
                        key="trialConfig"
                        color="initial"
                        underline="none"
                        to="/vertis/trial"
                      >
                        Trial Settings
                      </Link>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={6}
                      xs={12}
                      className={classes.flex}
                    >
                      <Typography variant="subtitle2">VRAT</Typography>
                      <Link
                        className={classes.link}
                        component={RouterLink}
                        key="hub"
                        color="initial"
                        underline="none"
                        to="/settings/partners/hub"
                      >
                        Hub
                      </Link>
                      <Link
                        className={classes.link}
                        component={RouterLink}
                        key="Users"
                        color="initial"
                        underline="none"
                        to="/vertis/users"
                      >
                        Users
                      </Link>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={6}
                      xs={12}
                      className={classes.flex}
                    >
                      <Typography variant="subtitle2">DB</Typography>
                      <Link
                        className={classes.link}
                        component={RouterLink}
                        key="postgresAnalyzer"
                        color="initial"
                        underline="none"
                        to="/vertis/postgres-analyzer"
                      >
                        Postgres Analyzer
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </RoleControl>
        </CardContent>
      </Card>
    </Grid>
  );
}
