import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import { FiAlertTriangle } from "react-icons/fi";
import Moment from "react-moment";
import LoadingGif from "../../assets/loading.gif";
import * as HomeService from "../../service/HomeService";
import { formatNumber } from "../../utils/NumberUtils";
import LoadingBar from "../LoadingBar/LoadingBar";

/**
 * @author Thiago Guimarães
 * @date 06/08/2021
 */
let countdownTimeOut = null;
const timeToRefresh = 5 * 60;

function LogsAnalyzedLastSevenDays() {
  const [loading, setLoading] = useState(false);
  const [logsProcessedThisWeek, setLogsProcessedThisWeek] = useState("-");
  const [logsProcessedThisMonth, setLogsProcessedThisMonth] = useState("-");
  const [errors, setErrors] = useState("");

  const [time, setTime] = useState(timeToRefresh);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [isActive, setIsActive] = useState(false);

  // Separa os minutos em duas unidades,colocando 0 no inicio quando necessario.
  const minuteLeftRight = String(Math.floor(time / 60))
    .padStart(2, "0")
    .split("");

  // Separa os segundos em duas unidades,colocando 0 no inicio quando necessario.
  const secondsLeftRight = String(time % 60)
    .padStart(2, "0")
    .split("");

  useEffect(() => {
    if (!isActive) {
      if (!loading) {
        setLoading(true);
        setErrors("");

        const month = moment().startOf("month").toISOString();
        const week  = moment().startOf("week").toISOString();

        Promise.all([ HomeService.getAmountLogsProcessed(month),  HomeService.getAmountLogsProcessed(week)])
        .then( values => {
          setLogsProcessedThisMonth(values[0]);
          setLogsProcessedThisWeek(values[1]);
        })
        .catch(() => setErrors("Something went wrong. Try again!!"))
        .finally(() => {
          clearTimeout(countdownTimeOut);
          setIsActive(true);
          setTime(timeToRefresh);
          setLastUpdate(new Date());
          setLoading(false);
        });
      }
    } else if (isActive && time > 0) {
      countdownTimeOut = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    } else if (isActive && time === 0 && !loading) {
      setIsActive(false);
    }

    return () => {
      clearTimeout(countdownTimeOut);
    };
    
  }, [isActive, time]); // eslint-disable-line

  return (
    <div className="col">
      <div className="ibox">
        <div className="ibox-title d-flex flex-column">
          <h5 className="mb-1">Log analysis</h5>
          <span className="text-muted" style={{ fontSize: "60%" }}>
            Last Update: <Moment format="DD/MM/yyyy HH:mm:ss">{lastUpdate}</Moment>
          </span>
        </div>
        <div className="ibox-content">
          {errors ? (
            <div className="text-center">
              <span className="text-danger">
                <FiAlertTriangle size="30" /> {errors}
              </span>
            </div>
          ) : (
            <div className="text-center">
              {loading ? (
                <img src={LoadingGif} width="40px" alt="Loading" />
              ) : (
                <>
                  <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>This Month</TableCell>
                      <TableCell  className="text-right">{formatNumber(logsProcessedThisMonth)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>This Week</TableCell>
                      <TableCell className="text-right">{formatNumber(logsProcessedThisWeek)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                </>
              )}
            </div>
          )}
          <div className="text-center mt-3">
            <small>
              <span onClick={() => setTime(0)} className="" style={{ cursor: "pointer" }}>
                <small>
                  Refresh ({`${minuteLeftRight[0]}${minuteLeftRight[1]}:${secondsLeftRight[0]}${secondsLeftRight[1]}`})
                </small>
              </span>
            </small>
          </div>
          <LoadingBar percent={(time * 100) / timeToRefresh} />
        </div>
      </div>
    </div>
  );
}

export default LogsAnalyzedLastSevenDays;
