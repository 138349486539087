import React, { useContext, useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { ErrorUnexpected, OperationSuccessfully, OperationCancelled } from "../../../../const/Messages";
import { Context } from "../../../../context/Context";
import * as RoleService from "../../../../service/RoleService";
import * as UserService from "../../../../service/UserService";
import Loading from "../../../Common/Loading/Loading";

/**
 * @author Thiago Guimarães
 * @date 28/01/2021
 */
const initialValues = {
  name: "",
  email: "",
  role: {
    id: "",
  },
};

function UserRegister({ location }) {
  const history = useHistory();
  const { user: loggedUser, handleLogout } = useContext(Context);

  const [roles, setRoles] = useState([]);
  const [values, setValues] = useState(initialValues);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location && location.state && location.state.id) {
      UserService.findById(location.state.id)
        .then((user) => {
          RoleService.findAll().then((roleList) => {
            setRoles(roleList);
            setValues({
              name: user.name,
              email: user.email,
              role: user.roles[0],
            });
            setLoading(false);
          });
        })
        .catch((error) => {
          history.push("/users", {
            message: ErrorUnexpected,
            messageType: "error",
          });
        });
    } else {
      RoleService.findAll()
        .then((roleList) => {
          setRoles(roleList);
        })
        .catch((error) => {
          toast.error(ErrorUnexpected);
        });
      setLoading(false);
    }
  }, []); // eslint-disable-line

  const schema = Yup.object().shape({
    name: Yup.string().required("Name is a required field"),
    email: Yup.string().email("Email must be a valid email").required("Email is a required field"),
    role: Yup.object().shape({
      id: Yup.number().required("Role is a required field"),
    }),
  });

  function handleSave(user, actions) {
    if (location && location.state && location.state.id) {
      user.id = location.state.id;
    }

    UserService.merge(user)
      .then((response) => {
        history.push("/users");
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          toast.error(ErrorUnexpected);
        } else if (error.response.data.fields) {
          const fields = error.response.data.fields;

          fields.forEach((field) => {
            actions.setFieldError(field.name, field.message);
          });
        } else if (error.response.data.title) {
          actions.setFieldError("role", error.response.data.title);
        } else {
          toast.error(ErrorUnexpected);
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  }

  function handleDelete(userId) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteById(userId)
          .then(() => {
            if (loggedUser.id === userId) {
              handleLogout();
            } else {
              history.push("/users", {
                message: OperationSuccessfully,
                messageType: "success",
              });
            }
          })
          .catch((error) => {
            toast.error(ErrorUnexpected);
          });
      }
    });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Row>
      <Col lg="12">
        <div className="ibox">
          <div className="ibox-title">User Register</div>
          <div className="ibox-content">
            <Formik initialValues={values} validationSchema={schema} onSubmit={handleSave}>
              {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                  <div className="form-group row">
                    <label htmlFor="name" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      Name:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field className="form-control" name="name" type="text" placeholder="Name" />
                      <span className="text-danger">
                        <ErrorMessage name="name" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="email" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      Email:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field className="form-control" name="email" type="email" placeholder="Email" autoComplete="username" />
                      <span className="text-danger">
                        <ErrorMessage name="email" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="roleId" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      Role:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field
                        as="select"
                        value={values.role.id}
                        className="form-control"
                        onChange={(ev) => {
                          setFieldValue("role.id", ev.target.value);
                        }}
                      >
                        <option value="">-- Select Option --</option>
                        {roles.map((role) => (
                          <option key={role.id} value={role.id}>
                            {role.name}
                          </option>
                        ))}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="role.id" />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Link
                      className="mr-1 btn btn-primary"
                      to={{
                        pathname: "/users",
                        state: {
                          message: OperationCancelled,
                          messageType: "info",
                        },
                      }}
                    >
                      Cancel
                    </Link>
                    {location && location.state && location.state.id && (
                      <Button variant="primary" className="mr-1" onClick={() => handleDelete(location.state.id)} disabled={isSubmitting}>
                        Delete
                      </Button>
                    )}
                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                      {location && location.state && location.state.id ? "Update" : "Save"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default UserRegister;
