import {
  AppBar,
  Box,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FiMenu } from "react-icons/fi";
import { IoSettingsSharp } from "react-icons/io5";
import { Link, Link as RouterLink } from "react-router-dom";
import logo from "../../assets/logo.png";
import { useMenuContext } from "../../context/MenuContext";
import AccountMenu from "./AccountMenu";
import AlertPopover from "./AlertPopover";
import { TrialBar } from "./TrialBar";

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      background: "#009EE2",
      zIndex: 3000,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      // [theme.breakpoints.up("sm")]: {
      //   display: "none",
      // },
    },
    title: {
      fontSize: 24,
      justifySelf: "center",
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: 21,
      },
    },
    tools: {
      justifySelf: "end",
    },
    toolBar: {
      display: "grid",
      gridTemplateColumns: "repeat(3,1fr)",
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "repeat(2,1fr)",
      },
    },
    logo: {
      [theme.breakpoints.down("sm")]: {
        width: "70%",
      },
      height: "50px",
    },
  };
});

function HeaderBar() {
  const { toogleMenu } = useMenuContext();

  const classes = useStyles();
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toogleMenu}
            className={classes.menuButton}
          >
            <FiMenu />
          </IconButton>
          <Link to="/">
            <img alt="Logo Vertis" src={logo} className={classes.logo} />
          </Link>
        </Box>
        <Typography variant="h6" className={classes.title}>
          Roaming Audit Tool - International
        </Typography>
        <Box className={classes.tools}>
          <AlertPopover />
          <IconButton component={RouterLink} to="/settings">
            <IoSettingsSharp size={30} color="white" />
          </IconButton>
          <AccountMenu />
        </Box>
      </Toolbar>
      <TrialBar />
    </AppBar>
  );
}

export default HeaderBar;
