import * as VertisApplicationLogService from "../../../../service/VertisApplicationLogService";
import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Col, Row } from "react-bootstrap";
import { Paper } from "@material-ui/core";
import { FiDownload, FiFile } from "react-icons/fi";
import { toast } from "react-toastify";
import { ErrorUnexpected } from "../../../../const/Messages";

/**
* @author Guilherme Almeida
* @date 02/09/2021
*/
function ApplicationLog() {
  const [list, setList] = useState([]);

  useEffect(() => {
    VertisApplicationLogService.findAll().then((list) => setList(list));
  }, []);

  function handleDownload(fileName) {
    VertisApplicationLogService.download(fileName)
    .catch(() => {
      toast.error(ErrorUnexpected);
    });
  }

  function handleView(fileName) {
    VertisApplicationLogService.viewDocument(fileName)
    .catch(() => {
      toast.error(ErrorUnexpected);
    });
  }

  const columns = [
    {
      title: "NAME",
      field: "name",
    },
  ];

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between align-items-center">
            <h5>Application Log - List</h5>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={list}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: false,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "list",
                headerStyle: {
                  textTransform: "uppercase",
                }
              }}
              actions={[
                {
                  icon: () => <FiDownload size={16} color="#007bff" />,
                  tooltip: "DOWNLOAD",
                  onClick: (event, rowData) => handleDownload(rowData.name),
                },
                {
                  icon: () => <FiFile size={16} color="#007bff" />,
                  tooltip: "VIEW",
                  onClick: (event, rowData) => handleView(rowData.name),
                },
              ]}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ApplicationLog;
