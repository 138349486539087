import api from "./api";

export function getExportRedundantCarrier() {
  return new Promise((resolve, reject) => {
    api
      .get(`/redundant-carrier/export`, { responseType: "blob" })
      .then((response) => {
        let fileName = "file.xls";
        if (response.headers && response.headers["content-disposition"]) {
          fileName = response.headers["content-disposition"]
            .split("filename=")[1]
            .replaceAll('"', "")
            .replaceAll("%20", " ");
        }

        const blob = new Blob([response.data], {
          type: "application/excel",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}
