import { Box, IconButton, List, ListItem, Typography } from "@material-ui/core";
import { FiX } from "react-icons/fi";
import { useMenuContext } from "../../context/MenuContext";
import { Category } from "./category";

export function SubMenu() {
  const { isOpen, subMenu, closeSubMenu } = useMenuContext();

  if (isOpen && !!subMenu) {
    return (
      <Box
        style={{
          height: "100%",
          width: 2,
          marginLeft: "120px"
        }}
      >
        <List style={{ width: "270px", overflowY: "auto", overflowX: "hidden" }}>
          <ListItem style={{ width: "250px", display: "flex", justifyContent: "space-between" }}>
            <Box style={{ flexGrow: 1, display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h5" style={{ color: "gray" }}>
                {subMenu?.text}
              </Typography>
              <IconButton onClick={() => closeSubMenu()} style={{top: -20, right: -38}}>
                <FiX color="gray" size={24}/>
              </IconButton>
            </Box>

          </ListItem>
          {subMenu?.children?.map((subMenu, index) => (
            <Category key={`${subMenu.text}_${index}`} title={subMenu.text} roles={subMenu.roles} links={subMenu.children} path={subMenu.path} />
          ))}
        </List>
      </Box>
    );
  }

  return null;
}