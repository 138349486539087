import React, { useEffect, useContext, useState } from "react"
import { Context } from "../../../context/Context"
import { Row, Col, Button } from "react-bootstrap"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { Link, useHistory } from "react-router-dom"
import Loading from "../../Common/Loading/Loading"
import { ErrorUnexpected, OperationSuccessfully } from "../../../const/Messages"
import { toast } from "react-toastify"
import * as UserService from "../../../service/UserService"
import * as Yup from "yup"
import Swal from "sweetalert2"

/**
 * @author Thiago Guimarães
 * @date 10/02/2021
 */
function Profile() {
  const { user, setUser, handleLogout } = useContext(Context)
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [values, setValues] = useState({ name: "", email: "" })

  useEffect(() => {
    if (user) {
      setValues({
        name: user.name,
        email: user.email,
      })
      setLoading(false)
    } else {
      history.push("/", {
        message: ErrorUnexpected,
        messageType: "error",
      })
    }
  }, []) // eslint-disable-line

  const schema = Yup.object().shape({
    name: Yup.string().required("Name is a required field"),
    email: Yup.string().email("Email must be a valid email").required("Email is a required field"),
  })

  function handleUpdate(values, actions) {
    UserService.update(user.id, values)
      .then((user) => {
        setUser(user)
        history.push("/", {
          message: OperationSuccessfully,
          messageType: "success",
        })
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          toast.error(ErrorUnexpected)
        } else if (error.response.data.fields) {
          const fields = error.response.data.fields

          fields.forEach((field) => {
            actions.setFieldError(field.name, field.message)
          })
        } else {
          toast.error(ErrorUnexpected)
        }
      })
      .finally(() => {
        actions.setSubmitting(false)
      })
  }

  function handleDelete() {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete your account?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteById(user.id)
          .then(() => {
            handleLogout()
          })
          .catch((error) => {
            toast.error(ErrorUnexpected)
          })
      }
    })
  }

  if (loading) {
    return <Loading />
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>Profile</h5>
          </div>
          <div className="ibox-content">
            <Formik initialValues={values} validationSchema={schema} onSubmit={handleUpdate}>
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group row">
                    <label htmlFor="name" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      Name:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field className="form-control" name="name" type="text" />
                      <span className="text-danger">
                        <ErrorMessage name="name" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="email" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      Email:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field className="form-control" name="email" type="email" />
                      <span className="text-danger">
                        <ErrorMessage name="email" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="email" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      Password:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Link to="/profile/password">Change Password</Link>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                      <Link
                        to={{
                          pathname: "/",
                          state: {
                            message: "Operation cancelled.",
                            messageType: "info",
                          },
                        }}
                        className="btn btn-primary mr-1"
                      >
                        Cancel
                      </Link>
                      <Button variant="primary" className="mr-1" onClick={handleDelete} disabled={isSubmitting}>
                        Delete
                      </Button>
                      <Button variant="primary" type="submit" disabled={isSubmitting}>
                        Update
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Profile
