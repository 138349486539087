import api from "./api";

export function deleteById(id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/ir21/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function deleteIr21Template(plmnId) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/ir21-template/${plmnId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function downloadIr21ById(id, type) {
  return new Promise((resolve, reject) => {
    api
      .get(`/ir21/download/${type}/${id}`, { responseType: "blob" })
      .then((response) => {
        let fileName = "file.xml";
        if (response.headers && response.headers["content-disposition"]) {
          fileName = response.headers["content-disposition"]
            .split("filename=")[1]
            .replaceAll('"', "")
            .replaceAll("%20", " ");
        }

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        // converte o blob em uma url
        const url = window.URL.createObjectURL(blob);

        // cria uma tag a para clicar e fazer o download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        // aciona o link
        link.click();

        // remove do dom
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        resolve();
      })
      .catch((error) => reject(error));
  });
}

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/ir21")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function findIr21ByOrganisation(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/ir21/organisation/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getExportData(plmnId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/ir21-template/export/${plmnId}`, { responseType: "blob" })
      .then((response) => {
        let fileName = "file.xls";
        if (response.headers && response.headers["content-disposition"]) {
          fileName = response.headers["content-disposition"]
            .split("filename=")[1]
            .replaceAll('"', "")
            .replaceAll("%20", " ");
        }

        const blob = new Blob([response.data], {
          type: "application/excel",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getExportDataAll() {
  return new Promise((resolve, reject) => {
    api
      .get(`/ir21-template/export`, { responseType: "blob" })
      .then((response) => {
        let fileName = "file.xls";
        if (response.headers && response.headers["content-disposition"]) {
          fileName = response.headers["content-disposition"]
            .split("filename=")[1]
            .replaceAll('"', "")
            .replaceAll("%20", " ");
        }

        const blob = new Blob([response.data], {
          type: "application/excel",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getIr21ValuesByTadig(plmnId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/ir21-template/${plmnId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getSearchViewList(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/ir21/search`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function saveIr21Template(values) {
  return new Promise((resolve, reject) => {
    api
      .post("/ir21-template", values)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function upload(data) {
  return new Promise((resolve, reject) => {
    api
      .post("/ir21", data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
