import { Paper, TextField } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FiArrowLeft, FiCheck, FiFilter, FiX } from "react-icons/fi";
import Moment from "react-moment";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ReportModal from "../../../../../components/Report/Modal/ReportModal";
import { ErrorUnexpected } from "../../../../../const/Messages";
import * as ElementTypeService from "../../../../../service/ElementTypeService";
import * as PlmnService from "../../../../../service/PlmnService";
import * as ReportService from "../../../../../service/ReportService";
import * as VendorService from "../../../../../service/VendorService";

/**
 * @author Thiago Guimarães
 * @date 30/03/2021
 */
function TadigDetailView() {
  const history = useHistory();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [plmn, setPlmn] = useState({});
  const [reportList, setReportList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  let dateFilter = { start: "", end: "" };

  useEffect(() => {
    if (location && location.state && location.state.plmnId) {
      PlmnService.getById(location.state.plmnId)
        .then((plmn) => setPlmn(plmn))
        .catch((error) => toast.error(ErrorUnexpected));

      ReportService.getReportTadigDetail(location.state.plmnId)
        .then((list) => setReportList(list))
        .catch((error) => toast.error(ErrorUnexpected))
        .finally(() => setIsLoading(false));
    } else {
      history.push("/report/tadig-view");
    }
  }, []); // eslint-disable-line

  // Carrega os filtros por marcação.
  useEffect(() => {
    async function callBackFunction() {
      const elementTypes = await ElementTypeService.findAll();
      const vendors = await VendorService.findAll();

      let clone = [...columns];
      clone[3] = {
        title: "ELEMENT TYPE",
        field: "elementTypeName",
        lookup: Object.fromEntries(
          elementTypes.map((elemType) => [elemType.name, elemType.name])
        ),
      };

      clone[4] = {
        title: "VENDOR",
        field: "vendorName",
        lookup: Object.fromEntries(
          vendors.map((vendor) => [vendor.name, vendor.name])
        ),
      };

      setColumns(clone);
    }

    callBackFunction();
  }, []); // eslint-disable-line

  function handleModal(report) {
    const param = {
      plmnId: location.state.plmnId,
      snapshotUploadElementId: report.snapshotUploadElementId,
      elementTypeId: report.elementTypeId,
      vendorId: report.vendorId,
      commandId: report.commandId,
      resultId: report.resultId,
    };

    ReportService.getReportDetail(param)
      .then((reportDetail) => {
        setModalData(reportDetail);
        setShowModal(true);
      })
      .catch((error) => toast.error(ErrorUnexpected));
  }

  const [columns, setColumns] = useState([
    {
      title: "",
      field: "error",
      customSort: (obj1, obj2) => {
        if (obj1.error === obj2.error) {
          return 0;
        } else if (obj1.error) {
          return -1;
        } else {
          return 1;
        }
      },
      render: (obj) => {
        return obj.error ? <FiX color="red" /> : <FiCheck color="green" />;
      },
      width: "1%",
      lookup: { true: "With Errors", false: "No Errors" },
    },
    {
      title: "RESULT",
      field: "comparisonResult",
      render: (row) => row.comparisonResult.toUpperCase(),
    },
    {
      title: "ELEMENT",
      field: "elementName",
      render: (row) => (
        <>
          <button className="btn btn-link" onClick={() => handleModal(row)}>
            {row.elementName}
          </button>{" "}
          ({row.commandName})
        </>
      ),
    },
    { title: "ELEMENT TYPE", field: "elementTypeName" },
    { title: "VENDOR", field: "vendorName" },
    {
      title: "DATE",
      field: "date",
      render: (row) => <Moment format="DD/MM/yyyy HH:mm:ss">{row.date}</Moment>,
      filterComponent: (props) => (
        <>
          <TextField
            {...props}
            label="START"
            type="date"
            value={dateFilter.start}
            className="mr-2"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              dateFilter = { ...dateFilter, start: event.target.value };
              props.onFilterChanged(props.columnDef.tableData.id, "FILTER");
            }}
          />
          <TextField
            {...props}
            label="END"
            type="date"
            value={dateFilter.end}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              dateFilter = { ...dateFilter, end: event.target.value };
              props.onFilterChanged(props.columnDef.tableData.id, "FILTER");
            }}
          />
        </>
      ),
      customFilterAndSearch: (term, row) => {
        const date = moment(row.date);

        if (term === "FILTER") {
          if (dateFilter && (dateFilter.start || dateFilter.end)) {
            if (dateFilter.start && dateFilter.end) {
              return (
                date.isBetween(dateFilter.start, dateFilter.end, "day") ||
                date.isSame(dateFilter.start, "day") ||
                date.isSame(dateFilter.end, "day")
              );
            } else if (dateFilter.start) {
              return date.isSameOrAfter(dateFilter.start, "day");
            } else if (dateFilter.end) {
              return date.isSameOrBefore(dateFilter.end, "day");
            }
          } else {
            return true;
          }
        } else {
          return date.format("DD/MM/yyyy HH:mm:ss").indexOf(term) !== -1;
        }
      },
      customSort: (obj1, obj2) => new Date(obj1.date) - new Date(obj2.date),
    },
  ]);

  return (
    <div className="ibox">
      <div className="ibox-title">
        <h5>
          <FiArrowLeft
            className="mr-2"
            size="22"
            color="gray"
            style={{ verticalAlign: "middle", cursor: "pointer" }}
            onClick={() => history.push("/report/tadig-view")}
          />
          Report - TADIG ({plmn.name})
        </h5>
      </div>
      <div className="ibox-content">
        <MaterialTable
          title=""
          columns={columns}
          data={reportList}
          isLoading={isLoading}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          icons={{
            Filter: () => <FiFilter />,
          }}
          options={{
            rowStyle: {
              fontSize: 13,
            },
            pageSize: 10,
            pageSizeOptions: [5, 10, 15, 20],
            actionsColumnIndex: -1,
            exportButton: true,
            exportAllData: true,
            searchFieldAlignment: "left",
            toolbarButtonAlignment: "right",
            exportFileName: "list",
            search: false,
            filtering: true,
            headerStyle: {
              textTransform: "uppercase",
            },
          }}
        />
        <ReportModal
          data={modalData}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      </div>
    </div>
  );
}

export default TadigDetailView;
