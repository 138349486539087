import React, { useEffect, useState } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import Moment from "react-moment";
import LoadingGif from "../../assets/loading.gif";
import * as HomeService from "../../service/HomeService";
import LoadingBar from "../LoadingBar/LoadingBar";
import { Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import { formatNumber } from "../../utils/NumberUtils";

/**
 * @author Thiago Guimarães
 * @date 08/04/2021
 */
let countdownTimeOut = null;
const timeToRefresh = 5 * 60;

function ElementsWithErrorToday() {
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState("");
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);

  const [time, setTime] = useState(timeToRefresh);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [isActive, setIsActive] = useState(false);

  // Separa os minutos em duas unidades,colocando 0 no inicio quando necessario.
  const minuteLeftRight = String(Math.floor(time / 60))
    .padStart(2, "0")
    .split("");

  // Separa os segundos em duas unidades,colocando 0 no inicio quando necessario.
  const secondsLeftRight = String(time % 60)
    .padStart(2, "0")
    .split("");

  useEffect(() => {
    if (!isActive) {
      if (!loading) {
        setLoading(true);
        setErrors("");

        HomeService.getErrorsByElementType()
          .then((elements) => {
            setList(elements);
            setTotal(
              elements.reduce((value, element) => {
                return value + element.count;
              }, 0)
            );
          })
          .catch(() => setErrors("Something went wrong. Try again!!"))
          .finally(() => {
            clearTimeout(countdownTimeOut);
            setIsActive(true);
            setTime(timeToRefresh);
            setLastUpdate(new Date());
            setLoading(false);
          });
      }
    } else if (isActive && time > 0) {
      countdownTimeOut = setTimeout(() => setTime(time - 1), 1000);
    } else if (isActive && time === 0 && !loading) {
      setIsActive(false);
    }

    return () => {
      clearTimeout(countdownTimeOut);
    };
  }, [isActive, time]); // eslint-disable-line

  return (
    <div className="col">
      <div className="ibox">
        <div className="ibox-title d-flex flex-column">
          <h5 className="mb-1">Errors by Element Type</h5>
          <span className="text-muted" style={{ fontSize: "60%" }}>
            Last Update: <Moment format="DD/MM/yyyy HH:mm:ss">{lastUpdate}</Moment>
          </span>
        </div>
        <div className="ibox-content">
          {errors ? (
            <div className="text-center">
              <span className="text-danger">
                <FiAlertTriangle size="30" /> {errors}
              </span>
            </div>
          ) : (
            <div className="text-center">
              {loading ? (
                <img src={LoadingGif} width="40px" alt="Loading" />
              ) : (
                <Table>
                  <TableBody>
                    {list.map((item) => (
                      <TableRow>
                        <TableCell>{item.elementTypeName}</TableCell>
                        <TableCell className="text-right">{formatNumber(item.count)}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell className="font-weight-bold">Total</TableCell>
                      <TableCell className="font-weight-bold text-right">{formatNumber(total)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </div>
          )}
          <div className="text-center mt-3">
            <small>
              <span onClick={() => setTime(0)} className="" style={{ cursor: "pointer" }}>
                <small>
                  Refresh ({`${minuteLeftRight[0]}${minuteLeftRight[1]}:${secondsLeftRight[0]}${secondsLeftRight[1]}`})
                </small>
              </span>
            </small>
          </div>
          <LoadingBar percent={(time * 100) / timeToRefresh} />
        </div>
      </div>
    </div>
  );
}

export default ElementsWithErrorToday;
