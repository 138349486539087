import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { FiX } from "react-icons/fi";
import { toast } from "react-toastify";
import { ErrorUnexpected } from "../../const/Messages";
import * as GlobalTitleService from "../../service/GlobalTitleService";

const useStyles = makeStyles((theme) => {
  return {
    modalContent: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      overflow: "auto",

      maxHeight: "85%", 
      width: "100%",
      [theme.breakpoints.up("lg")]: {
        maxWidth: "calc(100vw - 65%)",
      },
      [theme.breakpoints.down("md")]: {
        maxWidth: "calc(100vw - 55%)",
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "calc(100vw - 20%)",
      },
    },
    closeButton: {
      position: "absolute",
      right: "1rem",
      top: "0.5rem",
      color: "#b7b7b7",
      cursor: "pointer",

      transition: "filter 0.2s",
      "&:hover": {
        filter: "brightness(0.8)",
      },
    },
    title: {
      textTransform: "uppercase",
      fontStyle: "italic",
    },
    tableContainer: {
      margin: "1rem auto",
      maxWidth: 600,
      width: "100%",
      maxHeight:"300px"
    },
    categoryBox: {
      "& + div": {
        marginTop: "2rem",
      },
    },
    verticalList: {
      "& span": {
        width: 75,
        fontWeight: 500,
        display: "inline-block",
        marginRight: 5,
      },
    },
  };
});

export function GlobalTitleModal({ isOpen, handleClose, params }) {
  const classes = useStyles();
  const [data, setData] = useState({});

  useEffect(() => {
    GlobalTitleService.getGlobalTitlePrefix(params)
      .then((values) => setData(values))
      .catch(() => toast.error(ErrorUnexpected));
  }, [params]);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Card className={classes.modalContent}>
        <CardHeader
          title={
            <Box>
              <Typography variant="h5">Global Title Prefix</Typography>
              <FiX onClick={handleClose} className={classes.closeButton} />
            </Box>
          }
        />
        <Divider />
        <CardContent className={classes.cardContent}>
          <Box className={classes.categoryBox}>
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>CC</TableCell>
                    <TableCell>NDC</TableCell>
                    <TableCell>SN Range Start</TableCell>
                    <TableCell>SN Range Stop</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{data.cc}</TableCell>
                    <TableCell>{data.ndc}</TableCell>
                    <TableCell>{data.rangeStart}</TableCell>
                    <TableCell>{data.rangeStop}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className={classes.categoryBox}>
            <Typography variant="h6" className={classes.title}>
              Single Prefix
            </Typography>
            <Divider />
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.verticalList}>{data.singlePrefix}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box className={classes.categoryBox}>
            <Typography variant="h6" className={classes.title}>
              All Prefixes
            </Typography>
            <Divider />
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table size="small">
                <TableBody>
                  {data.allPrefixes?.map((prefix) => (
                    <TableRow>
                      <TableCell>{prefix}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
}
