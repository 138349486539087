import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FiArrowLeft, FiFrown } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { ErrorUnexpected } from "../../../const/Messages";
import { toast } from "react-toastify";
import { makeStyles, Button, Card, CardHeader, CardContent } from "@material-ui/core";
import * as Yup from "yup";
import * as AuthenticationService from "../../../service/AuthenticationService";
import ReactTooltip from "react-tooltip";
import LoginLayout from "../../../components/LoginLayout/LoginLayout";

const useStyles = makeStyles((theme) => {
  return {
    box: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    card: {
      maxWidth: 600,
      maxHeight: 475,
      width: "96%",
      height: "100%",
    },
    cardContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",

      "& form": {
        width: "100%",
      },
    },
  };
});

/**
 * @author Thiago Guimarães
 * @date 17/02/2021
 */
function GenerateToken() {
  const history = useHistory();
  const classes = useStyles();

  function handleSubmit(values, actions) {
    AuthenticationService.recovery(values.email)
      .then((response) => history.push("/forgotpassword/success"))
      .catch((error) => {
        toast.error(ErrorUnexpected);
      })
      .finally(() => actions.setSubmitting(false));
  }

  const schema = Yup.object().shape({
    email: Yup.string().email("Email must be a valid email").required("Email is a required field"),
  });

  return (
    <LoginLayout>
      <div className={classes.box}>
        <Card className={classes.card}>
          <CardHeader
            title={
              <div>
                <Link to="/login" data-for="main" data-tip="BACK TO HOME">
                  <FiArrowLeft size={30} />
                </Link>
                <ReactTooltip id="main" effect="solid" place="bottom" />
              </div>
            }
          />
          <CardContent className={classes.cardContent}>
            <FiFrown size={175} />
            <h1>Forgot Your Password?</h1>
            <p>Enter your email address below to receive your password reset instructions.</p>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <Field
                      className="form-control w-100"
                      name="email"
                      type="email"
                      placeholder="Email"
                      autoComplete="username"
                    />
                    <span className="text-danger">
                      <ErrorMessage name="email" />
                    </span>
                  </div>
                  <div className="form-group">
                    <Button type="submit" variant="contained" color="primary" fullWidth disabled={isSubmitting}>
                      Recover Password
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </div>
    </LoginLayout>
  );
}

export default GenerateToken;
