import React, { useState } from "react";
import { Box, IconButton, makeStyles, TextField, Tooltip } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import * as Yup from "yup";
import { FiPlus, FiMinus } from "react-icons/fi";

export const apnSchema = Yup.object().shape({
  apnOperatorIdentifierList: Yup.array()
    .of(
      Yup.string()
        .matches(/^.*mnc[0-9]{3}\.mcc[0-9]{3}.*$/, "The value filled in is not a valid APN. (*mncXXX.mccXXX*)")
        .label("APN")
    ),
});

export const apnInitialValues = {
  apnOperatorIdentifierList: [],
};

const useStyles = makeStyles({
  input: {
    "& + div": {
      marginTop: 15,
    },
  },
  container: {
    display: "flex",
    alignItems: "center",

    "&  div": {
      flex: 1,
    },

    "& + div": {
      marginTop: 15,
    },

    "& div:only-child": {
      marginRight: 48,
    },
  },
});

function Ir21TemplateApn() {
  const { errors, values, setFieldValue } = useFormikContext();
  const classes = useStyles();
  const [extraInputs, setExtraInputs] = useState(
    values?.apnOperatorIdentifierList?.length > 0 ? values.apnOperatorIdentifierList.length - 1 : 0
  );

  function handleAddInput() {
    const array = values.apnOperatorIdentifierList;
    array.push("");

    setFieldValue("apnOperatorIdentifierList", array);
    setExtraInputs((current) => current + 1);
  }

  function handleRemoveInput() {
    const array = values.apnOperatorIdentifierList;
    array.pop();

    setFieldValue("apnOperatorIdentifierList", array);
    setExtraInputs((current) => current - 1);
  }

  function hasError(arrayIndex) {
    if (errors) {
      if (errors.apnOperatorIdentifierList) {
        if (errors.apnOperatorIdentifierList[arrayIndex]) {
          return true;
        }
      }
    }
    return false;
  }

  function getErrorMessage(arrayIndex) {
    if (errors) {
      if (errors.apnOperatorIdentifierList) {
        if (typeof errors.apnOperatorIdentifierList === "string") {
          return errors.apnOperatorIdentifierList;
        } else if (errors.apnOperatorIdentifierList[arrayIndex]) {
          return errors.apnOperatorIdentifierList[arrayIndex];
        }
      }
    }

    return "";
  }

  return (
    <>
      <TextField
        label="TADIG"
        defaultValue=" "
        value={values.plmnName}
        disabled
        className={classes.input}
        variant="outlined"
      />
      <Box className={classes.container}>
        <Field
          as={TextField}
          label="APN"
          name="apnOperatorIdentifierList[0]"
          variant="outlined"
          error={hasError(0)}
          helperText={getErrorMessage(0)}
        />
        <Tooltip title="ADD MORE APNS">
          <IconButton onClick={handleAddInput}>
            <FiPlus size="1.5rem" />
          </IconButton>
        </Tooltip>
      </Box>
      {[...Array(Number(extraInputs))].map((value, index) => (
        <Box key={index} className={classes.container}>
          <Field
            as={TextField}
            label="APN"
            name={`apnOperatorIdentifierList[${index + 1}]`}
            variant="outlined"
            error={hasError(index + 1)}
            helperText={getErrorMessage(index + 1)}
          />
          {index + 1 === extraInputs && (
            <Tooltip title="REMOVE APN">
              <IconButton onClick={handleRemoveInput}>
                <FiMinus size="1.5rem" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ))}
    </>
  );
}

export default Ir21TemplateApn;
