import { Box, Drawer as MuiDrawer, List } from "@material-ui/core";
import { useMenuContext } from "../../context/MenuContext";
import { useEffect } from "react";
import MenuItem from "./MenuLeft/MenuItem";
import { menus } from "./MenuLeft/Menus";
import { styled } from "@material-ui/styles"
import { SubMenu } from "../subMenu";

function MenuLeft() {
  const { isOpen, setIsOpen, toogleMenu, subMenu } = useMenuContext();
  
  useEffect(() => {
    setIsOpen(false);
  }, [location]); // eslint-disable-line

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // @ts-ignore necessary for content to be below app bar
    ...theme.mixins.toolbar,
    width: '100px'
  }));

  return (
    <Box component="nav" style={{ minWidth: '100' }}>
      <MuiDrawer
        variant="temporary"
        open={isOpen}
        onClose={toogleMenu}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        style={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: subMenu ? 400 : 120
          },
        }}
      >
        <DrawerHeader />
        <Box style={{ display: "flex", height: "100%", width: subMenu ? 400 : 120 }}>
          <List className="HideScrollLeftMenu" style={{ maxWidth: "120px", width: "120px", position: "fixed", overflowY:"scroll", overflowX:"hidden", borderRight: "1px solid rgba(0, 0, 0, 0.12", height: "90%" }}>
            {menus.map((menu, index) => (
              <MenuItem key={menu.id} level={1} item={menu} />
            ))}
          </List>
          <SubMenu />
        </Box>
      </MuiDrawer>
    </Box>
  );
}
export default MenuLeft;