import { IconButton, Tooltip } from "@material-ui/core";

export function IconTooltip({ title, icon, button = false, onClick }) {
  if (button && onClick) {
    return (
      <IconButton onClick={onClick}>
        <Tooltip title={title}>
          <label style={{ cursor: button ? "pointer" : "default" }}>{icon}</label>
        </Tooltip>
      </IconButton>
    );
  }
  return (
    <Tooltip title={title}>
      <label style={{ cursor: button ? "pointer" : "default" }}>{icon}</label>
    </Tooltip>
  );
}
