import api from "./api";

export function findAll(commandId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/snapshot-ignored/${commandId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteById(commandId, id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/snapshot-ignored/${commandId}/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
