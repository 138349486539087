import { Paper, TextField } from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiCheck, FiFilter, FiX } from "react-icons/fi";
import Moment from "react-moment";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ReportModal from "../../../../components/Report/Modal/ReportModal";
import { ErrorUnexpected } from "../../../../const/Messages";
import * as CountryService from "../../../../service/CountryService";
import * as ElementTypeService from "../../../../service/ElementTypeService";
import * as ReportService from "../../../../service/ReportService";
import * as VendorService from "../../../../service/VendorService";

/**
 * @author Thiago Guimarães
 * @date 05/03/2021
 */
function ReportCountryView() {
  const location = useLocation();

  const [countryList, setCountryList] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  let dateFilter = { start: "", end: "" };

  const formikRef = useRef(null);

  useEffect(() => {
    if (location && location.state && location.state.countryId) {
      ReportService.getReportCountryView(location.state.countryId)
        .then((reports) => setReportList(reports))
        .catch(() => toast.error(ErrorUnexpected))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }

    CountryService.findAll()
      .then((countries) => {
        setCountryList(countries);

        if (location && location.state && location.state.countryId) {
          formikRef.current.setFieldValue(
            "countryId",
            location.state.countryId
          );
        }
      })
      .catch((error) => toast.error(ErrorUnexpected));
  }, []); // eslint-disable-line

  // Carrega os filtros por marcação.
  useEffect(() => {
    async function callBackFunction() {
      const elementTypes = await ElementTypeService.findAll();
      const vendors = await VendorService.findAll();

      let elementType = null;
      if (
        location &&
        location.state &&
        location.state.filters &&
        location.state.filters.elementTypeId
      ) {
        elementType = await ElementTypeService.getById(
          location.state.filters.elementTypeId
        );
      }

      let clone = [...columns];
      clone[5] = {
        title: "ELEMENT TYPE",
        field: "elementType",
        lookup: Object.fromEntries(
          elementTypes.map((elemType) => [elemType.name, elemType.name])
        ),
        defaultFilter: elementType && [elementType.name],
      };

      clone[6] = {
        title: "VENDOR",
        field: "vendor",
        lookup: Object.fromEntries(
          vendors.map((vendor) => [vendor.name, vendor.name])
        ),
      };

      setColumns(clone);
    }

    callBackFunction();
  }, []); // eslint-disable-line

  function handleFilter(values, actions) {
    setIsLoading(true);
    ReportService.getReportCountryView(values.countryId)
      .then((reports) => {
        setReportList(reports);
      })
      .catch((error) => {
        toast.error(ErrorUnexpected);
      })
      .finally(() => {
        setIsLoading(false);
        actions.setSubmitting(false);
      });
  }

  const schema = Yup.object().shape({
    countryId: Yup.string().required("Country is a required field"),
  });

  function handleModal(report) {
    const param = {
      plmnId: report.plmnId,
      snapshotUploadElementId: report.snapshotElementId,
      elementTypeId: report.elementTypeId,
      vendorId: report.vendorId,
      commandId: report.commandId,
      resultId: report.resultId,
    };

    ReportService.getReportDetail(param)
      .then((reportDetail) => {
        setModalData(reportDetail);
        setShowModal(true);
      })
      .catch((error) => toast.error(ErrorUnexpected));
  }

  const [columns, setColumns] = useState([
    {
      field: "error",
      title: " ",
      render: (row) =>
        row.error ? <FiX color="red" /> : <FiCheck color="green" />,
      width: "1%",
      lookup: { true: "With Errors", false: "No Errors" },
    },
    {
      field: "comparisonResult",
      title: "RESULT",
      width: "1%",
      render: (obj) => obj.comparisonResult.toUpperCase(),
    },
    { field: "organisation", title: "ORGANISATION" },
    { field: "plmn", title: "TADIG" },
    {
      field: "element",
      title: "ELEMENT",
      render: (row) => (
        <>
          <button className="btn btn-link" onClick={() => handleModal(row)}>
            {row.element}
          </button>
          ({row.commandName})
        </>
      ),
    },
    { field: "elementType", title: "ELEMENT TYPE" },
    { field: "vendor", title: "VENDOR" },
    {
      field: "date",
      title: "DATE",
      render: (row) => <Moment format="DD/MM/yyyy HH:mm:ss">{row.date}</Moment>,
      filterComponent: (props) => (
        <>
          <TextField
            {...props}
            label="START"
            type="date"
            value={dateFilter.start}
            className="mr-2"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              dateFilter = { ...dateFilter, start: event.target.value };
              props.onFilterChanged(props.columnDef.tableData.id, "FILTER");
            }}
          />
          <TextField
            {...props}
            label="END"
            type="date"
            value={dateFilter.end}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              dateFilter = { ...dateFilter, end: event.target.value };
              props.onFilterChanged(props.columnDef.tableData.id, "FILTER");
            }}
          />
        </>
      ),
      customFilterAndSearch: (term, row) => {
        const date = moment(row.date);

        if (term === "FILTER") {
          if (dateFilter && (dateFilter.start || dateFilter.end)) {
            if (dateFilter.start && dateFilter.end) {
              return (
                date.isBetween(dateFilter.start, dateFilter.end, "day") ||
                date.isSame(dateFilter.start, "day") ||
                date.isSame(dateFilter.end, "day")
              );
            } else if (dateFilter.start) {
              return date.isSameOrAfter(dateFilter.start, "day");
            } else if (dateFilter.end) {
              return date.isSameOrBefore(dateFilter.end, "day");
            }
          } else {
            return true;
          }
        } else {
          return date.format("DD/MM/yyyy HH:mm:ss").indexOf(term) !== -1;
        }
      },
      customSort: (obj1, obj2) => new Date(obj1.date) - new Date(obj2.date),
    },
  ]);

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>Report - Country view</h5>
          </div>
          <div className="ibox-content">
            <div className="mt-3 mb-5">
              <Formik
                initialValues={{ countryId: "" }}
                onSubmit={handleFilter}
                validationSchema={schema}
                innerRef={formikRef}
              >
                {({ isSubmitting }) => (
                  <Form className="form-inline align-items-start">
                    <div className="mr-2 d-flex flex-column">
                      <Field
                        as="select"
                        name="countryId"
                        className="form-control"
                      >
                        <option value="">Country</option>
                        {countryList.map((country) => (
                          <option value={country.id} key={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </Field>
                      <span className="text-danger ml-2">
                        <ErrorMessage name="countryId" />
                      </span>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        Filter
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <div>
              <MaterialTable
                title=""
                columns={columns}
                data={reportList}
                components={{
                  Container: (props) => <Paper {...props} elevation={0} />,
                }}
                icons={{
                  Filter: () => <FiFilter />,
                }}
                isLoading={isLoading}
                options={{
                  rowStyle: {
                    fontSize: 13,
                  },
                  pageSize: 10,
                  pageSizeOptions: [5, 10, 15, 20],
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData: true,
                  searchFieldAlignment: "left",
                  toolbarButtonAlignment: "right",
                  exportFileName: "report_country_view",
                  search: false,
                  filtering: true,
                  headerStyle: {
                    textTransform: "uppercase",
                  },
                }}
              />
              <ReportModal
                data={modalData}
                setShowModal={setShowModal}
                showModal={showModal}
              />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ReportCountryView;
