import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
import { FiList } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ErrorUnexpected } from "../../../../../const/Messages";
import * as ElementService from "../../../../../service/ElementService";
// import ElementUploadBatchModal from "./ElementUploadBatchModal";

/**
 * @author Thiago Guimarães
 * @date 25/03/2021
 */
function ElementSummaryList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [summaryList, setSummaryList] = useState([]);
  // const [modalBatch, setModalBatch] = useState(false);

  useEffect(() => {
    ElementService.getSummaryList()
      .then((list) => setSummaryList(list))
      .catch(() => toast.error(ErrorUnexpected))
      .finally(() => setIsLoading(false));
  }, []); // eslint-disable-line

  // function handleOpenBatchModal() {
  //   setModalBatch(true);
  // }

  const columns = [
    { field: "vendorName", title: "VENDOR" },
    { field: "elementTypeName", title: "ELEMENT TYPE" },
    { field: "count", title: "TOTAL" },
  ];
  return (
    <div className="ibox">
      <div className="ibox-title d-flex justify-content-between align-items-center">
        <h5>Network Element - Summary</h5>
        <div>
          {/* <Button className="mr-2" variant="primary" type="button" onClick={() => handleOpenBatchModal()}>
            Batch Upload
          </Button> */}
          <Link className="btn btn-primary" to="/settings/network-element/reg">
            New Element
          </Link>
        </div>
      </div>
      <div className="ibox-content">
        <MaterialTable
          title=""
          columns={columns}
          data={summaryList}
          isLoading={isLoading}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          options={{
            rowStyle: {
              fontSize: 13,
            },
            pageSize: 10,
            pageSizeOptions: [5, 10, 15, 20],
            actionsColumnIndex: -1,
            exportButton: true,
            exportAllData: true,
            searchFieldAlignment: "left",
            toolbarButtonAlignment: "right",
            exportFileName: "elements",
            headerStyle: {
              textTransform: "uppercase",
            },
          }}
          actions={[
            {
              icon: () => <FiList size={16} color="#007bff" />,
              tooltip: "DETAIL LIST",
              onClick: (event, rowData) =>
                history.push("/settings/network-element/detail/", {
                  elementTypeId: rowData.elementTypeId,
                  vendorId: rowData.vendorId,
                }),
            },
          ]}
        />
        {/* <ElementUploadBatchModal modal={modalBatch} setModal={setModalBatch} /> */}
      </div>
    </div>
  );
}

export default ElementSummaryList;
