import * as VertisPostgresAnalyzerService from "../../../../service/VertisPostgresAnalyzerService";
import { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { ErrorUnexpected } from "../../../../const/Messages";

/**
* @author Guilherme Almeida
* @date 02/09/2021
*/
function PostgresAnalyzer() {
  const [list, setList] = useState([]);

  useEffect(() => {
    VertisPostgresAnalyzerService.findTableDetail()
    .then((list) => setList(list))
    .catch(() => { toast.error(ErrorUnexpected); });;
  }, []);

  const columns = [

    {
      title: "Table",
      field: "tableName",
    },
    {
      title: "Row Count",
      field: "rowCount",
    },
    {
      title: "Pages",
      field: "pages",
    },
    {
      title: "Table Size",
      field: "tableSize",
    },
    {
      title: "Indexes Size",
      field: "indexesSize",
    },
    {
      title: "Total Size",
      field: "totalSize",
    },
  ];

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between align-items-center">
            <h5>Postgres Analyzer - List</h5>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={list}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: false,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "list",
                headerStyle: {
                  textTransform: "uppercase",
                }
              }}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default PostgresAnalyzer;
