import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { toast } from "react-toastify";
import { ErrorUnexpected } from "../../../../const/Messages";
import * as Ir21Service from "../../../../service/Ir21Service";
import Ir21SearchModal from "./Ir21SearchModal";

/**
 * @author Thiago Guimarães
 * @date 23/03/2021
 */
function Search() {
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const [organisationId, setOrganisationId] = useState("");
  const [organisationName, setOrganisationName] = useState("");

  useEffect(() => {
    Ir21Service.getSearchViewList()
      .then((ir21List) => {
        setList(ir21List);
      })
      .catch(() => {
        toast.error(ErrorUnexpected);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  function handleDownload(organisationId, organisationName) {
    setShowModal(true);
    setOrganisationId(organisationId);
    setOrganisationName(organisationName);
  }

  return (
    <div className="ibox">
      <div className="ibox-title">
        <h5>IR.21 - Search</h5>
      </div>
      <div className="ibox-content">
        <MaterialTable
          title=""
          columns={[
            {
              field: "organisationName",
              title: "ORGANISATION",
            },
            { field: "plmns", title: "TADIGS" },
          ]}
          data={list}
          isLoading={isLoading}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
          }}
          options={{
            rowStyle: {
              fontSize: 13,
            },
            pageSize: 10,
            pageSizeOptions: [5, 10, 15, 20],
            actionsColumnIndex: -1,
            exportButton: true,
            exportAllData: true,
            searchFieldAlignment: "left",
            toolbarButtonAlignment: "right",
            exportFileName: "ir21",
            headerStyle: {
              textTransform: "uppercase",
            },
          }}
          actions={[
            {
              icon: () => <FiDownload size={16} color="#007bff" />,
              tooltip: "Download",
              onClick: (event, rowData) =>
                handleDownload(
                  rowData.organisationId,
                  rowData.organisationName
                ),
            },
          ]}
        />
        <Ir21SearchModal
          setShowModal={setShowModal}
          showModal={showModal}
          organisationId={organisationId}
          organisationName={organisationName}
        />
      </div>
    </div>
  );
}

export default Search;
