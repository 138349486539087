import api from "./api";

export function deleteById(id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/hubs/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/hubs")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/hubs/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function merge(id, hub) {
  return new Promise((resolve, reject) => {
    api({
      method: id ? "put" : "post",
      url: id ? `/hubs/${id}` : "/hubs",
      data: hub,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
