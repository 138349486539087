import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { ErrorUnexpected, OperationSuccessfully } from "../../../const/Messages";

import * as Yup from "yup";
import * as VendorService from "../../../service/VendorService";
import * as ElementTypeService from "../../../service/ElementTypeService";
import * as ElementService from "../../../service/ElementService";
import * as NeLogsFileService from "../../../service/NeLogsFileService";

const initialValues = {
  vendor: "",
  elementType: "",
  element: "",
};

function LogSingleUploadModal({ show, setShow }) {
  const [vendorList, setVendorList] = useState([]);
  const [elementTypeList, setElementTypeList] = useState([]);
  const [elementList, setElementList] = useState([]);

  const formikRef = useRef(null);
  const SUPPORTED_FORMATS = ["text/plain"];

  useEffect(() => {
    if (show) {
      VendorService.findAll()
        .then((vendors) => setVendorList(vendors))
        .catch(() => toast.error(ErrorUnexpected));
    }
  }, [show]);

  const schema = Yup.object().shape({
    elementType: Yup.number().required("Element Type is a required field"),
    vendor: Yup.number().required("Vendor is a required field"),
    element: Yup.number().required("Element is a required field"),
    file: Yup.mixed()
      .required("File is required")
      .test("fileFormat", "Unsupported Format", (value) => value && (SUPPORTED_FORMATS.includes(value.type) || value.name.includes(".log")))
  });

  function handleCloseModal() {
    setShow(false);
  }

  function loadElementTypeCombo(vendorId) {
    setElementTypeList([]);
    setElementList([]);

    formikRef.current.setFieldValue("elementType", "");
    formikRef.current.setFieldValue("element", "");

    if (vendorId) {
      ElementTypeService.findByVendorId(vendorId)
        .then((elementTypes) => setElementTypeList(elementTypes))
        .catch((error) => toast.error(ErrorUnexpected));
    }
  }

  function loadElementCombo(vendorId, elementTypeId) {
    setElementList([]);
    formikRef.current.setFieldValue("element", "");

    if (vendorId && elementTypeId) {
      ElementService.getByVendorAndElementType(vendorId, elementTypeId)
        .then((elements) => setElementList(elements))
        .catch((error) => toast.error(ErrorUnexpected));
    }
  }

  function handleUpload(values, actions) {
    const form = new FormData();
    form.append("vendor", values.vendor);
    form.append("elementType", values.elementType);
    form.append("element", values.element);
    form.append("file", values.file);

    NeLogsFileService.upload(form)
      .then(() => {
        toast.success(OperationSuccessfully);
        handleCloseModal();
      })
      .catch((error) => toast.error(ErrorUnexpected))
      .finally(() => actions.setSubmitting(false));
  }

  return (
    <Modal show={show} onHide={handleCloseModal} size="lg" animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Single Log Upload</Modal.Title>
      </Modal.Header>
      <Formik initialValues={initialValues} innerRef={formikRef} validationSchema={schema} onSubmit={handleUpload}>
        {({ values, setFieldValue, isSubmitting, errors }) => (
          <Form>
            <Modal.Body>
              <div className="form-group row">
                <label htmlFor="vendor" className="col-sm-3 col-form-label">
                  Vendor:
                </label>
                <div className="col-sm-6">
                  <Field
                    as="select"
                    name="vendor"
                    className="form-control"
                    value={values.vendor}
                    onChange={(ev) => {
                      setFieldValue("vendor", ev.target.value);
                      loadElementTypeCombo(ev.target.value);
                    }}
                  >
                    <option value="">-- Select Option --</option>
                    {vendorList.map((vendor) => (
                      <option value={vendor.id} key={vendor.id}>
                        {vendor.name}
                      </option>
                    ))}
                  </Field>
                  <span className="text-danger">
                    <ErrorMessage name="vendor" />
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="elementType" className="col-sm-3 col-form-label">
                  Element Type:
                </label>
                <div className="col-sm-6">
                  <Field
                    as="select"
                    name="elementType"
                    className="form-control"
                    value={values.elementType}
                    onChange={(ev) => {
                      setFieldValue("elementType", ev.target.value);
                      loadElementCombo(values.vendor, ev.target.value);
                    }}
                  >
                    <option value="">-- Select Option --</option>
                    {elementTypeList.map((elementType) => (
                      <option value={elementType.id} key={elementType.id}>
                        {elementType.name}
                      </option>
                    ))}
                  </Field>
                  <span className="text-danger">
                    <ErrorMessage name="elementType" />
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="elementType" className="col-sm-3 col-form-label">
                  Element:
                </label>
                <div className="col-sm-6">
                  <Field
                    as="select"
                    name="element"
                    className="form-control"
                    value={values.element}
                    onChange={(ev) => {
                      setFieldValue("element", ev.target.value);
                    }}
                  >
                    <option value="">-- Select Option --</option>
                    {elementList.map((element) => (
                      <option value={element.id} key={element.id}>
                        {element.name}
                      </option>
                    ))}
                  </Field>
                  <span className="text-danger">
                    <ErrorMessage name="element" />
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="element" className="col-sm-3 col-form-label">
                  File:
                </label>
                <div className="custom-file col-sm-6">
                  <input
                    name="file"
                    type="file"
                    className="custom-file-input form-control"
                    onChange={(ev) => {
                      setFieldValue("file", ev.target.files[0]);
                    }}
                    accept="text/plain"
                  />
                  <label
                    className="custom-file-label form-control"
                    style={{ position: "relative", top: -40 }}
                    htmlFor="file"
                  >
                    {values?.file ? values.file.name : "Choose file"}
                  </label>
                  <span className="text-danger" style={{ position: "relative", top: -40 }}>
                    {errors.file && errors.file}
                  </span>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="mt-3 mr-4 d-flex justify-content-end">
                <Button className="mr-1" onClick={handleCloseModal} variant="primary" type="button">
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Upload
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default LogSingleUploadModal;
