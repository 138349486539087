import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ErrorUnexpected, OperationSuccessfully } from "../../../../../const/Messages";
import * as RealmSpecificService from "../../../../../service/RealmSpecificService";

/**
  @author Guilherme Almeida
  @date 10/02/2022
 */
export default function RealmsList() {
  const history = useHistory();
  const [realms, setRealms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    RealmSpecificService.findAll()
      .then((RealmList) => {
        setRealms(
          RealmList.sort((a, b) => {
            if (a.plmnName > b.plmnName) {
              return 1;
            }
            if (a.plmnName < b.plmnName) {
              return -1;
            }
            return 0;
          })
        );
      })
      .catch(() => {
        toast.error(ErrorUnexpected);
      }).finally(() => setIsLoading(false));
  }, []); // eslint-disable-line

  function handleDelete(id) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        RealmSpecificService.deleteById(id)
          .then(() => {
            RealmSpecificService.findAll()
              .then((RealmList) => {
                setRealms(
                  RealmList.sort((a, b) => {
                    if (a.plmnName > b.plmnName) {
                      return 1;
                    }
                    if (a.plmnName < b.plmnName) {
                      return -1;
                    }
                    return 0;
                  })
                );
                toast.success(OperationSuccessfully);
              })
              .catch(() => {
                toast.error(ErrorUnexpected);
              });
          })
          .catch(() => {
            toast.error(ErrorUnexpected);
          });
      }
    });
  }

  const columns = [
    {
      title: "TADIG",
      field: "plmnName",
    },
    {
      title: "REALM",
      field: "realm",
    },
  ];

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between align-items-center">
            <h5>Realm - List</h5>
            <div>
              <Link
                className="btn btn-primary"
                to={{
                  pathname: "/settings/realm/reg",
                  state: { id: "" },
                }}
              >
                ADD Realm
              </Link>
            </div>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={realms}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: false,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
              actions={[
                {
                  icon: () => <FiEdit size={16} color="#007bff" />,
                  tooltip: "EDIT",
                  onClick: (event, rowData) => history.push("/settings/realm/reg", { id: rowData.plmnId }),
                },
                {
                  icon: () => <FiTrash2 size={16} color="#007bff" />,
                  tooltip: "DELETE",
                  onClick: (event, rowData) => handleDelete(rowData.id),
                },
              ]}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
