import api from "./api";

export function updateir21files() {
  return new Promise((resolve, reject) => {
    api
      .get("/vertis/fazpramim/update-file-hash")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function findResume() {
  return new Promise((resolve, reject) => {
    api
      .get("/vertis/fazpramim/resume")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateIr21Redundant() {
  return new Promise((resolve, reject) => {
    api
      .get("/vertis/fazpramim/ir21/redundant")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
