import api from './api';

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/realm-specific")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getRealmsByTadig(plmnId) {
    return new Promise((resolve, reject) => {
      api
        .get(`/realm-specific/${plmnId}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

export function save(values) {
    return new Promise((resolve, reject) => {
      api
        .post("/realm-specific", values)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  }

export function deleteById(id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/realm-specific/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

