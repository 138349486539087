import React from "react";
import { Modal } from "react-bootstrap";
import { FiX, FiCheck } from "react-icons/fi";

/**
 * @author Thiago Guimarães
 * @date 09/03/2021
 */
function ReportModal({ showModal, setShowModal, data }) {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="xl" animation={false} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>{data.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <table className="table table-striped">
          <thead>
            <tr>
              <th></th>
              <th>Field Name</th>
              <th className="text-center">Log Value</th>
              <th className="text-center">Template Value</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.fields &&
              data.fields.map((fieldName, indexKey) => (
                <tr key={indexKey}>
                  <td>{data.errors[indexKey] == null ? "" : data.errors[indexKey] ? <FiX color="red" /> : <FiCheck color="green" />}</td>
                  <td>{fieldName.replaceAll('_', ' ')}</td>
                  <td className="text-center">{(data.logValues[indexKey] && data.logValues[indexKey] !== "null") ? `"${data.logValues[indexKey]}"` : "-"}</td>
                  <td className="text-center">{(data.refValues[indexKey] && data.refValues[indexKey] !== "null") ? `"${data.refValues[indexKey]}"` : "-"}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
}

export default ReportModal;
