import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import Moment from "react-moment";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, CardHeader, Divider, Paper, Typography } from "@material-ui/core";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import { ErrorUnexpected, OperationSuccessfully } from "../../../const/Messages";
import * as AlertService from "../../../service/AlertService";


function AlertList() {
  const [alertList, setAlertList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = () => {
    AlertService.findAll()
      .then((alerts) => setAlertList(alerts))
      .catch((error) => toast.error(ErrorUnexpected))
      .finally(() => setIsLoading(false));
  }

  const handleMarkAsRead = (alert) => {
    AlertService.markAsRead(alert.id)
      .then(() => {
        toast.success(OperationSuccessfully);
        updateData();
      })
      .catch(() => toast.error(ErrorUnexpected));
  };

  return (
    <Card>
      <CardHeader title={
        <Box>
          <Typography variant="h5">Notifications</Typography>
        </Box>
      } />
      <Divider />
      <PerfectScrollbar>
        <Box style={{ minWidth: 1024, margin: "0 25px" }}>
          <MaterialTable
            title=""
            data={alertList}
            columns={[
              {
                title: "Date",
                field: "occurrenceDate",
                render: (row) => <Moment format="DD/MM/yyyy HH:mm:ss">{row.occurrenceDate}</Moment>,
              },
              {
                title: "Functionality",
                field: "functionalityName",
              },
              {
                title: "Message",
                field: "message",
              },
            ]}
            isLoading={isLoading}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
            options={{
              rowStyle: {
                fontSize: 13,
              },
              pageSize: 10,
              pageSizeOptions: [5, 10, 15, 20],
              actionsColumnIndex: -1,
              searchFieldAlignment: "left",
              toolbarButtonAlignment: "right",
              exportButton: false,
              exportFileName: "Notifications",
            }}
            actions={[
              (row) => {
                if (!row.readed) {
                  return {
                    icon: () => <IoCheckmarkCircleSharp size={16} color="#007bff" />,
                    tooltip: "Mark as Read",
                    onClick: (event, rowData) => handleMarkAsRead(rowData),
                  };
                }
              },
            ]}
          />
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}

export default AlertList;
