import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { ErrorUnexpected, OperationCancelled, OperationSuccessfully } from "../../../../../const/Messages";
import { useHistory, useLocation } from "react-router";
import Ir21TemplateApn, { apnInitialValues, apnSchema } from "./Ir21TemplateApn";
import Ir21TemplateCamel, { camelInitialValues, camelSchema } from "./Ir21TemplateCamel";
import Ir21TemplateGeneral, { generalInitialValues, generalSchema } from "./Ir21TemplateGeneral";
import Ir21TemplateGlobalTitle, { globalTitleInitialValues, globalTitleSchema } from "./Ir21TemplateGlobalTitle";
import Ir21TemplateMapVersion, { mapVersionInitialValues, mapVersionSchema } from "./Ir21TemplateMapVersion";
import Ir21TemplateRealms, { realmsInitialValues, realmsSchema } from "./Ir21TemplateRealms";
import Ir21TemplateView from "./Ir21TemplateView";
import * as Ir21Service from "../../../../../service/Ir21Service";

const useStyles = makeStyles({
  stepMenus: {
    maxWidth: 1500,
    width: "100%",
    margin: "0 auto",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "calc(100% - 20%)",
    width: "100%",
    margin: "0 auto",
  },
  input: {
    "& + div": {
      marginTop: 15,
    },
  },
  bottomButtons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,

    "& button + button": {
      marginLeft: 10,
    },
  },
});

function Ir21Template() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const formikRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);

  const initialValues = {
    ...generalInitialValues,
    ...mapVersionInitialValues,
    ...apnInitialValues,
    ...camelInitialValues,
    ...globalTitleInitialValues,
    ...realmsInitialValues,
  };

  const schema = [generalSchema, mapVersionSchema, apnSchema, camelSchema, globalTitleSchema, realmsSchema];

  useEffect(() => {
    if (location?.state?.plmnId) {
      if (location?.state?.ir21Template) {
        Ir21Service.getIr21ValuesByTadig(location.state.plmnId)
          .then((values) => formikRef.current.setValues(values))
          .catch((error) => toast.error(ErrorUnexpected));
      } else {
        formikRef.current.setFieldValue("plmnId", location?.state?.plmnId);
        formikRef.current.setFieldValue("plmnName", location?.state?.plmnName);
      }
    } else {
      history.push("/ir21/settings/tadigs", {
        message: ErrorUnexpected,
        messageType: "error",
      });
    }
  }, [location]); // eslint-disable-line

  function handleBack() {
    validationValues();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function handleNext() {
    validationValues();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function validationValues(){
    const {values, setFieldValue} = formikRef.current;
    
    setFieldValue("apnOperatorIdentifierList", values.apnOperatorIdentifierList.filter((item) => item));
    setFieldValue("camelCAPVerSuppMSCInboundList", values.camelCAPVerSuppMSCInboundList.filter((item) => item));
    setFieldValue("camelCAPVerSuppMSCOutboundList", values.camelCAPVerSuppMSCOutboundList.filter((item) => item));
    setFieldValue("e164NumberSeriesList", values.e164NumberSeriesList.filter((item) => item.cc && item.ndc));
    setFieldValue("epcRealmsForRoamingList", values.epcRealmsForRoamingList.filter((item) => item));
  }

  function isLastStep() {
    return activeStep === 6;
  }

  function handleCancel() {
    history.push("/ir21/settings/tadigs", {
      message: OperationCancelled,
      messageType: "info",
    });
  }

  function handleSave(values, actions) {
    if (isLastStep()) {
      Ir21Service.saveIr21Template(values)
        .then(() =>
          history.push("/ir21/settings/tadigs", {
            message: OperationSuccessfully,
            messageType: "success",
          })
        )
        .catch((error) => {
          if (!error || !error.response || !error.response.data) {
            toast.error(ErrorUnexpected);
          } else if (error.response.data.fields) {
            error.response.data.fields.forEach((field) => {
              actions.setFieldError(field.name, field.message);
            });
          } else if (error.response.data.title) {
            actions.setFieldError("name", error.response.data.title);
          } else {
            toast.error(ErrorUnexpected);
          }
        });
    } else {
      handleNext();
      actions.setSubmitting(false);
    }
  }

  return (
    <Card>
      <CardHeader title="IR.21 Template" />
      <Divider />
      <CardContent>
        <Box className={classes.stepMenus}>
          <Stepper alternativeLabel activeStep={activeStep}>
            <Step key={0}>
              <StepLabel>GENERAL</StepLabel>
            </Step>
            <Step key={1}>
              <StepLabel>MAP VERSION</StepLabel>
            </Step>
            <Step key={2}>
              <StepLabel>APN OPERATOR IDENTIFIERS</StepLabel>
            </Step>
            <Step key={3}>
              <StepLabel>CAMEL</StepLabel>
            </Step>
            <Step key={4}>
              <StepLabel>GLOBAL TITLE</StepLabel>
            </Step>
            <Step key={5}>
              <StepLabel>EPC REALMS FOR ROAMING</StepLabel>
            </Step>
            <Step key={6}>
              <StepLabel>VIEW</StepLabel>
            </Step>
          </Stepper>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={schema[activeStep]}
          onSubmit={handleSave}
          innerRef={formikRef}
        >
          {({ isSubmitting }) => (
            <Form autoComplete="off">
              <Box className={classes.container}>
                {activeStep === 0 && <Ir21TemplateGeneral />}
                {activeStep === 1 && <Ir21TemplateMapVersion />}
                {activeStep === 2 && <Ir21TemplateApn />}
                {activeStep === 3 && <Ir21TemplateCamel />}
                {activeStep === 4 && <Ir21TemplateGlobalTitle />}
                {activeStep === 5 && <Ir21TemplateRealms />}
                {activeStep === 6 && <Ir21TemplateView />}
              </Box>
              <Box className={classes.bottomButtons}>
                <Button variant="contained" color="primary" onClick={handleCancel}>
                  Cancel
                </Button>
                {activeStep > 0 && (
                  <Button variant="contained" disabled={isSubmitting} color="primary" onClick={handleBack}>
                    Back
                  </Button>
                )}
                <Button
                  startIcon={isSubmitting && <CircularProgress size="1rem" />}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Saving" : isLastStep() ? "Save" : "Next"}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
}

export default Ir21Template;
