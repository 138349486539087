import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { ErrorUnexpected, OperationSuccessfully } from "../../../../../const/Messages";
import * as PlmnService from "../../../../../service/PlmnService";
import * as RealmSpecificService from "../../../../../service/RealmSpecificService";
import Loading from "../../../../Common/Loading/Loading";

/**
  @author Guilherme Almeida
  @date 20/01/2022
 */
export default function RealmsRegister({ location }) {
  const initialValues = {
    plmnId: "",
    active: false,
    realms: [],
  };

  const [tadigs, setTadigs] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const formikRef = useRef(null);

  useEffect(() => {
    PlmnService.findComboBox()
      .then((comboList) => {
        const comboOptions = comboList.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });

        setTadigs(comboOptions);
      })
      .catch(() => toast.error(ErrorUnexpected));

    if (location && location.state && location.state.id) {
      RealmSpecificService.getRealmsByTadig(location.state.id)
        .then((values) => {
          formikRef.current.setValues(values);
        })
        .catch(() => {
          history.push("/ir21/settings/realm", { message: ErrorUnexpected, messageType: "error" });
        });
    }

    setLoading(false);

    // eslint-disable-next-line
  }, []);

  const schema = Yup.object().shape({
    plmnId: Yup.string().required("Tadig is a required field"),
    realms: Yup.array().test("invalid", "Must have at least 1 realm.", function (value) {
      return !this.parent.active || (this.parent.active && value.length >= 1);
    }),
  });

  const schemaRealm = Yup.object().shape({
    mcc: Yup.string()
      .required()
      .max(7)
      .test(
        "invalid",
        "MCC must be a valid value, between 000 and 999",
        (value) => Number.isInteger(Number(value)) && Number(value) >= 0 && Number(value) <= 999 && value?.length === 3
      )
      .label("MCC"),
    mnc: Yup.string()
      .required()
      .max(7)
      .test(
        "invalid",
        "MNC must be a valid value, between 000 and 999",
        (value) => Number.isInteger(Number(value)) && Number(value) >= 0 && Number(value) <= 999 && value?.length === 3
      )
      .label("MNC"),
  });

  function updateRealms(plmnId) {
    return new Promise((resolve, reject) => {
      RealmSpecificService.getRealmsByTadig(plmnId)
        .then((values) => {
          formikRef.current.setValues(values);
          resolve();
        })
        .catch((error) => {
          toast.error(ErrorUnexpected);
          reject(error);
        });
    });
  }

  function handleRemove(index) {
    const { values, setFieldValue } = formikRef.current;

    //Obter a listagem
    let array = values.realms;

    //Remover da listagem pelo index
    array.splice(index, 1);

    //Salvar na listagem.
    setFieldValue("realms", array);
  }

  function handleSaveRealm(item, actions) {
    const { values, setFieldValue } = formikRef.current;
    const array = values.realms;
    let realm = `epc.mnc${item.mnc}.mcc${item.mcc}.3gppnetwork.org${+item.k2r ? ".key2roam.comfone.com" : ""}`;

    if (!values?.plmnId) {
      actions.setFieldError("mcc", "It is required to select Tadig first.");
    } else if (array && array.includes(realm)) {
      actions.setFieldError("mcc", "Realm found in listing.");
    } else {
      array.push(realm);
      setFieldValue("realms", array);
      actions.resetForm();
    }
  }

  function handleSave(values, actions) {
    RealmSpecificService.save(values)
      .then(() => history.push("/settings/realm", { message: OperationSuccessfully, messageType: "success" }))
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          toast.error(ErrorUnexpected);
        } else if (error.response.data.fields) {
          error.response.data.fields.forEach((field) => {
            actions.setFieldError(field.name, field.message);
          });
        } else if (error.response.data.title) {
          actions.setFieldError("plmn", error.response.data.title);
        } else {
          toast.error(ErrorUnexpected);
        }
      });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>Realm - Register</h5>
          </div>
          <div className="ibox-content">
            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSave} innerRef={formikRef}>
              {({ values, setFieldValue, errors }) => (
                <Form>
                  <div className="form-group row">
                    <label htmlFor="tadig" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      TADIG:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4 ">
                      <div className="input-group">
                        <Select
                          className="flex-1"
                          styles={{
                            placeholder: (defaultStyle) => {
                              return { ...defaultStyle, color: "#495057" };
                            },
                          }}
                          placeholder="-- Select Option --"
                          options={tadigs}
                          name="plmn"
                          value={tadigs.find((tadig) => tadig.value === values.plmnId)}
                          onChange={(item) => updateRealms(item.value)}
                        />
                      </div>
                      <span className="text-danger">{<ErrorMessage name="plmn" />}</span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="active" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      Audit:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-8 col-xl-6">
                      <Field type="checkbox" name="active" />
                      <label className="form-check-label pl-1">
                        Use this registration for checking logs, only. IR.21 will not be considered.
                      </label>
                      <span className="text-danger">
                        <ErrorMessage name="active" />
                      </span>
                    </div>
                  </div>

                  <Formik
                    initialValues={{ mcc: "", mnc: "" }}
                    validationSchema={schemaRealm}
                    onSubmit={handleSaveRealm}
                  >
                    {({ values, handleSubmit, setFieldValue, errors }) => (
                      <Form>
                        <div className="form-group row">
                          <label
                            htmlFor="mcc"
                            className="col-form-label col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-1"
                          ></label>
                          <div className="col-sm-4 col-md-3 col-lg-3 col-xl-2" style={{ marginBottom: 10 }}>
                            <Field className="form-control" name="mcc" type="text" placeholder="MCC" />
                            <span className="text-danger">
                              <ErrorMessage name="mcc" />
                            </span>
                          </div>
                          <div className="col-sm-4 col-md-3 col-lg-3 col-xl-2" style={{ marginBottom: 10 }}>
                            <Field className="form-control" name="mnc" type="text" placeholder="MNC" />
                            <span className="text-danger">
                              <ErrorMessage name="mnc" />
                            </span>
                          </div>
                          <div className="d-flex col-sm-4 col-md-3 col-lg-3 col-xl-2">
                            <Button
                              style={{ marginBottom: 10, height: 38 }}
                              variant="primary"
                              type="button"
                              onClick={() => {
                                setFieldValue("k2r", false);
                                handleSubmit();
                              }}
                            >
                              ADD
                            </Button>
                            <Button
                              style={{ marginLeft: 10, height: 38 }}
                              variant="primary"
                              type="button"
                              onClick={() => {
                                setFieldValue("k2r", true);
                                handleSubmit();
                              }}
                            >
                              ADD K2R
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>

                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Realm</th>
                        <th className="text-center"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {values?.realms.map((item, index) => (
                        <tr key={item + index}>
                          <td>{item}</td>
                          <td className="text-center" onClick={() => handleRemove(index)}>
                            <FiTrash2 size={16} color="#007bff" style={{ cursor: "pointer" }} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <span className="text-danger">{<ErrorMessage name="realms" />}</span>
                  <div className="d-flex justify-content-end">
                    <Link className="mr-1 btn btn-primary" to="/settings/realm">
                      Cancel
                    </Link>
                    <Button variant="primary" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
}
