import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as Yup from "yup";
import {
  ErrorUnexpected,
  OperationSuccessfully,
} from "../../../../../const/Messages";
import * as HubService from "../../../../../service/HubService";
import * as OperatorPartnersService from "../../../../../service/OperatorPartnerService";
import * as PlmnService from "../../../../../service/PlmnService";
import Loading from "../../../../Common/Loading/Loading";

const initialValues = {
  plmn: {
    id: "",
  },
  gsm: "NOT LIVE",
  camel: "NOT LIVE",
  gprs: "NOT LIVE",
  lte: "NOT LIVE",
  nsa5g: "NOT LIVE",
  volte: "NOT LIVE",
  roamingOpening: "",
  latestPartnerName: "",
  updateAt: "",
  hub: {
    id: "",
  },
};

/**
  @author Guilherme Almeida
  @date 10/02/2021
 */
export default function RoamingPartnersManagementRegister({ location }) {
  const history = useHistory();
  const [tadigs, setTadigs] = useState([]);
  const [agreementList, setAgreementList] = useState([]);
  const [hubList, setHubList] = useState([]);
  const [organisationName, setOrganisationName] = useState("Organisation");
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    PlmnService.findComboBox()
      .then((comboList) => {
        const comboOptions = comboList.map((item) => {
          return {
            value: item.id,
            label: item.name,
            organisationName: item.organisation.name,
          };
        });

        setTadigs(comboOptions);
      })
      .catch(() => toast.error(ErrorUnexpected));

    HubService.findAll()
      .then((list) => setHubList(list))
      .catch(() => toast.error(ErrorUnexpected));

    OperatorPartnersService.getAgreements()
      .then((agreements) => setAgreementList(agreements))
      .catch(() => toast.error(ErrorUnexpected));

    if (location && location.state && location.state.id) {
      OperatorPartnersService.getById(location.state.id)
        .then((operatorPartner) => {
          setValues(operatorPartner);
          setOrganisationName(operatorPartner.plmn.organisation.name);
          setLoading(false);
        })
        .catch(() => {
          history.push("/settings/partners/roaming-partners", {
            message: ErrorUnexpected,
            messageType: "error",
          });
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const schema = Yup.object().shape({
    plmn: Yup.object()
      .shape({
        id: Yup.number().required("Tadig is a required field"),
      })
      .test(
        "oneOfRequired",
        "One of GPRS, GSM, LTE or CAMEL must be filled",
        function (item) {
          return (
            this.parent.gprs ||
            this.parent.gsm ||
            this.parent.lte ||
            this.parent.camel ||
            this.parent.nsa5g ||
            this.parent.volte
          );
        }
      ),
  });

  function handleSave(values, actions) {
    let id = null;

    if (location && location.state && location.state.id) {
      id = location.state.id;
    }

    OperatorPartnersService.merge(id, values)
      .then((response) => {
        history.push("/settings/partners/roaming-partners", {
          message: OperationSuccessfully,
          messageType: "success",
        });
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          toast.error(ErrorUnexpected);
        } else if (error.response.data.fields) {
          error.response.data.fields.forEach((field) => {
            actions.setFieldError(field.name, field.message);
          });
        } else if (error.response.data.title) {
          actions.setFieldError("plmn", error.response.data.title);
        } else {
          toast.error(ErrorUnexpected);
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  }

  function handleDelete(id) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        OperatorPartnersService.deleteById(id).then(() => {
          history.push("/settings/partners/roaming-partners", {
            message: OperationSuccessfully,
            messageType: "success",
          });
        });
      }
    });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>Roaming Partners - Register</h5>
          </div>
          <div className="ibox-content">
            <Formik
              initialValues={values}
              validationSchema={schema}
              onSubmit={handleSave}
            >
              {({ values, setFieldValue, errors }) => (
                <Form>
                  <div className="form-group row">
                    <label
                      htmlFor="tadig"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      TADIG:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4 ">
                      <div className="input-group">
                        <Select
                          className="flex-1"
                          styles={{
                            placeholder: (defaultStyle) => {
                              return { ...defaultStyle, color: "#495057" };
                            },
                          }}
                          placeholder="-- Select Option --"
                          options={tadigs}
                          name="plmn"
                          value={tadigs.find(
                            (tadig) => tadig.value === values.plmn.id
                          )}
                          onChange={(item) => {
                            setFieldValue("plmn.id", item.value);
                            setOrganisationName(item.organisationName);
                          }}
                        />
                        <div className="input-group-append">
                          <Link
                            to="/ir21/settings/tadigs/reg"
                            className="input-group-text"
                          >
                            NEW
                          </Link>
                        </div>
                      </div>
                      <span className="text-danger">
                        {errors?.plmn?.id ? (
                          <ErrorMessage name="plmn.id" />
                        ) : (
                          <ErrorMessage name="plmn" />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="organisation"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Organisation:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <div className="form-control text-muted bg-light">
                        {organisationName}
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="gsm"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      GSM:
                    </label>
                    <div className="col-sm-12 col-md-4 col-lg-3 col-xl-4">
                      <Field as="select" name="gsm" className="form-control">
                        {agreementList.map((item) => (
                          <option value={item.agreement} key={item.agreement}>
                            {item.agreement}
                          </option>
                        ))}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="gsm" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="camel"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      CAMEL:
                    </label>
                    <div className="col-sm-12 col-md-4 col-lg-3 col-xl-4">
                      <Field as="select" name="camel" className="form-control">
                        {agreementList.map((item) => (
                          <option value={item.agreement} key={item.agreement}>
                            {item.agreement}
                          </option>
                        ))}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="camel" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="gprs"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      GPRS:
                    </label>
                    <div className="col-sm-12 col-md-4 col-lg-3 col-xl-4">
                      <Field as="select" name="gprs" className="form-control">
                        {agreementList.map((item) => (
                          <option value={item.agreement} key={item.agreement}>
                            {item.agreement}
                          </option>
                        ))}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="gprs" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="lte"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      LTE:
                    </label>
                    <div className="col-sm-12 col-md-4 col-lg-3 col-xl-4">
                      <Field as="select" name="lte" className="form-control">
                        {agreementList.map((item) => (
                          <option value={item.agreement} key={item.agreement}>
                            {item.agreement}
                          </option>
                        ))}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="lte" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="nsa5g"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      5G NSA:
                    </label>
                    <div className="col-sm-12 col-md-4 col-lg-3 col-xl-4">
                      <Field as="select" name="nsa5g" className="form-control">
                        {agreementList.map((item) => (
                          <option value={item.agreement} key={item.agreement}>
                            {item.agreement}
                          </option>
                        ))}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="nsa5g" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="volte"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      VoLTE:
                    </label>
                    <div className="col-sm-12 col-md-4 col-lg-3 col-xl-4">
                      <Field as="select" name="volte" className="form-control">
                        {agreementList.map((item) => (
                          <option value={item.agreement} key={item.agreement}>
                            {item.agreement}
                          </option>
                        ))}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="volte" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="hub"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      HUB:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field as="select" name="hub.id" className="form-control">
                        <option value="">-- Select Option --</option>
                        {hubList.map((hub) => (
                          <option value={hub.id} key={hub.id}>
                            {hub.name}
                          </option>
                        ))}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="hub" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="roamingOpening"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Roaming Opening:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field
                        name="roamingOpening"
                        className="form-control"
                      ></Field>
                      <span className="text-danger">
                        <ErrorMessage name="roamingOpening" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="latestPartnerName"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Latest Partner Name:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field
                        name="latestPartnerName"
                        className="form-control"
                      ></Field>
                      <span className="text-danger">
                        <ErrorMessage name="latestPartnerName" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="updatedAt"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Updated At:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field name="updatedAt" className="form-control"></Field>
                      <span className="text-danger">
                        <ErrorMessage name="updatedAt" />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Link
                      className="mr-1 btn btn-primary"
                      to="/settings/partners/roaming-partners"
                    >
                      Cancel
                    </Link>
                    {location && location.state && location.state.id && (
                      <Button
                        className="mr-1"
                        onClick={() => handleDelete(location.state.id)}
                        variant="primary"
                        type="button"
                      >
                        Delete
                      </Button>
                    )}
                    <Button variant="primary" type="submit">
                      {location && location.state && location.state.id
                        ? "Update"
                        : "Save"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
}
