import React from "react";
import { useParams } from "react-router-dom";
import ReportErrorSummary from "../../../../components/Report/Error/ReportErrorSummary";
import PageNotFound from "../../../Common/Error/PageNotFound";

/**
 * @author Thiago Guimarães
 * @date 16/04/2021
 */
function Error() {
  const { elementType, command } = useParams();

  if (elementType === "msc" && command === "mgisp") {
    return (
      <ReportErrorSummary
        key="1"
        vendorId="1"
        elementTypeId="9"
        commandId="1"
        reportTitle="Report MSC"
      />
    );
  } else if (elementType === "hlr" && command === "hercp") {
    return (
      <ReportErrorSummary
        key="2"
        vendorId="1"
        elementTypeId="5"
        commandId="2"
        reportTitle="Report HLR"
      />
    );
  } else if (elementType === "mme" && command === "connect-plmn") {
    return (
      <ReportErrorSummary
        key="3"
        vendorId="3"
        elementTypeId="10"
        commandId="3"
        reportTitle="Report SGSN/MME (CONNECT PLMN)"
      />
    );
  } else if (elementType === "mme" && command === "imsigt") {
    return (
      <ReportErrorSummary
        key="4"
        vendorId="3"
        elementTypeId="10"
        commandId="4"
        reportTitle="Report SGSN/MME (IMSIGT)"
      />
    );
  } else if (elementType === "mme" && command === "imsihss") {
    return (
      <ReportErrorSummary
        key="5"
        vendorId="3"
        elementTypeId="10"
        commandId="5"
        reportTitle="Report SGSN/MME (IMSIHSS)"
      />
    );
  } else if (elementType === "hss" && command === "esm-plmn-container") {
    return (
      <ReportErrorSummary
        key="6"
        vendorId="1"
        elementTypeId="6"
        commandId="6"
        reportTitle="Report HSS (ESM PLMN CONTAINER)"
      />
    );
  } else if (elementType === "hss" && command === "dia-cfg-routing-container") {
    return (
      <ReportErrorSummary
        key="7"
        vendorId="1"
        elementTypeId="6"
        commandId="7"
        reportTitle="Report HSS (DIA CFG ROUTING CONTAINER)"
      />
    );
  } else if (elementType === "edns" && command === "2g-3g") {
    return (
      <ReportErrorSummary
        key="8"
        vendorId="2"
        elementTypeId="2"
        commandId="8"
        reportTitle="Report EDNS (2G 3G)"
      />
    );
  } else if (elementType === "edns" && command === "4g") {
    return (
      <ReportErrorSummary
        key="9"
        vendorId="2"
        elementTypeId="2"
        commandId="9"
        reportTitle="Report EDNS (4G)"
      />
    );
  } else if (elementType === "pts" && command === "sccpgt-k2r-nngt") {
    return (
      <ReportErrorSummary
        key="10"
        vendorId="3"
        elementTypeId="13"
        commandId="15"
        reportTitle="Report PTS (K2R NNGT - LST SCCPGT)"
      />
    );
  } else if (elementType === "dra" && command === "dmrt") {
    return (
      <ReportErrorSummary
        key="11"
        vendorId="3"
        elementTypeId="1"
        commandId="10"
        reportTitle="Report DRA (DMRT)"
      />
    );
  } else if (elementType === "dra" && command === "rtimsi") {
    return (
      <ReportErrorSummary
        key="12"
        vendorId="3"
        elementTypeId="1"
        commandId="14"
        reportTitle="Report DRA (RTIMSI)"
      />
    );
  } else if (elementType === "dra" && command === "rtresult") {
    return (
      <ReportErrorSummary
        key="13"
        vendorId="3"
        elementTypeId="1"
        commandId="16"
        reportTitle="Report DRA (RTRESULT)"
      />
    );
  } else if (elementType === "dra" && command === "rtdhost") {
    return (
      <ReportErrorSummary
        key="14"
        vendorId="3"
        elementTypeId="1"
        commandId="11"
        reportTitle="Report DRA (RTDHOST)"
      />
    );
  } else if (elementType === "dra" && command === "rtorealm") {
    return (
      <ReportErrorSummary
        key="15"
        vendorId="3"
        elementTypeId="1"
        commandId="12"
        reportTitle="Report DRA (RTOREALM)"
      />
    );
  } else if (elementType === "dra" && command === "rtohost") {
    return (
      <ReportErrorSummary
        key="16"
        vendorId="3"
        elementTypeId="1"
        commandId="13"
        reportTitle="Report DRA (RTOHOST)"
      />
    );
  } else if (elementType === "pts" && command === "sccpgt-vrs-nngt") {
    return (
      <ReportErrorSummary
        key="17"
        vendorId="3"
        elementTypeId="13"
        commandId="17"
        reportTitle="Report PTS (VRS NNGT - LST SCCPGT)"
      />
    );
  } else if (elementType === "pts" && command === "sccpgt-vrs-mgt") {
    return (
      <ReportErrorSummary
        key="18"
        vendorId="3"
        elementTypeId="13"
        commandId="18"
        reportTitle="Report PTS (VRS MGT - LST SCCPGT)"
      />
    );
  } else if (elementType === "pts" && command === "sccpgt-normal-nngt") {
    return (
      <ReportErrorSummary
        key="19"
        vendorId="3"
        elementTypeId="13"
        commandId="19"
        reportTitle="Report PTS (NORMAL NNGT - LST SCCPGT)"
      />
    );
  } else if (elementType === "pts" && command === "sccpgt-normal-mgt") {
    return (
      <ReportErrorSummary
        key="20"
        vendorId="3"
        elementTypeId="13"
        commandId="20"
        reportTitle="Report PTS (NORMAL MGT - LST SCCPGT)"
      />
    );
  } else if (elementType === "pts" && command === "sccpgt-unknown") {
    return (
      <ReportErrorSummary
        key="21"
        vendorId="3"
        elementTypeId="13"
        commandId="21"
        reportTitle="Report PTS (UNKNOWN - LST SCCPGT)"
      />
    );
  } else if (elementType === "glr" && command === "glr") {
    return (
      <ReportErrorSummary
        key="22"
        vendorId="6"
        elementTypeId="4"
        commandId="22"
        reportTitle="Report GLR"
      />
    );
  } else if (elementType === "glr" && command === "glr-wl") {
    return (
      <ReportErrorSummary
        key="23"
        vendorId="6"
        elementTypeId="4"
        commandId="23"
        reportTitle="Report GLR WL"
      />
    );
  } else if (elementType === "sor" && command === "vlr") {
    return (
      <ReportErrorSummary
        key="24"
        vendorId="6"
        elementTypeId="12"
        commandId="24"
        reportTitle="Report SOR VLR"
      />
    );
  } else if (elementType === "sor" && command === "sgsn") {
    return (
      <ReportErrorSummary
        key="25"
        vendorId="6"
        elementTypeId="12"
        commandId="25"
        reportTitle="Report SOR SGSN"
      />
    );
  } else if (elementType === "sor" && command === "mme") {
    return (
      <ReportErrorSummary
        key="26"
        vendorId="6"
        elementTypeId="12"
        commandId="26"
        reportTitle="Report SOR MME"
      />
    );
  } else if (elementType === "hss" && command === "configuration-container") {
    return (
      <ReportErrorSummary
        key="27"
        vendorId="1"
        elementTypeId="6"
        commandId="27"
        reportTitle="Report HSS (Configuration Container)"
      />
    );
  } else if (elementType === "ugw" && command === "virtual-apn") {
    return (
      <ReportErrorSummary
        key="28"
        vendorId="3"
        elementTypeId="14"
        commandId="28"
        reportTitle="Report UGW (Virtual APN)"
      />
    );
  } else if (elementType === "dra" && command === "rtexit") {
    return (
      <ReportErrorSummary
        key="29"
        vendorId="3"
        elementTypeId="1"
        commandId="29"
        reportTitle="Report DRA (RTEXIT)"
      />
    );
  } else {
    return <PageNotFound />;
  }
}

export default Error;
