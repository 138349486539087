import api from "./api";

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get(`/elementtype`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function findByVendorId(vendorId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/elementtype/vendor/${vendorId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getBatchByVendor(vendorId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/elementtype/batch/${vendorId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/elementtype/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
