import React, { useState, useEffect } from "react";
import Loading from "../../../../../../Common/Loading/Loading";
import { Col, Row, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as EricssonHlrDefaultParametersService from "../../../../../../../service/EricssonHlrDefaultParametersService";
import { ErrorUnexpected, OperationSuccessfully } from "../../../../../../../const/Messages";

const initialValues = {
  id: "",
  natureOfAddress: "",
  chars: "",
  onlyCheckWithIR21: false,
  e164AllPrefixes: false,
};

/**
  @author Guilherme Almeida
  @date 12/05/2021
 */
export default function EricssonHlrDefaultParameters() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
      EricssonHlrDefaultParametersService.getEricssonHlrDefaultParameters()
        .then((setup) => {
          const data = { 
            ...setup, 
            e164AllPrefixes: setup.e164AllPrefixes ? "true" : "false"  
          };
          setValues(data);
          setLoading(false);
        })
        .catch(() => {
          history.push("/", { message: ErrorUnexpected, messageType: "error" });
        });
  }, []); // eslint-disable-line

  const schema = Yup.object().shape({
    natureOfAddress: Yup.number().required("Nature Of Address is a required field"),
  });

  function handleSave(values, actions) {

    console.log(values);

    EricssonHlrDefaultParametersService.update(values)
      .then(() => {
        history.push("/settings/report/template/hlr/ericsson/default", { message: OperationSuccessfully, messageType: "success" });
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          actions.setFieldError("natureOfAddress", ErrorUnexpected);
        } else if (error.response.data.title) {
          actions.setFieldError("natureOfAddress", error.response.data.title);
        }
        actions.setSubmitting(false);
      });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>Ericsson HLR Default Parameters</h5>
          </div>
          <div className="ibox-content">
            <Formik initialValues={values} validationSchema={schema} onSubmit={handleSave}>
              {() => (
                <Form>
                  <div className="form-group row">
                    <label
                      htmlFor="natureOfAddress"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Nature Of Address:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field
                        className="form-control"
                        name="natureOfAddress"
                        type="number"
                        placeholder="Nature Of Address"
                      />
                      <span className="text-danger">
                        <ErrorMessage name="natureOfAddress" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="chars"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Chars:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field
                        className="form-control"
                        name="chars"
                        placeholder="Ex: OCSIPH2,TRAFFV3"
                      />
                      <span className="text-danger">
                        <ErrorMessage name="chars" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="onlyCheckWithIR21"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      IR.21:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field type="checkbox" name="onlyCheckWithIR21" />
                      <label className="form-check-label pl-2">
                        Check HLR fields only if IR21 file exists.
                      </label>
                      <span className="text-danger">
                        <ErrorMessage name="onlyCheckWithIR21" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="e164AllPrefixes"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Global Title:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <div className="form-check form-check-inline">
                        <Field type="radio" name="e164AllPrefixes" value="false" id="boxTrue" className="form-check-input"/>
                        <label className="form-check-label" htmlFor="boxTrue">
                          Single prefix
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <Field type="radio" name="e164AllPrefixes" value="true" id="boxFalse" className="form-check-input"/>
                        <label className="form-check-label" htmlFor="boxFalse">
                          All prefixes
                        </label>
                      </div>
                      <span className="text-danger">
                        <ErrorMessage name="e164AllPrefixes" />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Link className="mr-1 btn btn-primary" to="/settings">
                      Cancel
                    </Link>
                    <Button variant="primary" type="submit">
                      Update
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
}
