import React, { useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ErrorUnexpected } from "../../../const/Messages.js";
import { FiDownload } from "react-icons/fi";
import * as NeLogsFileService from "../../../service/NeLogsFileService";

export default function LogSamplesModal({ show, setShow }) {
  const [list, setList] = useState([]);
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    NeLogsFileService.findAllSample()
      .then((samples) =>  setList(samples))
      .catch(() => toast.error(ErrorUnexpected))
      .finally();
  }, []);

  function handleDownload(id) {

    if(!waiting){
      setWaiting(true);
  
      NeLogsFileService.downloadSampleLogById(id)
        .catch((error) => {

          if(error && error.response && error.response.status === 404){
            toast.error("File not available.");
          }else{
            toast.error(ErrorUnexpected);
          }
        })
        .finally(() => setWaiting(false));
    }
  }

  return (
    <Modal show={show} onHide={() => setShow(false)} size="lg" animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Samples - List</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="text-center">Vendor</th>
              <th className="text-center">Element Type</th>
              <th className="text-center">Description</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {list.map((row) => (
              <tr key={row.id}>
                <td className="text-center">{row.vendor?.name}</td>
                <td className="text-center">{row.elementType?.name}</td>
                <td className="text-center">{row.description}</td>
                <td className="text-center">
                  <Tooltip title="DOWNLOAD LOG" arrow>
                    <span>
                      <FiDownload size={23} color="#007BFF" onClick={() => handleDownload(row.id)} style={{ cursor: waiting ? "wait" : "pointer", marginRight: 5 }} />
                    </span>
                  </Tooltip>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
}
