import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import * as Yup from "yup";

export const mapVersionSchema = Yup.object().shape({
  mapVersion: Yup.object().shape({
    inboundRoamingMscVlr: Yup.string()
      .test(
        "invalid",
        "INBOUND ROAMING must be a valid value, between 1 and 5",
        (value) => !value || (Number.isInteger(Number(value)) && Number(value) >= 0 && Number(value) <= 5)
      )
      .label("INBOUND ROAMING"),
    outboundRoaming: Yup.string()
      .test(
        "invalid",
        "OUTBOUND ROAMING must be a valid value, between 1 and 5",
        (value) => !value || (Number.isInteger(Number(value)) && Number(value) >= 0 && Number(value) <= 5)
      )
      .label("OUTBOUND ROAMING"),
  }),
});

export const mapVersionInitialValues = {
  mapVersion: {
    inboundRoamingMscVlr: "",
    outboundRoaming: "",
  },
};

const useStyles = makeStyles({
  input: {
    "& + div": {
      marginTop: 15,
    },
  },
});

function Ir21TemplateMapVersion() {
  const { errors, values } = useFormikContext();
  const classes = useStyles();

  return (
    <>
      <TextField
        label="TADIG"
        defaultValue=" "
        value={values.plmnName}
        disabled
        className={classes.input}
        variant="outlined"
      />
      <Field
        as={TextField}
        className={classes.input}
        label="INBOUND ROAMING"
        name="mapVersion.inboundRoamingMscVlr"
        variant="outlined"
        error={!!errors.mapVersion?.inboundRoamingMscVlr}
        helperText={errors.mapVersion?.inboundRoamingMscVlr}
      />
      <Field
        as={TextField}
        className={classes.input}
        label="OUTBOUND ROAMING"
        name="mapVersion.outboundRoaming"
        variant="outlined"
        error={!!errors.mapVersion?.outboundRoaming}
        helperText={errors.mapVersion?.outboundRoaming}
      />
    </>
  );
}

export default Ir21TemplateMapVersion;
