import { Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import Moment from "react-moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  ErrorUnexpected,
  OperationSuccessfully,
} from "../../../../const/Messages";
import * as Ir21Service from "../../../../service/Ir21Service";
import UploadIr21Modal from "./UploadIr21Modal";

function Upload() {
  const [isLoading, setIsLoading] = useState(true);
  const [ir21, setIr21] = useState([]);
  const [modal, setModal] = useState(false);
  let dateFilter = { start: "", end: "" };

  useEffect(() => {
    Ir21Service.findAll()
      .then((ir21List) => {
        setIr21(ir21List);
      })
      .catch(() => {
        toast.error(ErrorUnexpected);
      })
      .finally(() => setIsLoading(false));
  }, [modal]);

  function handleDelete(id) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Ir21Service.deleteById(id)
          .then(() => {
            Ir21Service.findAll().then((ir21) => {
              setIr21(ir21);
              toast.success(OperationSuccessfully);
            });
          })
          .catch(() => {
            toast.error(ErrorUnexpected);
          });
      }
    });
  }

  function handleOpenModal() {
    setModal(true);
  }

  const columns = [
    {
      title: "Organisation",
      field: "organisation.name",
    },
    {
      field: "raexFileCreationDate",
      title: "RELEASE DATE",
      render: (row) => (
        <Moment format="DD/MM/yyyy HH:mm:ss">{row.raexFileCreationDate}</Moment>
      ),
      filterComponent: (props) => (
        <>
          <TextField
            {...props}
            label="START"
            type="date"
            value={dateFilter.start}
            className="mr-2"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              dateFilter = { ...dateFilter, start: event.target.value };
              props.onFilterChanged(props.columnDef.tableData.id, "FILTER");
            }}
          />
          <TextField
            {...props}
            label="END"
            type="date"
            value={dateFilter.end}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              dateFilter = { ...dateFilter, end: event.target.value };
              props.onFilterChanged(props.columnDef.tableData.id, "FILTER");
            }}
          />
        </>
      ),
      customFilterAndSearch: (term, row) => {
        const date = moment(row.raexFileCreationDate);

        if (term === "FILTER") {
          if (dateFilter && (dateFilter.start || dateFilter.end)) {
            if (dateFilter.start && dateFilter.end) {
              return (
                date.isBetween(dateFilter.start, dateFilter.end, "day") ||
                date.isSame(dateFilter.start, "day") ||
                date.isSame(dateFilter.end, "day")
              );
            } else if (dateFilter.start) {
              return date.isSameOrAfter(dateFilter.start, "day");
            } else if (dateFilter.end) {
              return date.isSameOrBefore(dateFilter.end, "day");
            }
          } else {
            return true;
          }
        } else {
          return date.format("DD/MM/yyyy HH:mm:ss").indexOf(term) !== -1;
        }
      },
      customSort: (obj1, obj2) =>
        new Date(obj1.raexFileCreationDate) -
        new Date(obj2.raexFileCreationDate),
    },
    {
      title: "Raex Version",
      field: "tadigRaexVersion",
    },
    {
      title: "File Type",
      render: (row) => (row.ir85 ? "IR.85" : "IR.21"),
      customFilterAndSearch: (term, row) =>
        (row.ir85 ? "IR.85" : "IR.21").indexOf(term.toUpperCase()) !== -1,
    },
  ];

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between">
            <h5>Ir21 - Upload</h5>
            <div>
              <Button varient="primary" onClick={() => handleOpenModal()}>
                Upload
              </Button>
            </div>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={ir21}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "ir21",
                filtering: true,
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
              actions={[
                {
                  icon: () => <FiTrash2 size={16} color="#007bff" />,
                  tooltip: "DELETE",
                  onClick: (event, rowData) => handleDelete(rowData.id),
                },
              ]}
            />
            <UploadIr21Modal modal={modal} setModal={setModal} />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Upload;
