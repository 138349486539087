import api from "./api";

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/globaltitles")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getGlobalTitlePrefix(params) {
  return new Promise((resolve, reject) => {
    api
      .get("/globaltitles/summary/prefix", { params })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getE164ByGlobalTitlePrefix(params) {
  return new Promise((resolve, reject) => {
    api
      .get("/globaltitles/prefix", { params })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/globaltitles/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function merge(id, globaltitle) {

  return new Promise((resolve, reject) => {
    api({
      method: id ? "put" : "post",
      url: id ? `/globaltitles/${id}` : "/globaltitles",
      data: globaltitle,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteById(id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/globaltitles/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
