import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ErrorUnexpected } from "../../../../../const/Messages";
import api from "../../../../../service/api";

/**
 * @author Thiago Guimarães
 * @date 25/07/2022
 *
 */
export default function TadigAssociation() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [columns] = useState([
    {
      title: "TADIG PARENT",
      field: "parent",
    },
    {
      title: "TADIG CHILDS",
      field: "childs",
    },
  ]);

  useEffect(() => {
    api
      .get("/plmns/association")
      .then((response) => setData(response.data))
      .catch(() => toast.error(ErrorUnexpected))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between">
            <h5>Tadig Association - List</h5>
            <div>
              <Link
                className="btn btn-primary"
                to={{
                  pathname: "/ir21/settings/tadigs/association/reg",
                  state: { id: "" },
                }}
              >
                New Association
              </Link>
            </div>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={data}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "tadig association",
                filtering: true,
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
              actions={[
                {
                  icon: () => <FiEdit size={16} color="#007bff" />,
                  tooltip: "EDIT",
                  onClick: (event, rowData) =>
                    history.push("/ir21/settings/tadigs/association/reg", {
                      id: rowData.parentId,
                    }),
                },
              ]}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
