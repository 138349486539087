import React, { useState } from "react";
import { Box, IconButton, makeStyles, TextField, Tooltip } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { FiPlus, FiMinus } from "react-icons/fi";
import * as Yup from "yup";

export const realmsSchema = Yup.object().shape({
  epcRealmsForRoamingList: Yup.array()
    .of(
      Yup.string()
        .matches(/^.*mnc[0-9]{3}\.mcc[0-9]{3}.*$/, "The value filled in is not a valid REALM. (*mncXXX.mccXXX*)")
        .label("REALM")
    ),
});

export const realmsInitialValues = {
  epcRealmsForRoamingList: [],
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",

    "& div": {
      flex: 1,
    },

    "& + div": {
      marginTop: 15,
    },

    "& div:only-child": {
      marginRight: 48,
    },
  },
  input: {
    "& + div": {
      marginTop: 15,
    },
  },
});

function Ir21TemplateRealms() {
  const { errors, values, setFieldValue } = useFormikContext();
  const classes = useStyles();
  const [extraInputs, setExtraInputs] = useState(
    values?.epcRealmsForRoamingList?.length > 0 ? values.epcRealmsForRoamingList.length - 1 : 0
  );

  function handleAddInput() {

    const array = values.epcRealmsForRoamingList;

    realmsSchema.isValid(array);

    array.push("");

    setFieldValue("epcRealmsForRoamingList", array);
    setExtraInputs((current) => current + 1);
  }

  function handleRemoveInput() {
    const array = values.epcRealmsForRoamingList;
    array.pop();

    setFieldValue("epcRealmsForRoamingList", array);
    setExtraInputs((current) => current - 1);
  }

  function hasError(arrayIndex) {
    if (errors) {
      if (errors.epcRealmsForRoamingList) {
        if (errors.epcRealmsForRoamingList[arrayIndex]) {
          return true;
        }
      }
    }
    return false;
  }

  function getErrorMessage(arrayIndex) {
    if (errors) {
      if (errors.epcRealmsForRoamingList) {
        if (typeof errors.epcRealmsForRoamingList === "string") {
          return errors.epcRealmsForRoamingList;
        } else if (errors.epcRealmsForRoamingList[arrayIndex]) {
          return errors.epcRealmsForRoamingList[arrayIndex];
        }
      }
    }

    return "";
  }

  return (
    <>
      <TextField
        label="TADIG"
        defaultValue=" "
        value={values.plmnName}
        className={classes.input}
        disabled
        variant="outlined"
      />
      <Box className={classes.container}>
        <Field
          as={TextField}
          label="REALM"
          name="epcRealmsForRoamingList[0]"
          variant="outlined"
          error={hasError(0)}
          helperText={getErrorMessage(0)}
        />
        <Tooltip title="ADD MORE REALM">
          <IconButton onClick={handleAddInput}>
            <FiPlus size="1.5rem" />
          </IconButton>
        </Tooltip>
      </Box>
      {[...Array(Number(extraInputs))].map((value, index) => (
        <Box key={index} className={classes.container}>
          <Field
            as={TextField}
            label="REALM"
            name={`epcRealmsForRoamingList[${index + 1}]`}
            variant="outlined"
            error={hasError(index + 1)}
            helperText={getErrorMessage(index + 1)}
          />
          {index + 1 === extraInputs && (
            <Tooltip title="REMOVE REALM">
              <IconButton onClick={handleRemoveInput}>
                <FiMinus size="1.5rem" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ))}
    </>
  );
}

export default Ir21TemplateRealms;
