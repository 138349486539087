import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ErrorUnexpected, OperationSuccessfully } from "../../../const/Messages";
import * as Yup from "yup";
import * as ElementTypeService from "../../../service/ElementTypeService";
import * as NeLogsFileService from "../../../service/NeLogsFileService";
import * as VendorService from "../../../service/VendorService";

const initialValues = {
  elementType: "",
  vendor: "",
};

/**
 * Criação:
 * @author Guilherme Almeida
 * @date 15/02/2021
 * 
 * Atualização:
 * @author Thiago Guimarães
 * @date 01/09/2021
 * 
 */
export default function LogBatchUploadModal({ show, setShow }) {
  const [vendorList, setVendorList] = useState([]);
  const [elementTypeList, setElementTypeList] = useState([]);

  const SUPPORTED_FORMATS = ["text/plain"];
  const formikRef = useRef(null);

  useEffect(() => {
    if(show){
      VendorService.findBatchSupported()
        .then((vendors) => setVendorList(vendors))
        .catch((error) => toast.error(ErrorUnexpected));
    }
  }, [show]);

  const schema = Yup.object().shape({
    elementType: Yup.number().required("Element Type is a required field"),
    vendor: Yup.number().required("Vendor is a required field"),
    file: Yup.mixed()
      .required("A file is required")
      .test("fileFormat", "Unsupported Format", (value) => value && (SUPPORTED_FORMATS.includes(value.type) || value.name.includes(".log")))
  });

  function handleCloseModal() {
    setShow(false);
  }

  function updateElementTypeCombo(vendorId) {

    setElementTypeList([]);
    formikRef.current.setFieldValue("elementType", "");

    if (vendorId) {
      ElementTypeService.getBatchByVendor(vendorId)
        .then((elementTypes) => setElementTypeList(elementTypes))
        .catch((error) => toast.error(ErrorUnexpected));
    } 
  }

  function handleUpload(values, actions) {

    const formData = new FormData();
    formData.append("vendor", values.vendor);
    formData.append("elementType", values.elementType);
    formData.append("file", values.file);

    NeLogsFileService.uploadBatch(formData)
      .then(() => {
        toast.success(OperationSuccessfully);
        handleCloseModal(true);
      })
      .catch((error) => toast.error(ErrorUnexpected))
      .finally(() => actions.setSubmitting(false)); 
  }

  return (
    <Modal show={show} onHide={handleCloseModal} size="lg" animation={false}>
      <Formik initialValues={initialValues} innerRef={formikRef} validationSchema={schema} onSubmit={handleUpload}>
        {({ values, setFieldValue, isSubmitting, errors }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>File - Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
              <div className="form-group row">
                <label htmlFor="vendor" className="col-sm-3 col-form-label">
                  Vendor:
                </label>
                <div className="col-sm-6">
                  <Field
                    as="select"
                    name="vendor"
                    className="form-control"
                    value={values.vendor.id}
                    onChange={(ev) => {
                      setFieldValue("vendor", ev.target.value);
                      updateElementTypeCombo(ev.target.value);
                    }}
                  >
                    <option value="">-- Select Option --</option>
                    {vendorList.map((vendor) => (
                      <option value={vendor.id} key={vendor.id}>
                        {vendor.name}
                      </option>
                    ))}
                  </Field>
                  <span className="text-danger">
                    <ErrorMessage name="vendor" />
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="elementType" className="col-sm-3 col-form-label">
                  Element Type:
                </label>
                <div className="col-sm-6">
                  <Field
                    as="select"
                    name="elementType"
                    className="form-control"
                    value={values.elementType}
                    onChange={(ev) => {
                      setFieldValue("elementType", ev.target.value);
                    }}
                  >
                    <option value="">-- Select Option --</option>
                    {elementTypeList.map((elementType) => (
                      <option value={elementType.id} key={elementType.id}>
                        {elementType.name}
                      </option>
                    ))}
                  </Field>
                  <span className="text-danger">
                    <ErrorMessage name="elementType" />
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="element" className="col-sm-3 col-form-label">
                  File:
                </label>
                <div className="custom-file col-sm-6">
                  <input
                    name="file"
                    type="file"
                    className="custom-file-input form-control"
                    onChange={(ev) => {
                      setFieldValue("file", ev.target.files[0]);
                    }}
                    accept="text/plain"
                  />
                  <label className="custom-file-label form-control" style={{ position: "relative", top: -40}} htmlFor="file">
                    {values.file ? values.file.name : "Choose file"}
                  </label>
                  <span className="text-danger" style={{ position: "relative", top: -40}}>{errors.file && errors.file}</span>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="mt-3 mr-4 d-flex justify-content-end">
                <Button className="mr-1" onClick={handleCloseModal} variant="primary" type="button">
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Upload
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
