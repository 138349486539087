import api from './api';

export function findAll(){

    return new Promise( (resolve, reject) => {

        api.get('/roles')
        .then( response => {
            resolve(response.data);
        })
        .catch( error => {
            reject(error);
        })
    })
}

