import api from "./api";

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/template/huawei/ugw")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function merge(id, templateEricssonHlr) {
  return new Promise((resolve, reject) => {
    api({
      method: "put",
      url: `/template/huawei/ugw/${id}`,
      data: templateEricssonHlr,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
