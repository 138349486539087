import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiEdit, FiRotateCw, FiTrash2 } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  ErrorUnexpected,
  OperationSuccessfully,
} from "../../../../const/Messages";
import { Context } from "../../../../context/Context";
import * as UserService from "../../../../service/UserService";

/**
 * @author Thiago Guimarães
 * @date 28/01/2021
 */
function UserList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const { user: loggedUser, handleLogout } = useContext(Context);

  useEffect(() => {
    UserService.findList()
      .then((userList) => {
        setUsers(userList);
      })
      .catch(() => {
        toast.error(ErrorUnexpected);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Role",
      field: "roles[0].name",
      render: (row) => {
        let name = "";
        row.roles.forEach((role) => {
          if (role.name !== "VERTIS") {
            name = role.name;
          }
        });
        return name;
      },
    },
  ];

  function handleDelete(user) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: `Are you sure you want to delete ${user.name} Account?`,
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.deleteById(user.id)
          .then(() => {
            if (loggedUser.id === user.id) {
              handleLogout();
            } else {
              UserService.findList()
                .then((userList) => {
                  toast.success(OperationSuccessfully);
                  setUsers(userList);
                })
                .catch(() => {
                  toast.error(ErrorUnexpected);
                });
            }
          })
          .catch(() => {
            toast.error(ErrorUnexpected);
          });
      }
    });
  }

  function handleResetPassword(user) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: `Are you sure you want to reset password ${user.name} Account?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        UserService.resetPassword(user.id)
          .then(() => {
            toast.success(OperationSuccessfully);
          })
          .catch(() => {
            toast.error(ErrorUnexpected);
          });
      }
    });
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between">
            <h5>Users List</h5>
            <div>
              <Link className="btn btn-primary" to="/users/reg">
                New User
              </Link>
            </div>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={users}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "users-list",
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
              actions={[
                {
                  icon: () => <FiEdit size={16} color="#007bff" />,
                  tooltip: "EDIT",
                  onClick: (event, rowData) =>
                    history.push("users/reg", { id: rowData.id }),
                },
                {
                  icon: () => <FiRotateCw size={16} color="#007bff" />,
                  tooltip: "RESET PASSWORD",
                  onClick: (event, rowData) => handleResetPassword(rowData),
                },
                {
                  icon: () => <FiTrash2 size={16} color="#007bff" />,
                  tooltip: "DELETE",
                  onClick: (event, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default UserList;
