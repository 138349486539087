import { Collapse, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@material-ui/core";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import { MdFiberManualRecord } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useStylesNavColapse, useStylesNavItem } from "./MenuItemStyles";
import RoleControl from "../../security/RoleControl";
import { useMenuContext } from "../../../context/MenuContext";

export default function MenuItem({ item, level }) {
  const { openSubMenu, closeSubMenu, subMenu, currentPage, setCurrentPage } = useMenuContext();
  const theme = useTheme();
  const location = useLocation();

  function onClickMenu(item) {
    if (item.path) {
      //Redirecionar para o caminho
       setCurrentPage(item.path);
    } else if (item.children) {
      openSubMenu(item);
    } else {
      closeSubMenu();
    }
  }

  function isSelected(item){
    if(item.path === currentPage){
      return true;
    }
    return false;
  }

  function isActive(item) {
    if (subMenu?.text === item.text) {
      return true;
    }
    return false;
  }

  return (
    <RoleControl roles={item.roles}>
      <Link to={item.path} style={{textDecoration: "none"}}>
        <ListItem
          button
          onClick={() => onClickMenu(item)}
          style={{
            display: "flex",
            padding: "8px",
            textAlign: "center",
            borderRadius: "4px",
            marginBottom: "8px",
            flexDirection: "column",
            width: "100%",
            position: "relative",
            color: isActive(item) || isSelected(item) ? '#009EE2' : 'gray',
            borderLeft: isActive(item) || isSelected(item) ? "5px solid #009EE2" : 0,
            borderColor: theme.palette.primary.main,
          }}
        >
          <item.icon size={32} />
          <Typography
            style={{
              width: "100px",
              display: "block",
              marginTop: "8px",
              fontSize: "12px",
              fontWeight: "700",
              whiteSpace: "break-spaces",
              textOverflow: "ellipsis",
              color: "inherit",
            }}
          >
            {item.text}
          </Typography>
        </ListItem>
      </Link>
    </RoleControl>
  );
}

function NavColapse({ item, level, selected }) {
  const classes = useStylesNavColapse();
  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen(!open);
  }

  useEffect(() => {
    setOpen(selected === item.id);
  }, [selected]) // eslint-disable-line 

  const Icon = item.icon;
  const menuIcon = item.icon ? (
    <Icon className={classes.listIcon} />
  ) : (
    <MdFiberManualRecord
      className={selected === item.id ? classes.listCustomIconSubActive : classes.listCustomIconSub}
      fontSize={level > 0 ? "inherit" : "default"}
    />
  );

  let menuIconClass = !item.icon ? classes.listIcon : classes.menuIcon;

  return (
    <>
      <ListItem
        component="div"
        button={item.defaultOpen ? false : true}
        className={level > 1 ? classes.listItemNoBack : classes.listItem}
        selected={selected === item.id}
        onClick={handleClick}
        style={{ paddingLeft: level * 15 + "px" }}
      >
        <ListItemIcon className={menuIconClass}>{menuIcon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography
              variant="body1"
              style={{
                fontWeight: selected === item.id ? 500 : 400,
                textDecoration: selected === item.id ? "underline" : "",
                fontStyle: selected === item.id ? "italic" : "",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              color="inherit"
              className={classes.listItemTypography}
            >
              {item.text}
            </Typography>
          }
        />
        {item.defaultOpen ? (
          <></>
        ) : open ? (
          <FiChevronUp color="white" size="1rem" className={classes.collapseIcon} />
        ) : (
          <FiChevronDown color="white" size="1rem" className={classes.collapseIcon} />
        )}
      </ListItem>
      <Collapse in={item.defaultOpen ? true : open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.collapseWrapper}>
          {item.children.map((children) => (
            <MenuItem key={children.id} item={children} level={level + 1} />
          ))}
        </List>
      </Collapse>
    </>
  );
}

function NavItem({ item, level, selected }) {
  const classes = useStylesNavItem();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(selected === item.id);
  }, [selected]) // eslint-disable-line 

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon stroke={1.5} className={classes.listIcon} />
  ) : (
    <MdFiberManualRecord
      className={isActive ? classes.listCustomIconSubActive : classes.listCustomIconSub}
      fontSize={level > 0 ? "inherit" : "default"}
    />
  );

  let itemIconClass = !item.icon ? classes.listIcon : classes.menuIcon;
  let listItemProps = { component: React.forwardRef((props, ref) => <Link {...props} to={item.path} />) };

  return (
    <ListItem
      {...listItemProps}
      key={item.text}
      button
      className={level > 1 ? classes.listItemNoBack : classes.listItem}
      selected={isActive}
      style={{ paddingLeft: level * 15 + "px" }}
    >
      <ListItemIcon className={itemIconClass}>{itemIcon}</ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant="body1"
            style={{
              fontWeight: isActive ? 500 : 400,
              textDecoration: isActive ? "underline" : "",
              fontStyle: isActive ? "italic" : "",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            color="inherit"
          >
            {item.text}
          </Typography>
        }
      />
    </ListItem>
  );
}
