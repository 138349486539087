import api from "./api";

export function deleteById(id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/imsi-change-rules/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function findAll() {
  return new Promise((resolve, reject) => {
    api
      .get("/imsi-change-rules")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/imsi-change-rules/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function merge(id, imsiChangeRule) {
  return new Promise((resolve, reject) => {
    api({
      method: id ? "put" : "post",
      url: id ? `/imsi-change-rules/${id}` : "/imsi-change-rules",
      data: imsiChangeRule,
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
