import api from "./api";

export function recovery(email) {
  return new Promise((resolve, reject) => {
    api
      .post("/auth/password/recovery", { email })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function validRecoveryToken(token) {
  return new Promise((resolve, reject) => {
    api
      .get(`/auth/password/reset?t=${token}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function resetPassword(resetPasswordObject) {
  return new Promise((resolve, reject) => {
    api
      .post("/auth/password/reset", resetPasswordObject)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
