import React from "react";
import { Row, Col, Accordion, Card, Button } from "react-bootstrap";
import "./ReleaseNotes.css";

/**
 * @author Thiago Guimarães
 * @date 19/04/2021
 */

/**
<Card>
    <Card.Header>
        <Accordion.Toggle as={Button} variant="" eventKey="2" className="button-toggle">
            <div className="version-header">
            <span>0.9</span>
            <span>13/05/2021</span>
            </div>
        </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="2">
        <Card.Body>
            <div className="version-data">
                <div className="version-tag">
                    <div />
                    <span className="new">NEW</span>
                    <div />
                </div>
                <div className="version-tag-content">
                    <p> - <b>titulo</b>
                        <p>- subtitulo
                            <p className="mb-1">- conteudo </p>
                        </p>
                    </p>
                </div>
                <div className="version-tag">
                    <div />
                    <span className="improvement">IMPROVEMENT</span>
                    <div />
                </div>
                <div className="version-tag-content">
                    <p> - <b>titulo</b>
                        <p>- subtitulo
                            <p className="mb-1">- conteudo </p>
                        </p>
                    </p>
                </div>
                <div className="version-tag">
                    <div />
                    <span className="bug">BUG FIXED</span>
                    <div />
                </div>
                <div className="version-tag-content">
                    <p> - <b>titulo</b>
                        <p>- subtitulo
                            <p className="mb-1">- conteudo </p>
                        </p>
                    </p>
                </div>
            </div>
        </Card.Body>
    </Accordion.Collapse>
</Card>
*/

function ReleaseNotes() {
  return (
    <Row>
      <Col>
        <div>
          <div className="title">
            <h1>Release Notes</h1>
          </div>
          <div className="content">
          <Accordion defaultActiveKey="50">
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="50" className="button-toggle">
                      <div className="version-header">
                        <span>2.7.1</span>
                        <span>12/11/2024</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="50">
                  <Card.Body>
                      <div className="version-data">
                        <div className="version-tag">
                          <div />
                            <span className="improvement">IMPROVEMENT</span>
                          <div />
                        </div>
                        <div className="version-tag-content">
                            <p> - <b>Audit HLR X Roaming Partners - Blocked</b>
                              <p className="mb-1">- A auditoria do HLR passa a desconsiderar a sinalização de bloqueio com erro quando o ROAMREST estiver habilitado.</p>
                            </p>
                        </div>                                                
                        <div className="version-tag">
                          <div />
                            <span className="bug">BUG FIXED</span>
                          <div />
                        </div>
                        <div className="version-tag-content">
                            <p> - <b>Audit DRA - RT EXIT</b>
                              <p className="mb-1">- Correção na auditoria para desconsiderar o MCC = 724.</p>
                            </p>
                        </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>   
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="49" className="button-toggle">
                      <div className="version-header">
                        <span>2.7.0</span>
                        <span>31/10/2024</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="49">
                  <Card.Body>
                      <div className="version-data">
                        <div className="version-tag">
                          <div />
                            <span className="improvement">IMPROVEMENT</span>
                          <div />
                        </div>
                        <div className="version-tag-content">
                            <p> - <b>Audit HSS - Configuration Container</b>
                              <p className="mb-1">Melhoria na regra de auditoria do REALM. Passa a considerar a informação do IR.21. Removido a regra de equalização de três casas decimais para o MNC.</p>
                            </p>
                            <p> - <b>Report</b>
                                <p>- Export do Relatório das Operadoras com Redundância de Carrier
                                    <p className="mb-1">- Melhoria do upload dos dados do IR.21 no GSMA.</p>
                                </p>
                            </p>
                        </div>                                                
                        <div className="version-tag">
                          <div />
                            <span className="bug">BUG FIXED</span>
                          <div />
                        </div>
                        <div className="version-tag-content">
                            <p> - <b>Cadastro de Usuários</b>
                              <p className="mb-1">Correção do envio de e-mail. Temporariamente será utilizado o e-mal: roaming-audit-tool@outlook.com.</p>
                            </p>
                        </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>   
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="48" className="button-toggle">
                      <div className="version-header">
                        <span>2.6.0</span>
                        <span>30/10/2024</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="48">
                  <Card.Body>
                      <div className="version-data">
                        <div className="version-tag">
                            <div />
                              <span className="new">NEW</span>
                            <div />
                        </div>
                        <div className="version-tag-content">
                            <p> - <b>Report</b>
                                <p>- Export do Relatório das Operadoras com Redundância de Carrier
                                    <p className="mb-1">- caminho: Menu lateral esquerdo &#62; Tadigs &#62; Tadig - List &#62; Export Redundant Carrier (Ícone de arquivo abaixo do botão New Tadig) </p>
                                </p>
                            </p>
                            <p> - <b>Roaming Partners - Blocked</b>
                                  <p className="mb-1">- Nova funcionalidade que bloqueia a auditoria dos elementos HLR e HSS.</p>
                                  <p className="mb-1">- Com o acordo bloqueado, as auditorias de HLR e HSS são consideradas como sem acordo.</p>
                            </p>
                        </div>
                        <div className="version-tag">
                          <div />
                            <span className="improvement">IMPROVEMENT</span>
                          <div />
                        </div>
                        <div className="version-tag-content">
                            <p> - <b>Audit PTS - VRS NNGT</b>
                              <p className="mb-1">Melhoria na regra de definição de prefixos para os ranges de global title, a fim de não alarmar falso positivo na auditoria.</p>
                            </p>
                        </div>                                                
                        <div className="version-tag">
                          <div />
                            <span className="bug">BUG FIXED</span>
                          <div />
                        </div>
                        <div className="version-tag-content">
                            <p> - <b>Audit DRA - RTEXIT</b>
                              <p className="mb-1">Correção na auditoria.</p>
                            </p>
                        </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>   
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="47" className="button-toggle">
                      <div className="version-header">
                        <span>2.5.1</span>
                        <span>04/09/2024</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="47">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                            <div />
                              <span className="improvement">IMPROVEMENT</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Audit VoLTE - HSS/Ericsson e UGW/Huawei</b>
                                <p className="mb-1">Correção da auditoria para os tipos de acordos, regra:
                                  <p className="mb-2">- OUTUBOUND: HSS e UGW.</p>
                                  <p className="mb-2">- INBOUND: MME.</p>
                                </p>
                              </p>
                              <p> - <b>Roaming Partners Upload</b>
                                  <p className="mb-1">- O upload da planilha de acordos irá manter todos os cadastros de testes, regra:
                                    <p className="mb-2">- Se cadastro VRAT = TESTING e planilha de acordos = EMPTY -&#62; mantém TESTING.</p>
                                    <p className="mb-2">- Se cadastro VRAT = TESTING e planilha de acordos = OUT -&#62; cadastra OUTBOUND IN TESTING.</p>
                                    <p className="mb-2">- Se cadastro VRAT = TESTING e planilha de acordos = IN -&#62; cadastra INBOUND OUT TESTING.</p>
                                    <p className="mb-2">- Se cadastro VRAT = TESTING e planilha de acordos = IN/OUT -&#62; cadastra BILATERAL .</p>
                                    <p className="mb-2">- Se cadastro VRAT = OUTBOUND IN TESTING e planilha de acordos = empty -&#62; cadastra TESTING.</p>
                                    <p className="mb-2">- Se cadastro VRAT = OUTBOUND IN TESTING e planilha de acordos = OUT -&#62; mantém OUTBOUND IN TESTING.</p>
                                    <p className="mb-2">- Se cadastro VRAT = OUTBOUND IN TESTING e planilha de acordos = IN -&#62; cadastra INBOUND OUT TESTING.</p>
                                    <p className="mb-2">- Se cadastro VRAT = OUTBOUND IN TESTING e planilha de acordos = IN/OUT -&#62; cadastra BILATERAL.</p>
                                    <p className="mb-2">- Se cadastro VRAT = INBOUND OUT TESTING e planilha de acordos = empty -&#62; cadastra TESTING.</p>
                                    <p className="mb-2">- Se cadastro VRAT = INBOUND OUT TESTING e planilha de acordos = OUT -&#62; cadastra OUTBOUND IN TESTING.</p>
                                    <p className="mb-2">- Se cadastro VRAT = INBOUND OUT TESTING e planilha de acordos = IN -&#62; mantém INBOUND OUT TESTING.</p>
                                    <p className="mb-2">- Se cadastro VRAT = INBOUND OUT TESTING e planilha de acordos = IN/OUT -&#62; cadastra BILATERAL.</p>
                                  </p>
                              </p>
                          </div>                                             
                          <div className="version-tag">
                            <div />
                            <span className="bug">BUG FIXED</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p>- <b>Relatório de HSS</b> (CONFIGURATION CONTAINER)
                                <p>- Alterado a coluna IMSI para a coluna NETWORK DOMAIN.</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>   
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="46" className="button-toggle">
                      <div className="version-header">
                        <span>2.5.0</span>
                        <span>02/09/2024</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="46">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                            <div />
                              <span className="new">NEW</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Audit DRA - RTEXIT</b>
                                <p className="mb-1">REGRA: A auditoria da tabela RTEXIT considera que o IMSI com acordo deve estar presente e referenciado nas tabelas RTIMSI e RTRESULT, onde:
                                  <p className="mb-2">- Tabela RTIMSI: Colunas REFERENCE INDEX=1, IMSI=(Acordos Ativos) e NEXT REFERENCE INDEX.</p>
                                  <p className="mb-2">- Tabela RTEXIT: Colunas REFERENCE INDEX=(RTIMSI: NEXT REFERENCE INDEX), ROUT RESULT NAME.</p>
                                  <p className="mb-2">- Tabela RTRESULT: Coluna ROUT RESULT NAME=(RTEXT: ROUT RESULT NAME).</p>
                                  <p className="mb-2">- UPLOAD INDIVIDUAL: Mantém o padrão do DRA, todos os logs são carregados no mesmo arquivo.</p>
                                  <p className="mb-2">- O relatório de RTEXIT é o Report by Error &#62; DRA &#62; RTEXIT.</p>
                                </p>
                              </p>
                          </div>                                             
                          <div className="version-tag">
                            <div />
                            <span className="bug">BUG FIXED</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Audit VoLTE - HSS/Ericsson e UGW/Huawei</b>
                                  <p className="mb-1">Correção para ignorar a verificação dos acordos quando o tipo do acordo for TESTING.</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>   
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="45" className="button-toggle">
                      <div className="version-header">
                        <span>2.4.0</span>
                        <span>19/08/2024</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="45">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                            <div />
                              <span className="new">NEW</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Audit VoLTE</b>
                                <p className="mb-1">HSS/Ericsson</p>
                                <p className="mb-1">UGW/Huawei</p>
                              </p>
                              <p> - <b>Audit VoLTE Settings - UGW/Huawei </b>
                                <p className="mb-1">path: Settings &#62; General Settings &#62; Template Report &#62; UGW/Huawei</p>
                              </p>
                          </div>
                          <div className="version-tag">
                            <div />
                              <span className="improvement">IMPROVEMENT</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                            <p> - <b>Audit PTS - VRS NNGT</b>
                              <p className="mb-1">Nova regra para definição dos prefixos dos ranges de global title para não alarmar falso positivo na auditoria.</p>
                            </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>   
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="44" className="button-toggle">
                      <div className="version-header">
                        <span>2.3.1</span>
                        <span>07/08/2024</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="44">
                  <Card.Body>
                      <div className="version-data">                                                
                          <div className="version-tag">
                            <div />
                              <span className="improvement">IMPROVEMENT</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Report</b>
                                  <p className="mb-1">- Export das Informações do IR.21.
                                    <p className="mb-2">- Adicionado as colunas 'Organiozation' e 'Country'.</p>
                                  </p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>   
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="43" className="button-toggle">
                      <div className="version-header">
                        <span>2.3.0</span>
                        <span>31/07/2024</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="43">
                  <Card.Body>
                      <div className="version-data">                                                
                          <div className="version-tag">
                            <div />
                              <span className="new">NEW</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Report</b>
                                  <p className="mb-1">- Export das Informações do IR.21 (Somente as informações salvas na base de dados).
                                    <p className="mb-2">- caminho: Menu lateral esquerdo &#62; Tadigs &#62; Tadig - List &#62; Export IR.21 (Ícone de arquivo abaixo do botão New Tadig).</p>
                                  </p>
                              </p>
                          </div>
                          <div className="version-tag">
                            <div />
                            <span className="improvement">IMPROVEMENT</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Layout Design</b>
                                  <p className="mb-1">- Incluido a informação 'International' no título do aplicativo. (Roaming Audit Tool - International)</p>
                              </p>
                              <p> - <b>Report</b>
                                  <p className="mb-1">- O export das listagens passa a exportar todos os registros. Antes exportava apenas os exibidos na tela.</p>
                              </p>
                          </div>
                          <div className="version-tag">
                            <div />
                            <span className="bug">BUG FIXED</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Layout Design</b>
                                  <p className="mb-1">- Correção da exibição do modal.</p>
                              </p>
                              <p> - <b>Roaming Partners Upload</b>
                                  <p className="mb-1">- Removido do import os dados para os acordos nacionais.</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>   
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="42" className="button-toggle">
                      <div className="version-header">
                        <span>2.2.0</span>
                        <span>01/07/2024</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="42">
                  <Card.Body>
                      <div className="version-data">                                                
                          <div className="version-tag">
                            <div />
                              <span className="new">NEW</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Roaming Partners Upload</b>
                                  <p className="mb-1">- Upload da planilha de acordos do comercial.
                                    <p className="mb-2">- Incluído botão "Import Data" na listagem de "Roaming Partners".</p>
                                    <p className="mb-2">- Nova tela com a listagem "Roaming Partners Upload" que mostra todos os uploads realizados com informações de data, usuário e o arquivo utilizado. O arquivo carregado pode ser baixado e re-executado.
                                      <p className="mb-3">- Botão "Sample" - Download do template do arquivo utilizado como modelo para o upload.</p>
                                      <p className="mb-3">- Botão "Upload" - Funcionalidade para selecionar o arquivo que será importado na aplicação e descreve a regra que serão aplicadas.</p>
                                    </p>
                                    <p className="mb-2">- Alertas - No ícode de alerta(sino) do sistema, será informado qualquer processamento inválido, caso o arquivo seja diferente do template ou caso algum TADIG não for processado.</p>
                                  </p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>   
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="41" className="button-toggle">
                      <div className="version-header">
                        <span>2.1.1</span>
                        <span>17/03/2024</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="41">
                  <Card.Body>
                      <div className="version-data">                                                
                          <div className="version-tag">
                            <div />
                            <span className="bug">BUG FIXED</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Audit SoR</b>
                                  <p className="mb-1">Realizada correção para resolver a exibição incorreta de "MISSING" como "TESTING".</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>   
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="40" className="button-toggle">
                      <div className="version-header">
                        <span>2.1.0</span>
                        <span>15/03/2024</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="40">
                  <Card.Body>
                      <div className="version-data">                                                
                          <div className="version-tag">
                            <div />
                            <span className="bug">BUG FIXED</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Audit SoR</b>
                                  <p className="mb-1">Correção para considerar somente os seguintes tipos de acordos:
                                    <p className="mb-2">- BILATERAL</p>
                                    <p className="mb-2">- OUTBOUND</p>
                                    <p className="mb-2">- OUTBOUND IN TESTING</p>
                                    <p className="mb-2">- INBOUND OUT TESTING</p>
                                  </p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>   
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="39" className="button-toggle">
                      <div className="version-header">
                        <span>2.0.1</span>
                        <span>24/08/2023</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="39">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                            <div />
                              <span className="bug">BUG FIXED</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Audit DRA - RTDHOST</b>
                                  <p className="mb-1">- Correção do informativo de erros.</p>
                              </p>
                              <p> - <b>Layout</b>
                                  <p className="mb-1">- Ajuste da resolução da página.</p>
                              </p>
                              <p> - <b>Mapa Mundi</b>
                                  <p className="mb-1">- Removido as combos de "Access Type" e "Domain".</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>   
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="38" className="button-toggle">
                      <div className="version-header">
                        <span>2.0.0</span>
                        <span>09/06/2023</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="38">
                  <Card.Body>
                      <div className="version-data">                                                
                          <div className="version-tag">
                            <div />
                            <span className="improvement">IMPROVEMENT</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Layout Design</b>
                                  <p className="mb-1">- Identidade visual</p>
                                  <p className="mb-1">- Menu lateral esquerdo</p>
                              </p>
                              <p> - <b>Report</b>
                                  <p className="mb-1">- DRA - Mais informações no export dos relatórios:
                                    <p className="mb-2">- HUB (Normal, K2R, VRS)</p>
                                    <p className="mb-2">- MGT CC</p>
                                    <p className="mb-2">- MGT NC</p>
                                  </p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>   
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="37" className="button-toggle">
                      <div className="version-header">
                        <span>1.2.1</span>
                        <span>18/05/2023</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="37">
                  <Card.Body>
                      <div className="version-data">                                                
                          <div className="version-tag">
                            <div />
                            <span className="bug">BUG FIXED</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Audit SoR</b>
                                  <p className="mb-1">Correção para considerar somente os seguintes tipos de acordos:
                                    <p className="mb-2">- BILATERAL</p>
                                    <p className="mb-2">- OUTBOUND</p>
                                    <p className="mb-2">- OUTBOUND IN TESTING</p>
                                  </p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>   
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="36" className="button-toggle">
                      <div className="version-header">
                        <span>1.2.0</span>
                        <span>25/04/2023</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="36">
                  <Card.Body>
                      <div className="version-data">                                                
                          <div className="version-tag">
                            <div />
                            <span className="new">NEW</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>IR.21 - Suporte Nova Versão</b>
                                  <p className="mb-1">- TADIG-RAEX-IR.21 schema version 16.0.</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>   
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="35" className="button-toggle">
                      <div className="version-header">
                        <span>1.1.3</span>
                        <span>23/03/2023</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="35">
                  <Card.Body>
                      <div className="version-data">                                                
                          <div className="version-tag">
                            <div />
                            <span className="improvement">IMPROVEMENT</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                            <p> - <b>Audit DRA</b>
                                <p className="mb-1">- Correção para validar os realms do IR.21 e com a extensão ".key2roam.comfone.com".
                                    <p className="mb-2">- DMRT</p>
                                    <p className="mb-2">- RTDHOST</p>
                                    <p className="mb-2">- RTRESULT</p>
                                </p>
                            </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="34" className="button-toggle">
                      <div className="version-header">
                        <span>1.1.2</span>
                        <span>15/02/2023</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="34">
                  <Card.Body>
                      <div className="version-data">                                                
                          <div className="version-tag">
                            <div />
                            <span className="bug">BUG FIXED</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                            <p> - <b>Audit HLR - Sinalização de erro nos campos CHAR e NA</b>
                                <p className="mb-1">- Correção para não sinalização de erros para acordos em teste.</p>
                            </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="33" className="button-toggle">
                      <div className="version-header">
                        <span>1.1.1</span>
                        <span>15/12/2022</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="33">
                  <Card.Body>
                      <div className="version-data">                                                
                          <div className="version-tag">
                            <div />
                            <span className="improvement">IMPROVEMENT</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                            <p> - <b>Report</b>
                                <p className="mb-1">- O campos "Log Value" e "Template Value" passarão a exibir seus conteúdos entre aspas(""), a fim de possibilitar a visualização de espaços adicionados nos valores analisados.</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="32" className="button-toggle">
                      <div className="version-header">
                        <span>1.1.0</span>
                        <span>22/09/2022</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="32">
                  <Card.Body>
                      <div className="version-data">                                                
                          <div className="version-tag">
                            <div />
                            <span className="improvement">IMPROVEMENT</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                            <p> - <b>Global Title - NNGT</b>
                                <p className="mb-1">- Adicionado a possibilidade de verificação do NNGT com todos os PREFIXOS possíveis gerados pelo range (CC + NDC + SN_RANGE_START + SN_RANGE_STOP).</p>
                                <p className="mb-1">- Para os NEs que verificam o NNGT, é possível escolher o tipo de verificação. Em Settings &gt; General Settings &gt; Setup Report &gt; NE, configurar: Global Title = (Single prefix | All Prefixes).</p>
                                <p className="mb-1">- NA funcionalidade de Global Title, foi adicionado a coluna ACTIONS, onde pode ser verificado os prefixos gerados pelo sistema.</p>
                            </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="31" className="button-toggle">
                      <div className="version-header">
                        <span>1.0.0</span>
                        <span>25/08/2022</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="31">
                  <Card.Body>
                      <div className="version-data">                                                
                          <div className="version-tag">
                            <div />
                            <span className="new">NEW</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                            <p> - <b>Notificação Automatizada</b>
                                <p className="mb-1">- Falha de Conexão com GSMA.</p>
                                <p className="mb-1">- Falha de Leitura do Arquivo IR.21.</p>
                            </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="30" className="button-toggle">
                      <div className="version-header">
                        <span>0.29.1</span>
                        <span>04/08/2022</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="30">
                  <Card.Body>
                      <div className="version-data">                                                
                          <div className="version-tag">
                            <div />
                            <span className="bug">BUG FIXED</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                            <p> - <b>Audit SOR - Report by Error</b>
                                <p className="mb-1">- Correção da exibição dos detalhes do relatório.</p>
                            </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
          </Card>
          <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="29" className="button-toggle">
                      <div className="version-header">
                        <span>0.29</span>
                        <span>01/08/2022</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="29">
                  <Card.Body>
                      <div className="version-data">
                        <div className="version-tag">
                              <div />
                              <span className="new">NEW</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>TADIG Association <i>(Settings &gt; General Settings &gt; Register &gt; Tadig Association)</i></b>
                                  <p className="mb-1">- Cadastro de TADIGs que pertencem a um mesmo grupo. Um TADIG é definido como pai e os demais serão os filhos. As informações no IR.21 dos TADIGs filhos podem conter informações do TADIG pai. Esta informação de grupo é relevante na hora da verificação das informações.</p>
                              </p>
                          </div>
                          <div className="version-tag">
                            <div />
                            <span className="improvement">IMPROVEMENT</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                            <p> - <b>Audit SOR</b>
                                <p className="mb-1">- Adicionado para VLR e SGSN a opção de escolha para validar os dados de GT_ADRESS(NNGT) pela regra de TADIG Association. Configurar em: <i>Settings &gt; General Settings &gt; Setup Report &gt; SOR/Sixbell.</i></p>
                            </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="28" className="button-toggle">
                      <div className="version-header">
                        <span>0.28</span>
                        <span>14/07/2022</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="28">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                            <div />
                            <span className="improvement">IMPROVEMENT</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                            <p> - <b>Audit SOR - Ignored Cases</b>
                                <p className="mb-1">- Adicionado para VLR e SGSN a opção de escolha para ignorar pelo IMSI ou pelo IMSI + GT_ADRESS(NNGT). Configurar em: <i>Settings &gt; General Settings &gt; Setup Report &gt; SOR/Sixbell.</i></p>
                            </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="27" className="button-toggle">
                      <div className="version-header">
                        <span>0.27.1</span>
                        <span>28/06/2022</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="27">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                            <div />
                            <span className="improvement">IMPROVEMENT</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                            <p> - <b>Upload Logs</b>
                                <p className="mb-1">- Melhoria para aceitar arquivos com as extensões ".txt" e ".log".</p>
                            </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="26" className="button-toggle">
                      <div className="version-header">
                        <span>0.27</span>
                        <span>18/05/2022</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="26">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                            <div />
                            <span className="improvement">IMPROVEMENT</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                            <p> - <b>Audit HSS - Análise de Log em Batch</b>
                                <p className="mb-1">- Adicionado a análise dos logs de HSS em batch, conforme modelo do arquivo disponível em "Samples".</p>
                                <p className="mb-1">- <b>Importante:</b> Não é necessário que o elemento esteja cadastrado no sistema, o cadastro é realizado automaticamente. Uma vez que o elemento já existe no sistema, o mesmo deve estar ativo para que seus dados sejam analisados e salvos, caso contrário o trecho do log do elemento no arquivo batch será descartado.</p>
                            </p>
                          </div>
                          <div className="version-tag">
                            <div />
                            <span className="bug">BUG FIXED</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                            <p> - <b>Upload Log - Batch</b>
                                <p className="mb-1">- Vendor: Correção da informação da caixa de seleção para exibir apenas os que suportam a funcionalidade.</p>
                            </p>
                          </div> 
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="25" className="button-toggle">
                      <div className="version-header">
                        <span>0.26.2</span>
                        <span>31/03/2022</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="25">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                            <div />
                            <span className="bug">BUG FIXED</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                            <p> - <b>Audit DRA - RTDHOST, RTRESULT</b>
                                <p className="mb-1">- Correção da geração do relatório.</p>
                            </p>
                          </div>  
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="24" className="button-toggle">
                      <div className="version-header">
                        <span>0.26.1</span>
                        <span>09/03/2022</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="24">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                            <div />
                           <span className="improvement">IMPROVEMENT</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                            <p> - <b>Audit SoR - MME</b>
                                <p className="mb-1">- Verificação do log para o REALM do SoR passa a ser case sensitivo. Todos os REALMs devem ter duas informações duplicadas, uma com letras maiúsculas e outra com letras minúsculas.</p>
                            </p>
                          </div>  
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="23" className="button-toggle">
                      <div className="version-header">
                        <span>0.26</span>
                        <span>03/03/2022</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="23">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                            <div />
                           <span className="improvement">IMPROVEMENT</span>
                            <div />
                          </div>
                          <div className="version-tag-content">
                            <p> - <b>Análise de Log - Ajuste Para Padronizar Todas as Análises Com a Regra de REALM Específico.</b>
                                <p className="mb-1">- DRA</p>
                                <p className="mb-1">- eDNS</p>
                                <p className="mb-1">- GLR</p>
                                <p className="mb-1">- HSS</p>
                            </p>
                            <p> - <b>REALM - Cadastro Específico. (Settings &gt; General Settings &gt; Register &gt; Realm)</b> 
                                <p className="mb-1">- Adicionado a listagem dos REALMs cadastrados.</p>
                            </p>
                          </div><div className="version-tag">
                              <div />
                              <span className="bug">BUG FIXED</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>HLR Template</b>
                                  <p className="mb-1">- Correção do link do cadastro.</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="22" className="button-toggle">
                      <div className="version-header">
                        <span>0.25</span>
                        <span>09/02/2022</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="22">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                              <div />
                              <span className="improvement">IMPROVEMENT</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Audit HSS (DIA-CFG-RoutingContainer)</b>
                                  <p className="mb-1">- Adicionado suporte ao output do comando "show -r".</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="21" className="button-toggle">
                      <div className="version-header">
                        <span>0.24</span>
                        <span>27/01/2022</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="21">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                              <div />
                              <span className="new">NEW</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>REALM - Cadastro Específico. (Settings &gt; General Settings &gt; Register &gt; Realm)</b>
                                  <p className="mb-1">- Cadastro específico de REALM por TADIG. Utilizado em conjunto com as informações do IR.21 na verificação dos dados. Pode ser configurado para ser o template único de verificação.</p>
                              </p>
                          </div>
                          <div className="version-tag">
                              <div />
                              <span className="improvement">IMPROVEMENT</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Audit SoR - VLR e SGSN</b>
                                  <p className="mb-1">- Adicionado as colunas LOG VALUE e REF VALUE na exportação do relatório.</p>
                              </p>
                              <p> - <b>TADIG - IR.21 TEMPLATE</b>
                                  <p className="mb-1">- Removido as obrigatoriedades dos campos, em compatibilidade com o IR.21. Os campos MCC, MNC, MGT-CC e MGT-NC continuam obrigatórios.</p>
                                  <p className="mb-1">- Incluído uma tela final de visualização geral das informações para verificação.</p>
                              </p>
                              <p> - <b>UPLOAD LOG</b>
                                  <p className="mb-1">- Botão SAMPLES: Download dos modelos dos arquivos coletados dos NEs e utilizados pela análise no Audit.</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="20" className="button-toggle">
                      <div className="version-header">
                        <span>0.23.2</span>
                        <span>09/12/2021</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="20">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                              <div />
                              <span className="new">NEW</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Layout e Organização das Funcionalidades</b>
                                  <p className="mb-1">- Ícone de Engrenagem(configurações) inserido na barra verde superior do lado direito para centralizar as funcionalidades de configurações do sistema.</p>
                                  <p className="mb-1">- Menu lateral esquerdo retrátil para as funcionalidades ocuparem toda a tela, melhorando a usabilidade do usuário.</p>
                                  <p className="mb-1">- No menu lateral esquerdo foram mantidas apenas as funcionalidades de análise e verificação dos dados.</p>
                              </p>
                          </div>
                          <div className="version-tag">
                              <div />
                              <span className="improvement">IMPROVEMENT</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>GLR (Sixbell) - Análise de Log</b>
                                  <p>- Melhoria da análise de verificação do REALM
                                      <p className="mb-1">1- Todos os REALMs cadastrados para o TADIG no IR.21 devem estar no log.</p>
                                      <p className="mb-1">2- TADIG com acordo ativo que não possui REALM cadastrado no IR.21, é verificado se existe o REALM cadastrado na organização.</p>
                                  </p>
                              </p>
                          </div>
                          <div className="version-tag">
                              <div />
                              <span className="bug">BUG FIXED</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Roaming Partner</b>
                                  <p className="mb-1">- Correção da funcionalidade do botão "NEW" na caixa de seleção TADIG.</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="19" className="button-toggle">
                      <div className="version-header">
                        <span>0.23.1</span>
                        <span>23/11/2021</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="19">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                              <div />
                              <span className="bug">BUG FIXED</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>TADIG (IR.21 Template)</b>
                                  <p>- Aumento do número de dígitos nos campos MGT-CC e MGT-NC.</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="18" className="button-toggle">
                      <div className="version-header">
                        <span>0.23</span>
                        <span>11/11/2021</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="18">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                              <div />
                              <span className="new">NEW</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>SOR (Sixbell) - Análise de Log</b>
                                    <p className="mb-1">- MME </p>
                                    <p className="mb-1">- SGSN </p>
                                    <p className="mb-1">- VLR </p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
          </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="17" className="button-toggle">
                      <div className="version-header">
                      <span>0.22</span>
                      <span>04/11/2021</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="17">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                              <div />
                              <span className="new">NEW</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>GLR (Sixbell) - Análise de Log</b>
                                <p className="mb-1">- GLR</p>
                                <p className="mb-1">- GLR WL</p>
                              </p>
                          </div>
                          <div className="version-tag">
                              <div />
                              <span className="improvement">IMPROVEMENT</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Login/Recuperação de Senha.</b>
                                  <p>- Nova identificação visual para o sistema.</p>
                              </p>
                          </div>
                          <div className="version-tag">
                              <div />
                              <span className="bug">BUG FIXED</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>IR.21</b>
                                <p>- Correção do Download de Arquivos.</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
          </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="16" className="button-toggle">
                      <div className="version-header">
                      <span>0.21</span>
                      <span>22/10/2021</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="16">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                              <div />
                              <span className="new">NEW</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>TADIG</b> (IR.21 Template)
                                  <p>- O cadastro de TADIG foi melhorado para incluir/exibir as informações do IR.21.</p>
                              </p>
                          </div>
                          <div className="version-tag">
                              <div />
                              <span className="improvement">IMPROVEMENT</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Global Title</b>
                                  <p>- A funcionalidade é somente consulta. Foi removido a parte de inserção/edição de dados, os dados agora são inseridos pelo template do IR.21.</p>
                              </p>
                              <p> - <b>IR.21</b>
                                  <p>- Suporte ao upload dos arquivos nos formatos PDF e XML.</p>
                              </p>
                          </div>
                          <div className="version-tag">
                              <div />
                              <span className="bug">BUG FIXED</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Análise DRA</b>
                                  <p>- Correção do bug que sinaliza missing quando a informação do REALM não existe nos log e IR.21 para os parceiros com contrato ativo. O sistema vai considerar EQUAL.</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
          </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="15" className="button-toggle">
                      <div className="version-header">
                      <span>0.20.1</span>
                      <span>05/10/2021</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="15">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                              <div />
                              <span className="improvement">IMPROVEMENT</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Análise SGSN/MME</b>
                                  <p>- Melhoria na análise do log.</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="14" className="button-toggle">
                      <div className="version-header">
                      <span>0.20</span>
                      <span>05/10/2021</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="14">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                              <div />
                              <span className="new">NEW</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Alerta de Erros</b>
                                  <p className="mb-1">- Na barra verde superior do lado direito, foi inserido um ícone de sino para informar os alertas gerados pelo sistema.</p>
                                  <p className="mb-1">- Monitora os uploads de arquivos IR.21 e log dos NEs. Sinaliza quando ocorrer alguma falha ou envio de dados inválidos.</p>
                              </p>
                              <p> - <b>IR.21 - Suporte as Versão Mais Antigas</b>
                                  <p className="mb-1">- RAEX-IR.21 schema version 7.3.</p>
                                  <p className="mb-1">- RAEX-IR.21 schema version 8.2.</p>
                                  <p className="mb-1">- RAEX-IR.21 schema version 9.2.</p>
                                  <p className="mb-1">- RAEX-IR.21 schema version 10.1.</p>
                              </p>
                          </div>
                          <div className="version-tag">
                              <div />
                              <span className="improvement">IMPROVEMENT</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>IR.21 Upload</b>
                                <p className="mb-1">- Removido a necessidade de informar a versão do log na hora de carregar um arquivo manualmente.</p>
                              </p>
                              <p> - <b>Upload Log - Re-run.</b>
                                <p className="mb-1">- Incluído a funcionalidade de re-execução para o último log carregado na ferramenta. O ícone fica ao lado do ícone de download.</p>
                              </p>
                          </div>
                          <div className="version-tag">
                              <div />
                              <span className="bug">BUG FIXED</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Análise PTS</b>
                                  <p>- Correção do bug que sinaliza Address Message missing quando o mesmo existe no log.</p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
              </Accordion.Collapse>
          </Card>
            <Card>
              <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="13" className="button-toggle">
                      <div className="version-header">
                      <span>0.19</span>
                      <span>15/09/2021</span>
                      </div>
                  </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="13">
                  <Card.Body>
                      <div className="version-data">
                          <div className="version-tag">
                              <div />
                              <span className="new">NEW</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>IR.21 - Suporte Nova Versão </b>
                                  <p>- RAEX-IR.21 schema version 15.0.</p>
                              </p>
                          </div>
                          <div className="version-tag">
                              <div />
                              <span className="improvement">IMPROVEMENT</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Layout</b>
                                  <p>- <b>Reorganização do menu.</b>
                                      <p>- As funcionalidades de auditoria foram agrupadas por tópicos e mantidas no menu lateral.
                                        <p className="mb-1">- Home </p>
                                        <p className="mb-1">- IR.21 - funcionalidades referentes as informações contidas no IR.21. </p>
                                        <p className="mb-1">- Settings - Configuração da auditoria dos relatórios. </p>
                                        <p className="mb-1">- Report - Resultado da auditoria do Log X IR.21. </p>
                                        <p className="mb-1">- Upload Log - Envio dos logs que devem ser verificados. </p>
                                      </p>
                                      <p>- As funcionalidades de administração foram transportadas para o ícone na barra verde superior do lado direito.
                                        <p className="mb-1">- Gerenciamento de acesso do usuário (Profile). </p>
                                        <p className="mb-1">- Cadastro de usuários (Users). </p>
                                        <p className="mb-1">- Release Notes. </p>
                                        <p className="mb-1">- Logout. </p>
                                      </p>
                                  </p>
                              </p>
                          </div>
                          <div className="version-tag">
                              <div />
                              <span className="bug">BUG FIXED</span>
                              <div />
                          </div>
                          <div className="version-tag-content">
                              <p> - <b>Análise DRA</b>
                                  <p>- Correção do BUG nas análise dos parceiros com contrato VRS.</p>
                              </p>
                              <p> - <b>Contagem de Updates de IR.21</b>
                                  <p>- Padronização da totalização de atualizações na Home &gt; IR.21 Updates X IR.21 &gt; Documents &gt; Upload &gt; filtro por Release Date. </p>
                              </p>
                          </div>
                      </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="12" className="button-toggle">
                    <div className="version-header">
                      <span>0.18</span>
                      <span>08/09/2021</span>
                    </div>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="12">
                  <Card.Body>
                    <div className="version-data">
                      <div className="version-tag">
                        <div />
                        <span className="improvement">IMPROVEMENT</span>
                        <div />
                      </div>
                      <div className="version-tag-content">
                        <p>
                          - <b>Upload Logs</b>
                          <p className="mb-1">- Nova opção no menu lateral para melhorar a usabilidade.</p>
                          <p className="mb-1">- Exibe a listagem dos últimos uploads realizados, com as informações de cada upload. </p>
                          <p className="mb-1">- Removido do cadastro de elemento.</p>
                        </p>
                      </div>
                      <div className="version-tag">
                        <div />
                        <span className="bug">BUG FIXED</span>
                        <div />
                      </div>
                      <div className="version-tag-content">
                        <p>
                          - <b>ANALISE HLR</b>
                          <p className="mb-1">
                            - Correção da verificação do GT cadastrado como ROAMREST no template. Estava considerando
                            NO AGREEMENT, passa a ser considerado EQUAL.
                          </p>
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="" eventKey="11" className="button-toggle">
                        <div className="version-header">
                          <span>0.17</span>
                          <span>25/08/2021</span>
                        </div>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                    <Card.Body>
                        <div className="version-data">
                            <div className="version-tag">
                                <div />
                                  <span className="new">NEW</span>
                                <div />
                            </div>
                            <div className="version-tag-content">
                                <p> - <b>PTS Huawei - O relatório SCCPGT foi subdividido em grupos.</b> 
                                    <p className="mb-1">- K2R NNGT</p>
                                    <p className="mb-1">- NORMAL MGT</p>
                                    <p className="mb-1">- NORMAL NNGT</p>
                                    <p className="mb-1">- VRS MGT</p>
                                    <p className="mb-1">- VRS NNGT</p>
                                </p>
                            </div>
                            <div className="version-tag">
                                <div />
                                <span className="improvement">IMPROVEMENT</span>
                                <div />
                            </div>
                            <div className="version-tag-content">
                                <p> - <b>Dashboard</b> 
                                    <p>- Melhoria nos informativos.
                                        <p className="mb-1">- IR.21 Updates </p>
                                        <p className="mb-1">- Logs analysis </p>
                                    </p>
                                </p>
                                <p> - <b>Ignored Cases</b> 
                                    <p>- Incluído a funcionalidade para todos os relatórios DRA e PTS.</p>
                                </p>
                                <p> - <b>IR.21 Upload</b> 
                                    <p>- Incluído filtros de consulta individual por coluna.</p>
                                </p>
                                <p> - <b>Template - Incluído para DRA e PTS</b> 
                                    <p>- Default
                                        <p className="mb-1">- Configuração para Ignored Cases.</p>
                                        <p className="mb-1">- Configuração para IR.21.</p>
                                        <p className="mb-1">- Valores pré estabelecidos.</p>
                                    </p>
                                    <p>- Template
                                        <p className="mb-1">- Valores específicos para os relatórios.</p>
                                    </p>
                                </p>
                            </div>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="" eventKey="10" className="button-toggle">
                        <div className="version-header">
                        <span>0.16.1</span>
                        <span>12/08/2021</span>
                        </div>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                    <Card.Body>
                        <div className="version-data">
                            <div className="version-tag">
                                <div />
                                <span className="improvement">IMPROVEMENT</span>
                                <div />
                            </div>
                            <div className="version-tag-content">
                                <p> - <b>DRA Huawei</b> - Análise do Log
                                    <p>- Desconsiderado as análises dos dados nacionais.</p>
                                </p>
                            </div>
                            <div className="version-tag">
                                <div />
                                <span className="bug">BUG FIXED</span>
                                <div />
                            </div>
                            <div className="version-tag-content">
                                <p> - <b>PTS Huawei</b> - Análise de Log
                                    <p>- Correção na Comparação dos dados.</p>
                                </p>
                            </div>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="" eventKey="9" className="button-toggle">
                        <div className="version-header">
                        <span>0.16</span>
                        <span>09/08/2021</span>
                        </div>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                    <Card.Body>
                        <div className="version-data">
                            <div className="version-tag">
                                <div />
                                <span className="new">NEW</span>
                                <div />
                            </div>
                            <div className="version-tag-content">
                                <p> - <b>DRA Huawei</b> - Análise do Log
                                  <p className="mb-1">- DMRT</p>
                                  <p className="mb-1">- RTDHOST</p>
                                  <p className="mb-1">- RTOREALM</p>
                                  <p className="mb-1">- RTOHOST</p>
                                  <p className="mb-1">- RTIMSI</p>
                                  <p className="mb-1">- RTRESULT</p>
                                </p>
                            </div>
                            <div className="version-tag-content">
                                <p> - <b>PTS Huawei</b> - Análise do Log
                                  <p className="mb-1">- SCCPGT</p>
                                </p>
                            </div>
                            <div className="version-tag">
                                <div />
                                <span className="improvement">IMPROVEMENT</span>
                                <div />
                            </div>
                            <div className="version-tag-content">
                                <p> - <b>Dashboard</b>
                                    <p>- <b>Logs analyzed last 7 days</b>
                                        <p>- Alterado (Logs analyzed today) para exibir as informações dos últimos sete dias.</p>
                                    </p>
                                    <p>- <b>IR21s updated last 15 days</b>
                                        <p>- Alterado (IR21s updated this week) para exibir as informações dos últimos quinze dias.</p>
                                    </p>
                                </p>
                                <p> - <b>IR.21 Download</b>
                                  <p>- Download direto do RAEX, para os arquivos existente nele. Os arquivos adicionados na ferramenta manualmente continuarão no mesmo fluxo.</p>
                                </p>
                            </div>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="" eventKey="8" className="button-toggle">
                        <div className="version-header">
                          <span>0.15</span>
                          <span>29/07/2021</span>
                        </div>
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                    <Card.Body>
                        <div className="version-data">
                            <div className="version-tag">
                                <div />
                                <span className="improvement">IMPROVEMENT</span>
                                <div />
                            </div>
                            <div className="version-tag-content">
                                <p> - <b>ANALISE MSC</b>
                                    <p>- Melhoria na análise para identificar o nome do NE respeitando a seguintes entradas:
                                        <p className="mb-1">- eaw {"<NE>"}</p>  
                                        <p className="mb-1">- eaw {"<NE>"};</p>
                                        <p className="mb-1">- eaw NE={"<NE>"}</p>
                                        <p className="mb-1">- eaw NE={"<NE>"};</p>
                                    </p>
                                </p>
                                <p> - <b>CADASTRO DE NETWORK ELEMENTS</b>
                                    <p>- Melhoria para permitir excluir um NE que possua dados analisados.</p>
                                </p>
                                <p> - <b>IGNORED CASES BILATERAL</b>
                                    <p>- Incluído regra de exceção bilateral para ignorar a verificação de informação do log e do IR.21.
                                        <p className="mb-1">- EDNS </p>
                                        <p className="mb-1">- HSS </p>
                                        <p className="mb-1">- SGSN/MME </p>
                                    </p>
                                </p>
                                <p> - <b>Incluído a possibilidade de habilitar ou desabilitar a exceção, para todos os elementos, dos dados do LOG ou do IR.21, individualmente.</b>
                                    <p>- Caminho: Config Management &gt; Template &gt; [EDNS|HSS|SGSN/MME] &gt; [VENDOR] &gt; Default</p>
                                </p>
                                <p> - <b>IR.85</b>
                                    <p>- Corrigido a obtenção da informação do TADIG. Existe IR.85 sem a informação do campo AliasTADIGCode, quando identificado a ausência desta informação vai obter do campo TADIGCode.</p>
                                </p>
                            </div>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="7" className="button-toggle">
                    <div className="version-header">
                      <span>0.14</span>
                      <span>06/07/2021</span>
                    </div>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                  <Card.Body>
                    <div className="version-data">
                      <div className="version-tag">
                        <div />
                        <span className="new">NEW</span>
                        <div />
                      </div>
                      <div className="version-tag-content">
                        <p> - <b>IR.21 - Automatic Sync Setup</b>
                          <p>- Funcionalidade de upload automático dos arquivos de IR.21 diretamenta da API Roamsys.
                          </p>
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="6" className="button-toggle">
                    <div className="version-header">
                      <span>0.13</span>
                      <span>24/06/2021</span>
                    </div>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    <div className="version-data">
                      <div className="version-tag">
                        <div />
                        <span className="improvement">IMPROVEMENT</span>
                        <div />
                      </div>
                      <div className="version-tag-content">
                        <p> - <b>IGNORED CASES (MSC)</b>
                          <p> - Incluído regra de exceção bilateral, ignorar a informação dos dados IR.21 (Configurável). </p>
                          <p> - Configuration Management &gt; Template &gt; MSC &gt; Default
                            <p className="mb-1">- Incluído a possibilidade de habilitar ou desabilitar a exceção para todos os elementos.</p>
                            <p className="mb-1">- Incluído a possibilidade de habilitar ou desabilitar a exceção para informação do IR.21.</p>
                          </p>
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="5" className="button-toggle">
                    <div className="version-header">
                      <span>0.12</span>
                      <span>10/06/2021</span>
                    </div>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <div className="version-data">
                      <div className="version-tag">
                        <div />
                        <span className="improvement">IMPROVEMENT</span>
                        <div />
                      </div>
                      <div className="version-tag-content">
                        <p> - <b>IGNORED CASES</b> (Cadastro de exceção)
                          <p>- EDNS
                            <p className="mb-1">- 2G/3G </p>
                            <p className="mb-1">- 4G </p>
                          </p>
                          <p>- HSS
                            <p className="mb-1">- DIA CFG Routing Container </p>
                            <p className="mb-1">- ESM PLMN Container </p>
                          </p>
                          <p>- SGSN/MME
                            <p className="mb-1">- CONNECT PLMN </p>
                            <p className="mb-1">- IMSI GT </p>
                            <p className="mb-1">- IMSI HSS </p>
                          </p>
                        </p>
                      </div>
                      <div className="version-tag">
                        <div />
                        <span className="bug">BUG FIXED</span>
                        <div />
                      </div>
                      <div className="version-tag-content">
                        <p> - <b>HLR - Correção da análise.</b>
                          <p>- Correção na regra para os casos de testes.</p>
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="4" className="button-toggle">
                    <div className="version-header">
                      <span>0.11</span>
                      <span>27/05/2021</span>
                    </div>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <div className="version-data">
                      <div className="version-tag">
                        <div />
                        <span className="improvement">IMPROVEMENT</span>
                        <div />
                      </div>
                      <div className="version-tag-content">
                        <p> - <b>HLR - Análise do Log</b>
                          <p className="mb-1">- Melhoria da análise para sinalização de global title missing.</p>
                          <p className="mb-1">- Melhoria da análise para suportar os 2 tipos diferentes de logs que diferenciam no formato da exibição do nome do elemento.</p>
                        </p>
                        <p> - <b>IGNORED CASES (MSC)</b>
                          <p>- Incluído a possibilidade de adicionar a exceção para todos os elementos.</p>
                        </p>
                        <p> - <b>IR.21 - Upload</b>
                          <p>- Removido a necessidade de informar a operadora.</p>
                        </p>
                      </div>
                      <div className="version-tag">
                        <div />
                        <span className="bug">BUG FIXED</span>
                        <div />
                      </div>
                      <div className="version-tag-content">
                        <p> - <b>eDNS - Correção da análise.</b>
                          <p className="mb-1">- Correção da identificação dos TADIGs IR85.</p>
                          <p className="mb-1">- Correção da comparação do valor do REALM.</p>
                        </p>
                        <p> - <b>MSC &gt; CAMEL - Falha no uso da variável default.</b>
                          <p>- Correção da regra de usar primeiramente o valor do Sender Tadig.</p>
                        </p>
                        <p> - <b>MSC &gt; CAMEL - Falha na verificação de MISSING/TESTING.</b>
                          <p>- Correção da notificação de faltantes para casos de testes.</p>
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="3" className="button-toggle">
                    <div className="version-header">
                      <span>0.10</span>
                      <span>20/05/2021</span>
                    </div>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <div className="version-data">
                      <div className="version-tag">
                        <div />
                        <span className="new">NEW</span>
                        <div />
                      </div>
                      <div className="version-tag-content">
                        <p> - <b>Cadastros Ignore Cases para MSC</b> (Configuration Management &gt; Template &gt; MSC &gt; Ericsson &gt; Ignored Cases)
                          <p className="mb-1">- Este cadastro exibe os IMSIs que foram removidos da verificação pelo usuário.</p>
                          <p className="mb-1">- Para incluir um IMSI como ignorado, é feito diretamente no relatório de resultado.</p>
                        </p>
                        <p> - <b> Regra de verificação do CAMEL</b>
                          <p className="mb-1">- Novo plano de contingência para obter a informação do IR.21. Se o TADIG não possui a informação de CAMEL e este TADIG não é o TADIG principal da operadora, vai tentar obter o valor de CAMEL do TADIG principal, definido no IR.21 como Sender TADIG. Caso o Sender TADIG também não possua valor de CAMEL, vai utilizar o valor default cadastrado no template default.</p>
                          <p className="mb-1">OBS: Esta regra é mais uma opção de verificação que deve ser configurada no template default do NE.</p>
                        </p>
                      </div>
                      <div className="version-tag">
                        <div />
                        <span className="bug">BUG FIXED</span>
                        <div />
                      </div>
                      <div className="version-tag-content">
                        <p>- <b>Relatório de HSS</b> (DIA CFG ROUTING CONTAINER)
                          <p>- Alterado a exibição dos dados da coluna IMSI para REALM.</p>
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="2" className="button-toggle">
                    <div className="version-header">
                      <span>0.9</span>
                      <span>13/05/2021</span>
                    </div>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <div className="version-data">
                      <div className="version-tag">
                        <div />
                        <span className="new">NEW</span>
                        <div />
                      </div>
                      <div className="version-tag-content">
                        <p> - <b>Configuration Manager</b>
                          <p>- Templates Default: Define regras específicas para a análise dos dados e os valores dos campos estáticos que devem ser verificados. Campos estáticos são os campos que não são verificados com dados fornecidos pelo IR.21.
                            <p className="mb-1">- eDNS </p>
                            <p className="mb-1">- HLR </p>
                            <p className="mb-1">- HSS </p>
                            <p className="mb-1">- SGSN / MME </p>
                          </p>
                        </p>
                        <p> - <b>Release Notes</b>
                          <p>- Incluído informativo com o histórico dos upgrades da ferramenta.</p>
                        </p>
                      </div>
                      <div className="version-tag">
                        <div />
                        <span className="improvement">IMPROVEMENT</span>
                        <div />
                      </div>
                      <div className="version-tag-content">
                        <p>- <b>Configuration Manager</b>
                          <p>- Template &gt; MSC &gt; Ericsson &gt; Default
                            <p>- Incluído opção para escolha da aplicação da nova regra de não verificação dos dados do Log para os parceiros que ainda não possuem IR.21 cadastrados no sistema.</p>
                          </p>
                        </p>
                        <p>- <b>Verificação dos Logs</b>
                          <p>- Nova regra de não verificação dos dados do Log para os parceiros que ainda não possuem IR.21 cadastrados no sistema.</p>
                        </p>
                      </div>
                      <div className="version-tag">
                        <div />
                        <span className="bug">BUG FIXED</span>
                        <div />
                      </div>
                      <div className="version-tag-content">
                        <p>- <b>Correção da Análise do HLR</b>
                          <p>- Corrigido a indicação de erro de NA quando o parceiro está configurado como ROAMREST.</p>
                        </p>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="" eventKey="1" className="button-toggle">
                    <div className="version-header">
                      <span>0.8</span>
                      <span>06/05/2021</span>
                    </div>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ReleaseNotes;
