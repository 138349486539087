import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiFileText, FiFilter } from "react-icons/fi";
import { toast } from "react-toastify";
import { GlobalTitleModal } from "../../../../../components/GlobalTitle/GlobalTitleModal";
import { ErrorUnexpected } from "../../../../../const/Messages";
import * as GlobalTitleService from "../../../../../service/GlobalTitleService";

/**
  @author Guilherme Almeida
  @date 08/03/2021
 */
export default function GlobalTitleList() {
  // const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [globalTitle, setGlobalTitle] = useState([]);
  const [modalParams, setModalParams] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    GlobalTitleService.findAll()
      .then((globalTitleList) => {
        setGlobalTitle(
          globalTitleList.sort((a, b) => {
            if (a.plmn.name > b.plmn.name) {
              return 1;
            }
            if (a.plmn.name < b.plmn.name) {
              return -1;
            }
            return 0;
          })
        );
      })
      .catch(() => {
        toast.error(ErrorUnexpected);
      })
      .finally(() => setIsLoading(false));
  }, []); // eslint-disable-line

  function handleOpen(row) {
    const params = {
      cc: row.cc,
      ndc: row.ndc,
      rangeStart: row.rangeStart,
      rangeStop: row.rangeStop,
    };

    setModalParams(params);
    setShowModal(true);
  }

  function handleClose() {
    setShowModal(false);
  }
  // function handleDelete(id) {
  //   Swal.fire({
  //     icon: "warning",
  //     title: "Confirmation",
  //     text: "Are you sure you want to delete?",
  //     type: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#DD6B55",
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "No",
  //     closeOnConfirm: true,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       GlobalTitleService.deleteById(id)
  //         .then(() => {
  //           GlobalTitleService.findAll()
  //             .then((globalTitleList) => {
  //               setGlobalTitle(globalTitleList);
  //               toast.success(OperationSuccessfully);
  //             })
  //             .catch(() => {
  //               toast.error(ErrorUnexpected);
  //             });
  //         })
  //         .catch(() => {
  //           toast.error(ErrorUnexpected);
  //         });
  //     }
  //   });
  // }

  const columns = [
    {
      title: "TADIG",
      field: "plmn.name",
    },
    {
      title: "CC",
      field: "cc",
    },
    {
      title: "NDC",
      field: "ndc",
    },
    {
      title: "SN RANGE START",
      field: "rangeStart",
    },
    {
      title: "SN RANGE STOP",
      field: "rangeStop",
    },
  ];

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between align-items-center">
            <h5>Global Title - List</h5>
            <div>
              {/* <Link
                className="btn btn-primary"
                to={{
                  pathname: "/ir21/settings/global-title/reg",
                  state: { id: "" },
                }}
              >
                New Global Title
              </Link> */}
            </div>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={globalTitle}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              icons={{
                Filter: () => <FiFilter />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "globalTitle",
                filtering: true,
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
              actions={[
                // {
                //   icon: () => <FiEdit size={16} color="#007bff" />,
                //   tooltip: "EDIT",
                //   onClick: (event, rowData) => history.push("/ir21/settings/global-title/reg", { id: rowData.id }),
                // },
                // {
                //   icon: () => <FiTrash2 size={16} color="#007bff" />,
                //   tooltip: "DELETE",
                //   onClick: (event, rowData) => handleDelete(rowData.id),
                // },
                {
                  icon: () => <FiFileText size={16} color="#007bff" />,
                  tooltip: "VIEW GLOBAL TITLE PREFIX",
                  onClick: (event, rowData) => handleOpen(rowData),
                },
              ]}
            />
          </div>
        </div>
        <GlobalTitleModal
          isOpen={showModal}
          handleClose={handleClose}
          params={modalParams}
        />
      </Col>
    </Row>
  );
}
