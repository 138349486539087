import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { ErrorUnexpected, OperationSuccessfully } from "../../../../const/Messages";
import api from "../../../../service/api";

export default function VertisTrialSettings() {
  const { handleSubmit, values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      enabled: true,
      expirationDate: "2022-09-14T14:45",
      lastUpdate: "2022-09-14T14:45",
    },
    onSubmit: (values) => {
      api
        .put("trial", values)
        .then(() => {
          toast.success(OperationSuccessfully);
          fetchData();
        })
        .catch(() => toast.error(ErrorUnexpected));
    },
  });

  useEffect(() => {
    fetchData()
  }, []); // eslint-disable-line 


  function fetchData(){
    api.get("trial").then((response) => {
      setFieldValue("enabled", String(response.data.enabled));
      setFieldValue("expirationDate", moment(response.data.expirationDate).format("yyyy-MM-DDTHH:mm"));
      setFieldValue("lastUpdate", moment(response.data.lastUpdate).format("yyyy-MM-DDTHH:mm"));
    });
  }

  return (
    <Card>
      <CardHeader title="Trial Settings" />
      <Divider />
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>Enabled:</Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                  <FormControlLabel
                    name="enabled"
                    value="true"
                    checked={values.enabled === "true"}
                    onChange={handleChange}
                    control={<Radio color="primary" />}
                    label="TRUE"
                  />
                  <FormControlLabel
                    name="enabled"
                    value="false"
                    checked={values.enabled === "false"}
                    onChange={handleChange}
                    control={<Radio color="primary" />}
                    label="FALSE"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <Typography>Expiration Date:</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                variant="outlined"
                type="datetime-local"
                name="expirationDate"
                value={values.expirationDate}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography>Last Update:</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField variant="outlined" type="datetime-local" disabled value={values.lastUpdate} />
            </Grid>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" color="primary" type="submit">
                Update
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}
