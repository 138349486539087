import { Paper, TextField } from "@material-ui/core";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiCheck, FiFilter, FiX } from "react-icons/fi";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ErrorUnexpected } from "../../../../../const/Messages";
import * as ElementTypeService from "../../../../../service/ElementTypeService";
import * as ReportService from "../../../../../service/ReportService";
import * as VendorService from "../../../../../service/VendorService";
import "./ReportNetworkElementView.css";

/**
 * @author Thiago Guimarães
 * @date 19/02/2021
 */
function Report() {
  const [isLoading, setIsLoading] = useState(true);
  const [summaryList, setSummaryList] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [elementTypeList, setElementTypeList] = useState([]);
  let dateFilter = { start: "", end: "" };

  useEffect(() => {
    VendorService.findAll()
      .then((vendors) => setVendorList(vendors))
      .catch(() => toast.error(ErrorUnexpected));

    ElementTypeService.findAll()
      .then((elementTypes) => setElementTypeList(elementTypes))
      .catch(() => toast.error(ErrorUnexpected));

    ReportService.getNetworkElementReportSummary()
      .then((summaryList) => setSummaryList(summaryList))
      .catch(() => toast.error(ErrorUnexpected))
      .finally(() => setIsLoading(false));
  }, []); // eslint-disable-line

  const columns = [
    {
      title: "",
      field: "error",
      customSort: (obj1, obj2) => {
        if (obj1.error === obj2.error) {
          return 0;
        } else if (obj1.error) {
          return -1;
        } else {
          return 1;
        }
      },
      render: (obj) => {
        return obj.error ? <FiX color="red" /> : <FiCheck color="green" />;
      },
      width: "1%",
      lookup: { true: "With Errors", false: "No Errors" },
    },
    {
      title: "ELEMENT",
      field: "element",
      render: (obj) => {
        return (
          <>
            <Link
              className="text-primary font-weight-bold"
              to={`/report/element-view/detail/${obj.snapshotUploadElementId}`}
            >
              {obj.element}
            </Link>{" "}
            ({obj.commandName})
          </>
        );
      },
    },
    {
      title: "ELEMENT TYPE",
      field: "elementType",
      lookup: Object.fromEntries(
        elementTypeList.map((vendor) => [vendor.name, vendor.name])
      ),
    },
    {
      title: "VENDOR",
      field: "vendor",
      lookup: Object.fromEntries(
        vendorList.map((vendor) => [vendor.name, vendor.name])
      ),
    },
    {
      title: "DATE",
      field: "date",
      render: (obj) => <Moment format="DD/MM/yyyy HH:mm:ss">{obj.date}</Moment>,
      filterComponent: (props) => (
        <>
          <TextField
            {...props}
            label="START"
            type="date"
            value={dateFilter.start}
            className="mr-2"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              dateFilter = { ...dateFilter, start: event.target.value };
              props.onFilterChanged(props.columnDef.tableData.id, "FILTER");
            }}
          />
          <TextField
            {...props}
            label="END"
            type="date"
            value={dateFilter.end}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              dateFilter = { ...dateFilter, end: event.target.value };
              props.onFilterChanged(props.columnDef.tableData.id, "FILTER");
            }}
          />
        </>
      ),
      customFilterAndSearch: (term, row) => {
        const date = moment(row.date);

        if (term === "FILTER") {
          if (dateFilter && (dateFilter.start || dateFilter.end)) {
            if (dateFilter.start && dateFilter.end) {
              return (
                date.isBetween(dateFilter.start, dateFilter.end, "day") ||
                date.isSame(dateFilter.start, "day") ||
                date.isSame(dateFilter.end, "day")
              );
            } else if (dateFilter.start) {
              return date.isSameOrAfter(dateFilter.start, "day");
            } else if (dateFilter.end) {
              return date.isSameOrBefore(dateFilter.end, "day");
            }
          } else {
            return true;
          }
        } else {
          return date.format("DD/MM/yyyy HH:mm:ss").indexOf(term) !== -1;
        }
      },
      customSort: (obj1, obj2) => new Date(obj1.date) - new Date(obj2.date),
    },
  ];

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>Report Summary</h5>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={summaryList}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              icons={{
                Filter: () => <FiFilter />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "summary-list",
                search: false,
                filtering: true,
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Report;
