import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import {
  ErrorUnexpected,
  OperationSuccessfully,
} from "../../../../../../const/Messages";
import * as EricssonHssDefaultParametersService from "../../../../../../service/EricssonHssDefaultParametersService";
import Loading from "../../../../../Common/Loading/Loading";

const initialValues = {
  id: "",
  ignoredCasesLog: false,
  ignoredCasesIr21: false,
  hssEsm: "",
  diacfgroutingContainerOnlyCheckWithIR21: false,
  esmplmncontainerOnlyCheckWithIR21: false,
  configurationContainerOnlyCheckWithIR21: false,
};

/**
  @author Guilherme Almeida
  @date 12/05/2021
 */
export default function EricssonHssDefaultParameters() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    EricssonHssDefaultParametersService.getEricssonHssDefaultParameters()
      .then((defaultParameters) => {
        setValues(defaultParameters);
        setLoading(false);
      })
      .catch(() => {
        history.push("/", { message: ErrorUnexpected, messageType: "error" });
      });
  }, []); // eslint-disable-line

  const schema = Yup.object().shape({
    hssEsm: Yup.string().required(
      "Hss Esm Allowed Of Address is a required field"
    ),
  });

  function handleSave(values, actions) {
    EricssonHssDefaultParametersService.update(values)
      .then(() => {
        history.push("/settings/report/template/hss/ericsson/default", {
          message: OperationSuccessfully,
          messageType: "success",
        });
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          actions.setFieldError("hssEsm", ErrorUnexpected);
        } else if (error.response.data.title) {
          actions.setFieldError("hssEsm", error.response.data.title);
        }
        actions.setSubmitting(false);
      });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>Ericsson HSS Default Parameters</h5>
          </div>
          <div className="ibox-content">
            <Formik
              initialValues={values}
              validationSchema={schema}
              onSubmit={handleSave}
            >
              {() => (
                <Form>
                  <div className="font-weight-bold pt-2 pb-2">
                    <label>GENERAL</label>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="ignoredCases"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Ignored Cases:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <div className="d-flex align-items-baseline">
                        <Field type="checkbox" name="ignoredCasesLog" />
                        <label className="form-check-label pl-2">
                          Ignore value from LOG.
                        </label>
                        <span className="text-danger">
                          <ErrorMessage name="ignoredCasesLog" />
                        </span>
                      </div>
                      <div className="d-flex align-items-baseline">
                        <Field type="checkbox" name="ignoredCasesIr21" />
                        <label className="form-check-label pl-2">
                          Ignore value from IR21.
                        </label>
                        <span className="text-danger">
                          <ErrorMessage name="ignoredCasesIr21" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="hr-line-dashed"></div>
                  <div className="font-weight-bold pt-2 pb-2">
                    <label>Configuration Container</label>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="configurationContainerOnlyCheckWithIR21"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      IR.21:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field
                        type="checkbox"
                        name="configurationContainerOnlyCheckWithIR21"
                      />
                      <label className="form-check-label pl-2">
                        Check fields only if IR21 file exists.
                      </label>
                      <span className="text-danger">
                        <ErrorMessage name="configurationContainerOnlyCheckWithIR21" />
                      </span>
                    </div>
                  </div>
                  <div className="hr-line-dashed"></div>
                  <div className="font-weight-bold pt-2 pb-2">
                    <label>DIA CFG ROUTING CONTAINER</label>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="hssEsm"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      HSS ESM:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field
                        className="form-control"
                        name="hssEsm"
                        placeholder="HSS ESM"
                      />
                      <span className="text-danger">
                        <ErrorMessage name="hssEsm" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="diacfgroutingContainerOnlyCheckWithIR21"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      IR.21:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field
                        type="checkbox"
                        name="diacfgroutingContainerOnlyCheckWithIR21"
                      />
                      <label className="form-check-label pl-2">
                        Check fields only if IR21 file exists.
                      </label>
                      <span className="text-danger">
                        <ErrorMessage name="diacfgroutingContainerOnlyCheckWithIR21" />
                      </span>
                    </div>
                  </div>
                  <div className="hr-line-dashed"></div>
                  <div className="font-weight-bold pt-2 pb-2">
                    <label>ESM PLMN CONTAINER</label>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="esmplmncontainerOnlyCheckWithIR21"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      IR.21:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field
                        type="checkbox"
                        name="esmplmncontainerOnlyCheckWithIR21"
                      />
                      <label className="form-check-label pl-2">
                        Check fields only if IR21 file exists.
                      </label>
                      <span className="text-danger">
                        <ErrorMessage name="esmplmncontainerOnlyCheckWithIR21" />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Link className="mr-1 btn btn-primary" to="/settings">
                      Cancel
                    </Link>
                    <Button variant="primary" type="submit">
                      Update
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
}
