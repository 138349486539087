import React from "react";
import { makeStyles, Box, Typography, Divider, TableContainer, TableRow, TableCell,TableBody, Paper, TableHead } from "@material-ui/core";
import { useFormikContext } from "formik";
import { Table } from "react-bootstrap";

const useStyles = makeStyles((theme) => {
  return {
    title: {
      textTransform: "uppercase",
      fontStyle: "italic",
    },
    tableContainer: {
      margin: "1rem auto",
      width: "100%",
      overflowX: "inherit",
    },
    categoryBox: {
      "& + div": {
        marginTop: "2rem",
      },
    },
    verticalList: {
      "& span": {
        fontWeight: 500,
        display: "inline-block",
        marginRight: 5,
      },
    },
  };
});

function Ir21TemplateGeneral() {
  const { values } = useFormikContext();
  const classes = useStyles();

  return (
    <>
      <Box className={classes.categoryBox}>
        <Typography variant="h6" className={classes.title}>
          General
        </Typography>
        <Divider />
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell className={classes.verticalList}>
                  <span>TADIG</span>
                  {": " + values.plmnName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.verticalList}>
                  <span>Type</span>
                  {": " + (values.isIr85 ? "IR.85" : "IR.21")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.verticalList}>
                  <span>MCC</span>
                  {": " + (values.e212?.mcc || "")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.verticalList}>
                  <span>MNC</span>
                  {": " + (values.e212?.mnc || "")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.verticalList}>
                  <span>MGT CC</span>
                  {": " + (values.e214?.mgtcc || "")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.verticalList}>
                  <span>MGT NC</span>
                  {": " + (values.e214?.mgtnc || "")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box className={classes.categoryBox}>
        <Typography variant="h6" className={classes.title}>
          Map Version
        </Typography>
        <Divider />
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell className={classes.verticalList}>
                  <span>INBOUND</span>
                  {": " + (values.mapVersion?.inboundRoamingMscVlr || "")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.verticalList}>
                  <span>OUTBOUND</span>
                  {": " + (values.mapVersion?.outboundRoaming || "")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box className={classes.categoryBox}>
        <Typography variant="h6" className={classes.title}>
          APN Operator Identifiers
        </Typography>
        <Divider />
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table size="small">
            <TableBody>
              {values.apnOperatorIdentifierList?.map((apn) => (
                <TableRow>
                  <TableCell>{apn}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box className={classes.categoryBox}>
        <Typography variant="h6" className={classes.title}>
          Camel
        </Typography>
        <Divider />
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell className={classes.verticalList}>
                  <span>INBOUND</span>
                  {": " + values.camelCAPVerSuppMSCInboundList?.join(", ")}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.verticalList}>
                  <span>OUTBOUND</span>
                  {": " + values.camelCAPVerSuppMSCOutboundList?.join(", ")}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box className={classes.categoryBox}>
        <Typography variant="h6" className={classes.title}>
          Global Title
        </Typography>
        <Divider />
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>CC</TableCell>
                <TableCell>NDC</TableCell>
                <TableCell>Range Start</TableCell>
                <TableCell>Range Stop</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {values.e164NumberSeriesList?.map((globalTitle) => (
                <TableRow>
                  <TableCell>{globalTitle.cc}</TableCell>
                  <TableCell>{globalTitle.ndc}</TableCell>
                  <TableCell>{globalTitle.rangeStart}</TableCell>
                  <TableCell>{globalTitle.rangeStop}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box className={classes.categoryBox}>
        <Typography variant="h6" className={classes.title}>
          EPC Realms For Roaming
        </Typography>
        <Divider />
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table size="small">
            <TableBody>
              {values.epcRealmsForRoamingList?.map((realm) => (
                <TableRow>
                  <TableCell>{realm}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default Ir21TemplateGeneral;
