import api from "./api";

export function getHuaweiDraDefaultParameters() {
  return new Promise((resolve, reject) => {
    api
      .get("/huawei-dra-default-parameters")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/huawei-dra-default-parameters/${id}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function update(values) {
  return new Promise((resolve, reject) => {
    api({
      method: "put",
      url: `/huawei-dra-default-parameters`,
      data: values,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
