import { Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MaterialTable from "material-table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiArrowLeft, FiFilter } from "react-icons/fi";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ErrorUnexpected } from "../../../const/Messages";
import * as ReportService from "../../../service/ReportService";

/**
 * @author Thiago Guimarães
 * @date 16/04/2021
 */
function ReportErrorDetail({
  vendorId,
  elementTypeId,
  commandId,
  field,
  keyLabel,
  baseUrl,
  reportTitle,
}) {
  const history = useHistory();
  const [reportList, setReportList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let dateFilter = { start: "", end: "" };

  useEffect(() => {
    const param = {
      vendorId,
      elementTypeId,
      commandId,
      field,
    };

    ReportService.getReportByErrorDetailView(param)
      .then((reports) => setReportList(reports))
      .catch(() => {
        toast.error(ErrorUnexpected);
        history.push(baseUrl);
      })
      .finally(() => setIsLoading(false));
  }, [vendorId, elementTypeId, commandId, field]); // eslint-disable-line

  function handleGoBack() {
    history.push(baseUrl);
  }

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns([
      {
        title: "RESULT",
        field: "comparisonResult",
        render: (row) =>
          row.comparisonResult && row.comparisonResult.toUpperCase(),
      },
      { title: keyLabel, field: "key" },
      { title: "ELEMENT", field: "elementName" },
      { title: "TADIG", field: "plmnName" },
      {
        title: "LOG VALUE",
        field: "fieldValue",
        render: (obj) => {
          return <span style={{ whiteSpace: "pre" }}>{obj.fieldValue}</span>;
        },
      },
      {
        title: "TEMPLATE VALUE",
        field: "fieldRefValue",
        render: (obj) => {
          return <span style={{ whiteSpace: "pre" }}>{obj.fieldRefValue}</span>;
        },
      },
      {
        title: "DATE",
        field: "date",
        render: (obj) => (
          <Moment format="DD/MM/yyyy HH:mm:ss">{obj.date}</Moment>
        ),
        filterComponent: (props) => (
          <>
            <TextField
              {...props}
              label="START"
              type="date"
              value={dateFilter.start}
              className="mr-2"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                dateFilter = { ...dateFilter, start: event.target.value };
                props.onFilterChanged(props.columnDef.tableData.id, "FILTER");
              }}
            />
            <TextField
              {...props}
              label="END"
              type="date"
              value={dateFilter.end}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                dateFilter = { ...dateFilter, end: event.target.value };
                props.onFilterChanged(props.columnDef.tableData.id, "FILTER");
              }}
            />
          </>
        ),
        customFilterAndSearch: (term, row) => {
          const date = moment(row.date);

          if (term === "FILTER") {
            if (dateFilter && (dateFilter.start || dateFilter.end)) {
              if (dateFilter.start && dateFilter.end) {
                return (
                  date.isBetween(dateFilter.start, dateFilter.end, "day") ||
                  date.isSame(dateFilter.start, "day") ||
                  date.isSame(dateFilter.end, "day")
                );
              } else if (dateFilter.start) {
                return date.isSameOrAfter(dateFilter.start, "day");
              } else if (dateFilter.end) {
                return date.isSameOrBefore(dateFilter.end, "day");
              }
            } else {
              return true;
            }
          } else {
            return date.format("DD/MM/yyyy HH:mm:ss").indexOf(term) !== -1;
          }
        },
        customSort: (obj1, obj2) => new Date(obj1.date) - new Date(obj2.date),
      },
    ]);
  }, [keyLabel]);

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>
              <FiArrowLeft
                className="mr-2"
                size="22"
                color="gray"
                style={{ verticalAlign: "middle", cursor: "pointer" }}
                onClick={handleGoBack}
              />
              {reportTitle} - {field}
            </h5>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={reportList}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              icons={{
                Filter: () => <FiFilter />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: `${reportTitle} - ${field}`,
                search: false,
                filtering: true,
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ReportErrorDetail;
