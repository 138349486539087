import React from "react";
import MenuLeft from "./MenuLeft";
import HeaderBar from "./HeaderBar";
import Footer from "./Footer";
import { CssBaseline, makeStyles } from "@material-ui/core";
import { MenuProvider } from "../../context/MenuContext";

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: "flex",
      minWidth: 1000
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      marginTop: 30,
      marginBottom:20,
      flexGrow: 1,
      padding: theme.spacing(3),
      [theme.breakpoints.up("lg")]: {
        width: "calc(100vw - 340px)",
      },
      [theme.breakpoints.down("md")]: {
        width: "calc(100vw - 100px)",
      },
    },
  };
});

function Layout({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MenuProvider>
        <HeaderBar />
        <MenuLeft />
      </MenuProvider>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
      <Footer/>
    </div>
  );
}

export default Layout;