import api from "./api";

export function find() {
  return new Promise((resolve, reject) => {
    api
      .get("/ir21-automatic-setup")
      .then((response) => resolve(response.data))
      .catch((error) =>reject(error));
  });
}

export function merge(values) {
  return new Promise((resolve, reject) => {
    api
      .post("/ir21-automatic-setup", values)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
