import React, { useContext } from "react"
import { Route, Redirect } from "react-router-dom"
import { Context } from "../context/Context"
import LayoutIndex from "../components/Layout/LayoutIndex"

function PrivateRoute({ component: Component, roles, ...rest }) {
  const { signed, user } = useContext(Context)

  // Verifica se o usuario possui alguma ROLE, exigida para a rota.
  function isRoleAllowed(userRoles, routeRoles) {
    if (userRoles && routeRoles) {
      return userRoles.filter((role) => routeRoles.includes(role.name)).length > 0
    }

    return false
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!signed) {
          // Não logado
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        } else if (!roles) {
          // Rota sem restrições
          return <LayoutIndex key="main-layout"> <Component {...props} /> </LayoutIndex>
        } else if (isRoleAllowed(user.roles, roles)) {
          // Rota com restrição de roles
          return <LayoutIndex key="main-layout"> <Component {...props} /> </LayoutIndex>
        } else {
          // Se nenhum dos casos acima
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        }
      }}
    />
  )
}

export default PrivateRoute
