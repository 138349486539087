import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import api from "../../service/api";

export function TrialBar() {
  const [trialInfo, setTrialInfo] = useState(undefined);
  const [actualDate, setActualDate] = useState(new Date());

  useEffect(() => {
    api.get("trial").then((response) => setTrialInfo(response.data));
  }, []);

  useEffect(() => {
    if (trialInfo && trialInfo.enabled) {
      setTimeout(() => {
        setActualDate(new Date());
      }, 1000);
    }
  }, [actualDate, trialInfo]);

  if (!trialInfo || !trialInfo.enabled) {
    return null;
  }

  return (
    <Box style={{ background: "#ccc", color: "#368f54", textAlign: "center" }}>
      {moment(trialInfo.expirationDate).isBefore(moment()) ? (
        <Typography variant="h6">TRIAL VERSION - EXPIRED</Typography>
      ) : (
        <Typography variant="h6">
          TRIAL VERSION - REMAINING{" "}
          <Moment date={trialInfo.expirationDate} format="D [DAYS] hh:mm:ss" duration={moment(actualDate)} />
        </Typography>
      )}
    </Box>
  );
}
