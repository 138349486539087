import { makeStyles } from "@material-ui/core";

export const useStylesNavColapse = makeStyles(theme => ({
  collapseIcon: {
    fontSize: "1rem",
    marginTop: "auto",
    marginBottom: "auto",
  },
  menuIcon: {
    marginTop: "auto",
    marginBottom: "auto",
    color: "white",
    fontSize: 20,
  },
  listIcon: {
    minWidth: "18px",
    marginTop: "auto",
    marginBottom: "auto",
    color: "white"
  },
  listCustomIconSub: {
    width: "6px",
    height: "6px",
    color: "white"
  },
  listCustomIconSubActive: {
    width: "8px",
    height: "8px",
  },
  listItem: {
    // marginBottom: "5px",
    alignItems: "flex-start",
    color: "black",
  },
  listItemNoBack: {
    // marginBottom: "5px",
    // background:"#2b2b2b",
    backgroundColor: "transparent !important",
    paddingTop: "8px",
    paddingBottom: "8px",
    alignItems: "flex-start",
    color: "black",
    "&$selected": {
      backgroundColor: "red !important",
      "&:hover": {
        backgroundColor: "orange !important",
      },
    },
  },
  collapseWrapper: {
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      left: "20px",
      top: 0,
      height: "100%",
      width: "1px",
      opacity: 1,
      background: "white",
    },
  },
}));

export const useStylesNavItem = makeStyles(theme => ({
  listIcon: {
    minWidth: "18px",
    marginTop: "auto",
    marginBottom: "auto",
    color: "white"
  },
  menuIcon: {
    marginTop: "auto",
    marginBottom: "auto",
    color: "white",
    fontSize: 20,
  },
  listCustomIconSub: {
    width: "6px",
    height: "6px",
    color: "white"
  },
  listCustomIconSubActive: {
    width: "8px",
    height: "8px",
    color: "white"
  },
  listItem: {
    // marginBottom: "5px",
    alignItems: "center",
    color: "white",
  },
  listItemNoBack: {
    // marginBottom: "5px",
    backgroundColor: "transparent !important",
    paddingTop: "8px",
    paddingBottom: "8px",
    alignItems: "flex-start",
    color: "white",
    // background:"#2b2b2b",
  },
}));
