import React, { useContext, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button, Col, Row } from "react-bootstrap";
import { FiCheck, FiX, FiEyeOff, FiEye } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import * as Yup from "yup";
import { ErrorUnexpected, OperationCancelled, OperationSuccessfully } from "../../../const/Messages";
import { Context } from "../../../context/Context";
import * as UserService from "../../../service/UserService";

function ProfileChangePassword() {
  const { user } = useContext(Context);
  const history = useHistory();

  const [visibled, setVisibled] = useState({
    currentPassword: false,
    newPassword: false,
    newPasswordConfirmation: false,
  });
  const [passwordRules, setPasswordRules] = useState({
    size: false,
    lower: false,
    upper: false,
    special: false,
    number: false,
  });

  function updatePasswordRules(password) {
    setPasswordRules({
      size: password.length >= 12 && password.length <= 18, // if the password length is greater than 6 and less than 18
      lower: password.match("(?=.*[a-z])"), // if at least one lower case letter exists
      upper: password.match("[A-Z].*[A-Z]"), // if at least two upper case letter exists
      special: password.match("([!@#$%&*_])"), // if at least one non-word character exists
      number: password.match("\\d.*\\d"), // if at least two digit exists
    });
  }

  const schema = Yup.object().shape({
    currentPassword: Yup.string().required("Password is a required field"),
    newPassword: Yup.string().required("New password is a required field"),
    newPasswordConfirmation: Yup.string()
      .required("Confirm Password is a required field")
      .oneOf([Yup.ref("newPassword"), null], "The passwords don't match."),
  });

  function handleUpdate(values, actions) {
    UserService.updatePassword(user.id, values)
      .then((data) => {
        history.push("/", {
          message: OperationSuccessfully,
          messageType: "success",
        });
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          toast.error(ErrorUnexpected);
        } else if (error.response.data.fields) {
          const fields = error.response.data.fields;

          fields.forEach((field) => {
            actions.setFieldError(field.name, field.message);
          });
        } else if (error.response.data.title) {
          actions.setFieldError("currentPassword", error.response.data.title);
        } else {
          toast.error(ErrorUnexpected);
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>Profile - Change Password</h5>
          </div>
          <div className="ibox-content">
            <Formik
              initialValues={{
                currentPassword: "",
                newPassword: "",
                newPasswordConfirmation: "",
              }}
              validationSchema={schema}
              onSubmit={handleUpdate}
            >
              {({ setFieldValue, isSubmitting }) => (
                <Form>
                  <div className="form-group row">
                    <label htmlFor="name" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      Name:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <input type="text" className="form-control" value={user.name} disabled />
                      <span className="text-danger"></span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="currentPassword" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      Current Password:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <div className="input-group">
                        <Field
                          className="form-control"
                          name="currentPassword"
                          type={visibled.currentPassword ? "text" : "password"}
                          placeholder="Current Password"
                          autoComplete="current-password"
                        />
                        <div
                          className="input-group-append"
                          onClick={() =>
                            setVisibled({
                              ...visibled,
                              currentPassword: !visibled.currentPassword,
                            })
                          }
                        >
                          <span className="input-group-text">
                            {visibled.currentPassword ? <FiEyeOff /> : <FiEye />}
                          </span>
                        </div>
                      </div>
                      <span className="text-danger">
                        <ErrorMessage name="currentPassword"></ErrorMessage>
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="newPassword" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      New Password:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <div className="input-group">
                        <Field
                          className="form-control"
                          name="newPassword"
                          type={visibled.newPassword ? "text" : "password"}
                          placeholder="New Password"
                          autoComplete="new-password"
                          onChange={(ev) => {
                            setFieldValue("newPassword", ev.target.value);
                            updatePasswordRules(ev.target.value);
                          }}
                          data-tip
                          data-for="newPasswordTooltip"
                        />
                        <div
                          className="input-group-append"
                          onClick={() =>
                            setVisibled({
                              ...visibled,
                              newPassword: !visibled.newPassword,
                            })
                          }
                        >
                          <span className="input-group-text">{visibled.newPassword ? <FiEyeOff /> : <FiEye />}</span>
                        </div>
                      </div>
                      <span className="text-danger">
                        <ErrorMessage name="newPassword"></ErrorMessage>
                      </span>
                    </div>
                    <ReactTooltip id="newPasswordTooltip" type="dark">
                      <div className="">
                        <span>Your password must contain the following:</span>
                        <br />
                        <br />
                        <span>
                          {passwordRules.size ? <FiCheck color="green" /> : <FiX color="red" />} 12-18 chatacters
                        </span>
                        <br />
                        <span>
                          {passwordRules.lower ? <FiCheck color="green" /> : <FiX color="red" />} At least 1 lowercase
                          letter
                        </span>
                        <br />
                        <span>
                          {passwordRules.upper ? <FiCheck color="green" /> : <FiX color="red" />} At least 2 uppercase
                          letter
                        </span>
                        <br />
                        <span>
                          {passwordRules.special ? <FiCheck color="green" /> : <FiX color="red" />} At least 1 special
                          letter (!@#$%&*_){" "}
                        </span>
                        <br />
                        <span>
                          {passwordRules.number ? <FiCheck color="green" /> : <FiX color="red" />} At least 2 number
                        </span>
                        <br />
                      </div>
                    </ReactTooltip>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="newPasswordConfirmation" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      Confirm New Password :
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <div className="input-group">
                        <Field
                          className="form-control"
                          name="newPasswordConfirmation"
                          placeholder="Confirm New Password"
                          autoComplete="new-password"
                          type={visibled.newPasswordConfirmation ? "text" : "password"}
                        />
                        <div
                          className="input-group-append"
                          onClick={() =>
                            setVisibled({
                              ...visibled,
                              newPasswordConfirmation: !visibled.newPasswordConfirmation,
                            })
                          }
                        >
                          <span className="input-group-text">
                            {visibled.newPasswordConfirmation ? <FiEyeOff /> : <FiEye />}
                          </span>
                        </div>
                      </div>
                      <span className="text-danger">
                        <ErrorMessage name="newPasswordConfirmation"></ErrorMessage>
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                      <Link
                        to={{
                          pathname: "/profile",
                          state: {
                            message: OperationCancelled,
                            messageType: "info",
                          },
                        }}
                        className="btn btn-primary mr-1"
                      >
                        Cancel
                      </Link>
                      <Button variant="primary" type="submit" disabled={isSubmitting}>
                        Update
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ProfileChangePassword;
