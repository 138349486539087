import { Typography } from "@material-ui/core";

export function CategoryTitle({title}){ 
return (
  <Typography
    variant="body2"
    color="primary"
    style={{
      mb: 1,
      display: "block",
      fontSize: "16px",
      marginTop: "8px",
      fontWeight: 900,
      lineHeight: "28px",
      paddingLeft: 0,
      textTransform: "uppercase",
    }}
  >
    {title}
  </Typography>
);
}