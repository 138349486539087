import React from "react";
import { Modal } from "react-bootstrap";

/**
* @author Guilherme Almeida
* @date 04/06/2021
*/
function IgnoredCasesModal({ showModal, setShowModal, data }) {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{data.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
      <table className="table table-striped">
          <thead>
            <tr>
              <th>Field Name</th>
              <th className="text-center">Value</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.fields &&
              data.fields.map((fieldName, indexKey) => (
                <tr key={indexKey}>
                  <td>{fieldName}</td>
                  <td className="text-center">{(data.values[indexKey] && data.values[indexKey] !== "null") ? data.values[indexKey] : "-"}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Modal.Body>
    </Modal>
  );
}

export default IgnoredCasesModal;
