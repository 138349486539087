import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  container: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(20px + 2vmin)",
    color: "#fff",
  },
  text: {
    fontWeight: 600,
    color: "#009EE2",
    animation: "App-logo-spin infinite 1s linear alternate",
  },
});
function Loading() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span className={classes.text}>Roaming Audit Tool - International</span>
    </div>
  );
}

export default Loading;
