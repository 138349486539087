import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { AssociationList } from "../../../../../../components/Tadig/Association/AssociationList";
import { ErrorUnexpected } from "../../../../../../const/Messages";
import api from "../../../../../../service/api";

export default function TadigAssociationRegister() {
  const history = useHistory();
  const location = useLocation();
  const [parentId, setParentId] = useState();
  const [plmnList, setPlmnList] = useState([]);

  const [associatedList, setAssociatedList] = useState([]);
  const [disassociatedList, setDisassociateList] = useState([]);

  useEffect(() => {
    api
      .get("/plmns")
      .then((response) => {
        setPlmnList(response.data);
        setDisassociateList(response.data);
      })
      .catch(() => toast.error(ErrorUnexpected))
      .finally(() => {
        if (location && location.state && location.state.id) {
          setParentId(location.state.id);
        }else{
          setParentId();
        }
      });
  }, []);

  useEffect(() => {
    if (parentId) {
      api
        .get(`/plmns/association/${parentId}/childs`)
        .then((response) => {
          setAssociatedList(response.data);
          setDisassociateList(
            plmnList.filter(
              (plmn) => response.data.every((associated) => plmn.id !== associated.id) && plmn.id !== parentId
            )
          );
        })
        .catch(() => toast.error(ErrorUnexpected));
    }
  }, [parentId]);

  function handleGoBack() {
    history.push("/ir21/settings/tadigs/association");
  }

  function associate(child) {
    api
      .put(`/plmns/association/${parentId}/${child.id}`)
      .then(() => {
        setAssociatedList((current) => [...current, child]);
        setDisassociateList((current) => current.filter((plmn) => plmn.id !== child.id));
      })
      .catch((error) => toast.error(error.response.data.title));
  }

  function disassociate(child) {
    api
      .delete(`/plmns/association/${parentId}/${child.id}`)
      .then(() => {
        setDisassociateList((current) => [...current, child]);
        setAssociatedList((current) => current.filter((plmn) => plmn.id !== child.id));
      })
      .catch((error) => toast.error(error.response.data.title));
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>
              <FiArrowLeft
                className="mr-2"
                size="22"
                color="gray"
                style={{ verticalAlign: "middle", cursor: "pointer" }}
                onClick={handleGoBack}
              />
              Tadig Association - Register
            </h5>
          </div>
          <div className="ibox-content">
            <div className="form">
              <div className="form-group row">
                <label htmlFor="organisation" className="col-form-label col-sm-12">
                  Tadig:
                </label>
                <div className="col-sm-12">
                  <Select
                    placeholder="-- Select Option --"
                    styles={{
                      placeholder: (defaultStyle) => {
                        return { ...defaultStyle, color: "#495057" };
                      },
                    }}
                    options={plmnList.map((plmn) => {
                      return { value: plmn.id, label: plmn.name };
                    })}
                    name="plmn"
                    value={plmnList
                      .map((plmn) => {
                        return { value: plmn.id, label: plmn.name };
                      })
                      .find((plmn) => plmn.value === parentId)}
                    onChange={(item) => {
                      setParentId(item.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="organisation" className="col-form-label col-sm-12">
                  Association List:
                </label>
                <div className="col-sm-12">
                  {parentId && (
                    <AssociationList
                      associatedList={associatedList}
                      disassociatedList={disassociatedList}
                      associate={associate}
                      disassociate={disassociate}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
