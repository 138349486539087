import React from "react";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select as MaterialUiSelect,
  TextField,
} from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import * as Yup from "yup";

export const generalInitialValues = {
  plmnId: "",
  isIr85: false,
  e212: {
    mcc: "",
    mnc: "",
  },
  e214: {
    mgtcc: "",
    mgtnc: "",
  },
};

export const generalSchema = Yup.object().shape({
  plmnId: Yup.string().required().label("Tadig"),
  isIr85: Yup.string().required().label("Type"),
  e212: Yup.object().shape({
    mcc: Yup.string()
      .required()
      .max(3)
      .test(
        "invalid",
        "MCC must be a valid value, between 0 and 999",
        (value) => Number.isInteger(Number(value)) && Number(value) >= 0 && Number(value) <= 999
      )
      .label("MCC"),
    mnc: Yup.string()
      .required()
      .max(3)
      .test(
        "invalid",
        "MNC must be a valid value, between 0 and 999",
        (value) => Number.isInteger(Number(value)) && Number(value) >= 0 && Number(value) <= 999
      )
      .label("MNC"),
  }),
  e214: Yup.object().shape({
    mgtcc: Yup.string()
      .required()
      .max(7)
      .test(
        "invalid",
        "MGT CC must be a valid value, between 0 and 9999999",
        (value) => Number.isInteger(Number(value)) && Number(value) >= 0 && Number(value) <= 9999999
      )
      .label("MGT CC"),
    mgtnc: Yup.string()
      .required()
      .max(7)
      .test(
        "invalid",
        "MGT NC must be a valid value, between 0 and 9999999",
        (value) => Number.isInteger(Number(value)) && Number(value) >= 0 && Number(value) <= 9999999
      )
      .label("MGT NC"),
  }),
});

const useStyles = makeStyles({
  input: {
    "& + div": {
      marginTop: 15,
    },
  },
});

function Ir21TemplateGeneral() {
  const { errors, values } = useFormikContext();
  const classes = useStyles();

  return (
    <>
      <TextField
        label="TADIG"
        defaultValue=" "
        value={values.plmnName}
        disabled
        className={classes.input}
        variant="outlined"
      />
      <FormControl variant="outlined" className={classes.input}>
        <InputLabel id="input_type">TYPE</InputLabel>
        <Field as={MaterialUiSelect} labelId="input_type" name="isIr85" label="TYPE">
          <MenuItem value={false}>IR.21</MenuItem>
          <MenuItem value={true}>IR.85</MenuItem>
        </Field>
      </FormControl>
      <Field
        as={TextField}
        className={classes.input}
        label="MCC"
        name="e212.mcc"
        variant="outlined"
        error={!!errors?.e212?.mcc}
        helperText={errors?.e212?.mcc}
      />
      <Field
        as={TextField}
        className={classes.input}
        label="MNC"
        name="e212.mnc"
        variant="outlined"
        error={!!errors?.e212?.mnc}
        helperText={errors?.e212?.mnc}
      />
      <Field
        as={TextField}
        className={classes.input}
        label="MGT CC"
        name="e214.mgtcc"
        variant="outlined"
        error={!!errors?.e214?.mgtcc}
        helperText={errors?.e214?.mgtcc}
      />
      <Field
        as={TextField}
        className={classes.input}
        label="MGT NC"
        name="e214.mgtnc"
        variant="outlined"
        error={!!errors?.e214?.mgtnc}
        helperText={errors?.e214?.mgtnc}
      />
    </>
  );
}

export default Ir21TemplateGeneral;
