import { CircularProgress } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  ErrorUnexpected,
  OperationSuccessfully,
} from "../../../../../../const/Messages";
import * as OperatorPartnersUploadService from "../../../../../../service/OperatorPartnerUploadService";
import "./RoamingPartnersUploadUploadModal";

const initialValues = {
  elementType: "",
  vendor: "",
};

/**
 * Criação:
 * @author Guilherme Almeida
 * @date 26/06/2024
 */
export default function RoamingPartnersUploadUploadModal({ show, setShow }) {
  const SUPPORTED_FORMATS = [
    ".xls",
    ".xlsx",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const formikRef = useRef(null);

  const schema = Yup.object().shape({
    file: Yup.mixed()
      .required("A file is required")
      .test(
        "fileFormat",
        "Unsupported Format",
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
  });

  function handleCloseModal() {
    setShow(false);
  }

  function handleUpload(values, actions) {
    const formData = new FormData();
    formData.append("file", values.file);

    OperatorPartnersUploadService.upload(formData)
      .then(() => {
        toast.success(OperationSuccessfully);
        handleCloseModal(true);
      })
      .catch((error) => toast.error(ErrorUnexpected))
      .finally(() => actions.setSubmitting(false));
  }

  return (
    <Modal show={show} onHide={handleCloseModal} size="lg" animation={false}>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        validationSchema={schema}
        onSubmit={handleUpload}
      >
        {({ values, setFieldValue, isSubmitting, errors }) => (
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Roaming Partners Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
              <div>
                <p>
                  O upload irá atualizar a base de dados do sistema e esta ação
                  não poderá ser desfeita. As seguintes regras serão aplicadas:
                </p>
                <ol>
                  <li>
                    Todos os registros com "Status do acordo" = "Vigente" e
                    "PMNCODE-TIM" = "ORIGINAL" serão cadastrados;
                    <ul>
                      <li>
                        Exceção quando não houver o TADIG ("PMNCODE")
                        previamente cadastrado no sistema. Para este caso, o
                        sistema irá informar no alerta quais os TADIGs que não
                        foram cadastrados.
                      </li>
                    </ul>
                  </li>

                  <li>
                    Todos os TADIGs que não respeitarem a regra 1 serão
                    removidos do sistema;
                    <ul>
                      <li>
                        Exceção para os acordos cadastrados no sistema como em
                        fase de testes, estes serão mantidos, ou seja, para os
                        acordos "TESTING" ou "INBOUND OUT TESTING" = "OUTBOUND
                        IN TESTING", respeitando a regra a seguir:
                        <ul>
                          <li>
                            Se cadastro VRAT = TESTING e planilha de acordos =
                            EMPTY -&gt; mantém TESTING.
                          </li>
                          <li>
                            Se cadastro VRAT = TESTING e planilha de acordos =
                            OUT -&gt; cadastra OUTBOUND IN TESTING.
                          </li>
                          <li>
                            Se cadastro VRAT = TESTING e planilha de acordos =
                            IN -&gt; cadastra INBOUND OUT TESTING.
                          </li>
                          <li>
                            Se cadastro VRAT = TESTING e planilha de acordos =
                            IN/OUT -&gt; cadastra BILATERAL .
                          </li>
                          <li>
                            Se cadastro VRAT = OUTBOUND IN TESTING e planilha de
                            acordos = empty -&gt; cadastra TESTING.
                          </li>
                          <li>
                            Se cadastro VRAT = OUTBOUND IN TESTING e planilha de
                            acordos = OUT -&gt; mantém OUTBOUND IN TESTING.
                          </li>
                          <li>
                            Se cadastro VRAT = OUTBOUND IN TESTING e planilha de
                            acordos = IN -&gt; cadastra INBOUND OUT TESTING.
                          </li>
                          <li>
                            Se cadastro VRAT = OUTBOUND IN TESTING e planilha de
                            acordos = IN/OUT -&gt; cadastra BILATERAL.
                          </li>
                          <li>
                            Se cadastro VRAT = INBOUND OUT TESTING e planilha de
                            acordos = empty -&gt; cadastra TESTING.
                          </li>
                          <li>
                            Se cadastro VRAT = INBOUND OUT TESTING e planilha de
                            acordos = OUT -&gt; cadastra OUTBOUND IN TESTING.
                          </li>
                          <li>
                            Se cadastro VRAT = INBOUND OUT TESTING e planilha de
                            acordos = IN -&gt; mantém INBOUND OUT TESTING.
                          </li>
                          <li>
                            Se cadastro VRAT = INBOUND OUT TESTING e planilha de
                            acordos = IN/OUT -&gt; cadastra BILATERAL.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
              <div className="form-group row">
                <label htmlFor="file" className="col-sm-1 col-form-label">
                  File:
                </label>
                <div className="custom-file col-sm-11">
                  <input
                    disabled={isSubmitting}
                    name="file"
                    type="file"
                    className="custom-file-input form-control"
                    onChange={(ev) => {
                      setFieldValue("file", ev.target.files[0]);
                    }}
                    accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  />
                  <label
                    className="custom-file-label form-control"
                    style={{ position: "relative", top: -40 }}
                    htmlFor="file"
                  >
                    {values.file ? values.file.name : "Choose file"}
                  </label>
                  <span
                    className="text-danger"
                    style={{ position: "relative", top: -40 }}
                  >
                    {errors.file && errors.file}
                  </span>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
              {isSubmitting && (
                <div className="d-flex">
                  <CircularProgress disableShrink size={16} />
                  <p className="ml-1">
                    Please wait while the file is loading. This may take a few
                    moments.
                  </p>
                </div>
              )}

              <div className="mt-3 mr-4 ml-auto d-flex justify-content-end">
                <Button
                  className="mr-1"
                  onClick={handleCloseModal}
                  variant="primary"
                  type="button"
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Upload
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
