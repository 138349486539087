import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Link,
  Popover,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useContext, useRef, useState } from "react";
import { IoPersonCircle } from "react-icons/io5";
import { Link as RouterLink } from "react-router-dom";
import { ADMIN, VERTIS } from "../../const/Roles";
import { Context } from "../../context/Context";
import RoleControl from "../security/RoleControl";

const useStyles = makeStyles({
  text: {
    fontSize: "12px",
  },
  button: {
    backgroundColor: "#009EE2",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#009EE2",
    },
  },
  link: {
    color: "#009EE2",
  },
});

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { user, handleLogout } = useContext(Context);

  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton className={classes.icon} ref={anchorRef} onClick={handleOpen}>
        <IoPersonCircle size={33} color="white" />
      </IconButton>

      <Popover
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        style={{ zIndex: 3001, marginLeft: "-3rem" }}
      >
        <Container style={{ width: 240 }}>
          <Box mt={2}>
            <Typography
              variant="h6"
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {user.name &&
                user.name
                  .split(" ")
                  .filter(
                    (content, index) =>
                      index === 0 || index === user.name.split(" ").length - 1
                  )
                  .join(" ")}
            </Typography>
            <Typography variant="body2">{user.email}</Typography>

            <Box mt={1} mb={1}>
              <Divider variant="middle" />
            </Box>

            <Button
              className={classes.button}
              fullWidth
              variant="outlined"
              to="/profile"
              component={RouterLink}
              onClick={handleClose}
            >
              Profile
            </Button>
            <Box mt={1}>
              <RoleControl roles={[ADMIN, VERTIS]}>
                <Button
                  className={classes.button}
                  fullWidth
                  variant="outlined"
                  to="/users"
                  component={RouterLink}
                  onClick={handleClose}
                >
                  Users
                </Button>
              </RoleControl>
            </Box>
          </Box>
          <Box mt={1} mb={1}>
            <Divider variant="middle" />
          </Box>
          <Button
            className={classes.button}
            fullWidth
            variant="outlined"
            onClick={handleLogout}
          >
            Logout
          </Button>
          <Box mt={1} mb={1}>
            <Divider variant="middle" />
          </Box>
          <Box>
            <Typography align="center">
              <Link
                component={RouterLink}
                className={classes.text}
                key="release"
                color="initial"
                underline="none"
                to="/release"
                onClick={handleClose}
              >
                • RELEASE NOTES •
              </Link>
            </Typography>
          </Box>
        </Container>
      </Popover>
    </>
  );
}
