import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as ElementService from "../../../../../service/ElementService";
import * as ElementTypeService from "../../../../../service/ElementTypeService";
import * as VendorService from "../../../../../service/VendorService";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loading from "../../../../Common/Loading/Loading";
import {
  ErrorUnexpected,
  OperationSuccessfully,
} from "../../../../../const/Messages";
import { Context } from "../../../../../context/Context";
import { ADMIN } from "../../../../../const/Roles";

const initialValues = {
  name: "",
  elementType: {
    id: "",
  },
  vendor: {
    id: "",
  },
  active: "",
};

/**
 * @author Thiago Guimarães
 * @date 08/02/2021
 */
function ElementRegister() {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(initialValues);
  const [vendorList, setVendorList] = useState([]);
  const [elementTypeList, setElementTypeList] = useState([]);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location && location.state && location.state.id) {
      ElementService.getById(location.state.id)
        .then((element) => {
          VendorService.findAll().then((vendors) => {
            setVendorList(vendors);
            updateElementTypeCombo(element.vendor.id).then(() => {
              setValues(element);
              setLoading(false);
            });
          });
        })
        .catch((error) => {
          history.push("/settings/network-element", {
            message: ErrorUnexpected,
            messageType: "error",
          });
        });
    } else {
      VendorService.findAll()
        .then((vendors) => {
          setVendorList(vendors);
        })
        .catch((error) => {
          toast.error(ErrorUnexpected);
        });
      setLoading(false);
    }
  }, []); // eslint-disable-line

  function updateElementTypeCombo(vendorId) {
    return new Promise((resolve, reject) => {
      ElementTypeService.findByVendorId(vendorId)
        .then((elementTypes) => {
          setElementTypeList(elementTypes);
          resolve();
        })
        .catch((error) => {
          toast.error(ErrorUnexpected);
          reject(error);
        });
    });
  }

  const schema = Yup.object().shape({
    name: Yup.string().required("Element name is a required field"),
    elementType: Yup.object().shape({
      id: Yup.number().required("Element Type is a required field"),
    }),
    vendor: Yup.object().shape({
      id: Yup.number().required("Vendor is a required field"),
    }),
    active: Yup.string().required("Active is a required field"),
  });

  function handleSave(values, actions) {
    let id = null;

    if (location && location.state && location.state.id) {
      id = location.state.id;
    }

    ElementService.merge(id, values)
      .then((response) => {
        history.push("/settings/network-element/detail/", {
          elementTypeId: values.elementType.id,
          vendorId: values.vendor.id,
          message: OperationSuccessfully,
          messageType: "success",
        });
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          toast.error(ErrorUnexpected);
        } else if (error.response.data.fields) {
          error.response.data.fields.forEach((field) => {
            actions.setFieldError(field.name, field.message);
          });
        } else if (error.response.data.title) {
          actions.setFieldError("name", error.response.data.title);
        } else {
          toast.error(ErrorUnexpected);
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  }

  function handleDelete(element) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete? This will permanently delete the element data in the system.",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ElementService.deleteById(element.id).then(() => {
          history.push("/settings/network-element/detail/", {
            elementTypeId: element.elementType.id,
            vendorId: element.vendor.id,
            message: OperationSuccessfully,
            messageType: "success",
          });
        });
      }
    });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>Network Element - Register</h5>
          </div>
          <div className="ibox-content">
            <Formik
              initialValues={values}
              validationSchema={schema}
              onSubmit={handleSave}
            >
              {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                  <div className="form-group row">
                    <label
                      htmlFor="vendor"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Vendor:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field
                        as="select"
                        name="vendor"
                        className="form-control"
                        value={values.vendor.id}
                        onChange={(ev) => {
                          setFieldValue("vendor.id", ev.target.value);
                          updateElementTypeCombo(ev.target.value);
                        }}
                      >
                        <option value="">-- Select Option --</option>
                        {vendorList.map((vendor) => (
                          <option value={vendor.id} key={vendor.id}>
                            {vendor.name}
                          </option>
                        ))}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="vendor.id" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="elementType"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Element Type:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field
                        as="select"
                        name="elementType"
                        className="form-control"
                        value={values.elementType.id}
                        onChange={(ev) => {
                          setFieldValue("elementType.id", ev.target.value);
                        }}
                      >
                        <option value="">-- Select Option --</option>
                        {elementTypeList.map((elementType) => (
                          <option value={elementType.id} key={elementType.id}>
                            {elementType.name}
                          </option>
                        ))}
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="elementType.id" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="elementType"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Name:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field name="name" className="form-control"></Field>
                      <span className="text-danger">
                        <ErrorMessage name="name" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="active"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Active:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field
                        as="select"
                        name="active"
                        className="form-control"
                      >
                        <option value="">-- Select Option --</option>
                        <option value="true">TRUE</option>
                        <option value="false">FALSE</option>
                      </Field>
                      <span className="text-danger">
                        <ErrorMessage name="active" />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Link className="mr-1 btn btn-primary" to="/settings/network-element">
                      Cancel
                    </Link>
                    {location &&
                      location.state &&
                      location.state.id &&
                      user.roles.map((role) => role.name).includes(ADMIN) && (
                        <Button
                          className="mr-1"
                          onClick={() => handleDelete(values)}
                          variant="primary"
                          type="button"
                        >
                          Delete
                        </Button>
                      )}
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {location && location.state && location.state.id
                        ? "Update"
                        : "Save"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ElementRegister;
