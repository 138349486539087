import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { FiCheck, FiFilter, FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ErrorUnexpected } from "../../../../../const/Messages";
import * as ReportService from "../../../../../service/ReportService";

/**
 * @author Thiago Guimarães
 * @date 30/03/2021
 */
function TadigSummaryView() {
  const [isLoading, setIsLoading] = useState(true);
  const [summaryList, setSummaryList] = useState([]);

  useEffect(() => {
    ReportService.getReportTadig()
      .then((list) => setSummaryList(list))
      .catch((error) => toast.error(ErrorUnexpected))
      .finally(() => setIsLoading(false));
  }, []); // eslint-disable-line

  const columns = [
    {
      title: "",
      field: "error",
      render: (row) =>
        row.error ? <FiX color="red" /> : <FiCheck color="green" />,
      width: "1%",
      lookup: { true: "With Errors", false: "No Errors" },
    },
    {
      title: "TADIG",
      field: "plmnName",
      render: (row) => (
        <Link
          to={{
            pathname: "/report/tadig-view/detail",
            state: { plmnId: row.plmnId },
          }}
        >
          {row.plmnName}
        </Link>
      ),
    },
    { title: "ORGANISATION", field: "organisationName" },
    { title: "COUNTRY", field: "countryName" },
  ];

  return (
    <div className="ibox">
      <div className="ibox-title">
        <h5>Report - TADIG</h5>
      </div>
      <div className="ibox-content">
        <div>
          <MaterialTable
            title=""
            columns={columns}
            data={summaryList}
            isLoading={isLoading}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
            icons={{
              Filter: () => <FiFilter />,
            }}
            options={{
              rowStyle: {
                fontSize: 13,
              },
              pageSize: 10,
              pageSizeOptions: [5, 10, 15, 20],
              actionsColumnIndex: -1,
              exportButton: true,
              exportAllData: true,
              searchFieldAlignment: "left",
              toolbarButtonAlignment: "right",
              exportFileName: "summary-list",
              search: false,
              filtering: true,
              headerStyle: {
                textTransform: "uppercase",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default TadigSummaryView;
