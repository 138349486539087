import React, { useEffect, useState } from "react";
import Loading from "../../Common/Loading/Loading";
import LoginLayout from "../../../components/LoginLayout/LoginLayout";
import ReactTooltip from "react-tooltip";
import { Card, CardContent, CardHeader, makeStyles } from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { FiArrowLeft, FiCheck, FiEye, FiEyeOff, FiLock, FiX } from "react-icons/fi";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ErrorUnexpected } from "../../../const/Messages";
import * as Yup from "yup";
import * as AuthenticationService from "../../../service/AuthenticationService";


const useStyles = makeStyles((theme) => {
  return {
    box: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    card: {
      maxWidth: 600,
      maxHeight: 525,
      width: "96%",
      height: "100%",
    },
    cardContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",

      "& form": {
        width: "100%",
      },
    },
  };
});
function ChangePassword() {
  const classes = useStyles();

  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  const [loading, setLoading] = useState(true);
  const [visibled, setVisibled] = useState({
    password: false,
    confirmPassword: false,
  });
  const [passwordRules, setPasswordRules] = useState({
    size: false,
    lower: false,
    upper: false,
    special: false,
    number: false,
  });

  useEffect(() => {
    const token = query.get("t");

    AuthenticationService.validRecoveryToken(token)
      .then((response) => {
        setLoading(false);
      })
      .catch(() => {
        history.push("/login", { message: ErrorUnexpected, messageType: "error" });
      });
  }, []); // eslint-disable-line

  function updatePasswordRules(password) {
    setPasswordRules({
      size: password.length >= 12 && password.length <= 18, // if the password length is greater than 6 and less than 18
      lower: password.match("(?=.*[a-z])"), // if at least one lower case letter exists
      upper: password.match("[A-Z].*[A-Z]"), // if at least one upper case letter exists
      special: password.match("([!@#$%&*_])"), // if at least one non-word character exists
      number: password.match("\\d.*\\d"), // if at least two digit exists
    });
  }

  function handleSubmit(values, actions) {
    const token = query.get("t");
    values.token = token;

    AuthenticationService.resetPassword(values)
      .then(() => {
        history.push("/login", {
          message: "Password successfully reset.",
          messageType: "success",
        });
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          toast.error(ErrorUnexpected);
        } else if (error.response.data.fields) {
          const fields = error.response.data.fields;

          fields.forEach((field) => {
            actions.setFieldError(field.name, field.message);
          });
        } else if (error.response.data.title) {
          actions.setFieldError("password", error.response.data.title);
        } else {
          toast.error(ErrorUnexpected);
        }
      })
      .finally(() => actions.setSubmitting(false));
  }

  const schema = Yup.object().shape({
    password: Yup.string().required("Password is a required field"),
    passwordConfirmation: Yup.string()
      .required("Confirm Password is a required field")
      .oneOf([Yup.ref("password"), null], "The passwords don't match."),
  });

  if (loading) {
    <Loading />;
  }

  return (
    <LoginLayout>
      <div className={classes.box}>
        <Card className={classes.card}>
          <CardHeader
            title={
              <div>
                <Link to="/login" data-for="main" data-tip="BACK TO HOME">
                  <FiArrowLeft size={30} />
                </Link>
                <ReactTooltip id="main" effect="solid" place="bottom" />
              </div>
            }
          />
          <CardContent className={classes.cardContent}>
            <FiLock size={175} />
            <h1>Reset Password</h1>
            <p>Enter a new password for your account below.</p>
            <Formik
              initialValues={{
                password: "",
                passwordConfirmation: "",
              }}
              validationSchema={schema}
              onSubmit={handleSubmit}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ setFieldValue, isSubmitting }) => (
                <Form>
                  <div className="form-group">
                    <div className="input-group">
                      <Field
                        className="form-control"
                        name="password"
                        type={visibled.password ? "text" : "password"}
                        placeholder="New password"
                        autoComplete="password"
                        onChange={(ev) => {
                          setFieldValue("password", ev.target.value);
                          updatePasswordRules(ev.target.value);
                        }}
                        data-tip
                        data-for="newPasswordTooltip"
                      />
                      <div
                        className="input-group-append"
                        onClick={() =>
                          setVisibled({
                            ...visibled,
                            password: !visibled.password,
                          })
                        }
                      >
                        <span className="input-group-text">{visibled.password ? <FiEyeOff /> : <FiEye />}</span>
                      </div>
                    </div>
                    <ReactTooltip id="newPasswordTooltip" type="dark">
                      <div className="">
                        <span>Your password must contain the following:</span>
                        <br />
                        <br />
                        <span>
                          {passwordRules.size ? <FiCheck color="green" /> : <FiX color="red" />} 12-18 chatacters
                        </span>
                        <br />
                        <span>
                          {passwordRules.lower ? <FiCheck color="green" /> : <FiX color="red" />} At least 1 lowercase
                          letter
                        </span>
                        <br />
                        <span>
                          {passwordRules.upper ? <FiCheck color="green" /> : <FiX color="red" />} At least 2 uppercase
                          letter
                        </span>
                        <br />
                        <span>
                          {passwordRules.special ? <FiCheck color="green" /> : <FiX color="red" />} At least 1 special
                          character (!@#$%&*_).
                        </span>
                        <br />
                        <span>
                          {passwordRules.number ? <FiCheck color="green" /> : <FiX color="red" />} At least 2 number
                        </span>
                        <br />
                      </div>
                    </ReactTooltip>
                    <span className="text-danger">
                      <ErrorMessage name="password" />
                    </span>
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <Field
                        className="form-control"
                        name="passwordConfirmation"
                        type={visibled.confirmPassword ? "text" : "password"}
                        placeholder="Confirm new password"
                        autoComplete="password"
                      />
                      <div
                        className="input-group-append"
                        onClick={() =>
                          setVisibled({
                            ...visibled,
                            confirmPassword: !visibled.confirmPassword,
                          })
                        }
                      >
                        <span className="input-group-text">{visibled.confirmPassword ? <FiEyeOff /> : <FiEye />}</span>
                      </div>
                    </div>
                    <span className="text-danger">
                      <ErrorMessage name="passwordConfirmation" />
                    </span>
                  </div>
                  <div className="form-group">
                    <Button className="w-100" type="submit" disabled={isSubmitting}>
                      Reset Password
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </div>
    </LoginLayout>
  );
}

export default ChangePassword;
