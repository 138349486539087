import React, { useContext } from "react";
import { Context } from "../../context/Context";

/**
 * @author Thiago Guimarães
 * @date 27/01/2021
 */
function RoleControl({ children, roles }) {
  const { user } = useContext(Context);
  // Verifica se o usuario possui alguma ROLE, exigida para o conteudo.
  function isRoleAllowed(userRoles, routeRoles) {
    if (userRoles && routeRoles) {
      return (
        userRoles.filter((role) => routeRoles.includes(role.name)).length > 0
      );
    }

    return false;
  }

  if (!roles || isRoleAllowed(user.roles, roles)) {
    return <>{children}</>;
  }
  return <> </>;
}

export default RoleControl;
