import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  FiArrowLeft,
  FiCheck,
  FiDownload,
  FiEdit,
  FiTrash2,
  FiX,
} from "react-icons/fi";
import Moment from "react-moment";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  ErrorUnexpected,
  OperationSuccessfully,
} from "../../../../../const/Messages";
import { ADMIN } from "../../../../../const/Roles";
import * as ElementService from "../../../../../service/ElementService";
import * as NeLogsFileService from "../../../../../service/NeLogsFileService";
// import ElementUploadBatchModal from "./ElementUploadBatchModal";
// import ElementUploadModal from "./ElementUploadModal";
import { Context } from "../../../../../context/Context";

/**
 * @author Thiago Guimarães
 * @date 08/02/2021
 */
function ElementList() {
  const { user } = useContext(Context);
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [elementList, setElementList] = useState([]);
  // const [modal, setModal] = useState({
  //   show: false,
  //   element: { name: "", vendor: { name: "" }, elementType: { name: "" } },
  // });
  // const [modalBatch, setModalBatch] = useState(false);

  useEffect(() => {
    if (
      !location.state ||
      !location.state.vendorId ||
      !location.state.elementTypeId
    ) {
      history.push("/settings/network-element");
    }

    ElementService.getElementsLastUploadBy(
      location.state.elementTypeId,
      location.state.vendorId
    )
      .then((elements) => setElementList(elements))
      .catch(() => toast.error(ErrorUnexpected))
      .finally(() => setIsLoading(false));
  }, []); // eslint-disable-line

  function downloadLastLog(element) {
    NeLogsFileService.downloadLastLogByElement(element.elementId).catch(
      (error) => {
        if (error && error.response && error.response.status === 404) {
          toast.error("Unable to download ");
        } else {
          toast.error(ErrorUnexpected);
        }
      }
    );
  }

  // function handleOpenModal(element) {
  //   setModal({ show: true, element });
  // }

  // function handleOpenBatchModal() {
  //   setModalBatch(true);
  // }

  function handleDelete(element) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: `Are you sure you want to delete ${element.elementName}? This will permanently delete the element data in the system.`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        ElementService.deleteById(element.elementId)
          .then(() => {
            history.push("/settings/network-element", {
              elementTypeId: element.elementTypeId,
              vendorId: element.vendorId,
              message: OperationSuccessfully,
              messageType: "success",
            });
          })
          .catch(() => {
            toast.error(ErrorUnexpected);
          });
      }
    });
  }

  const columns = [
    {
      title: "Vendor",
      field: "vendorName",
    },
    {
      title: "Element Type",
      field: "elementTypeName",
    },
    {
      title: "Element Name",
      field: "elementName",
    },
    {
      title: "Active",
      field: "active",
      customSort: (obj1, obj2) => {
        if (obj1.active === obj2.active) {
          return 0;
        } else if (obj1.active) {
          return -1;
        } else {
          return 1;
        }
      },
      render: (obj) => {
        return obj.active ? <FiCheck color="green" /> : <FiX color="red" />;
      },
    },
    {
      title: "Last Upload",
      field: "lastUpload",
      render: (row) =>
        row.lastUpload && (
          <Moment format="DD/MM/yyyy HH:mm:ss">{row.lastUpload}</Moment>
        ),
    },
  ];

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between align-items-center">
            <h5 className="d-flex align-items-center">
              <FiArrowLeft
                className="mr-2"
                size="22"
                color="gray"
                style={{ verticalAlign: "middle", cursor: "pointer" }}
                onClick={() => history.push("/settings/network-element")}
              />
              <span>Network Element - List</span>
            </h5>
            <div>
              {/* <Button
                className="mr-2"
                variant="primary"
                type="button"
                onClick={() => handleOpenBatchModal()}
              >
                Batch Upload
              </Button> */}
              <Link
                className="btn btn-primary"
                to="/settings/network-element/reg"
              >
                New Element
              </Link>
            </div>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={elementList}
              isLoading={isLoading}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                rowStyle: {
                  fontSize: 13,
                },
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "elements",
              }}
              actions={[
                {
                  icon: () => <FiEdit size={16} color="#007bff" />,
                  tooltip: "EDIT",
                  onClick: (event, rowData) =>
                    history.push("/settings/network-element/reg", {
                      id: rowData.elementId,
                    }),
                },
                // {
                //   icon: () => <FiUpload size={16} color="#007bff" />,
                //   tooltip: "UPLOAD",
                //   onClick: (event, rowData) => handleOpenModal(rowData),
                // },
                {
                  icon: () => <FiDownload size={16} color="#007bff" />,
                  tooltip: "DOWNLOAD LOG",
                  onClick: (event, rowData) => downloadLastLog(rowData),
                },
                () => {
                  if (user.roles.map((role) => role.name).includes(ADMIN)) {
                    return {
                      icon: () => <FiTrash2 size={16} color="#007bff" />,
                      tooltip: "DELETE",
                      onClick: (event, rowData) => handleDelete(rowData),
                    };
                  }
                },
              ]}
            />
            {/* <ElementUploadModal modal={modal} setModal={setModal} />
            <ElementUploadBatchModal
              modal={modalBatch}
              setModal={setModalBatch}
            /> */}
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ElementList;
