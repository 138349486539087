import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "80vh",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(20px + 0.5vmin)",
    color: "#808080",
  },
  sorryText: {
    textAlign: "center",
  },
  buttonHome: {
    marginTop: 25,
  },
});

function PageNotFound() {
  const history = useHistory();
  const classes = useStyles();
  const [time, setTime] = useState(5);
  const countdownTimeOut = setTimeout(() => setTime(time - 1), 1000);

  useEffect(() => {
    if (time === 0) {
      clearTimeout(countdownTimeOut);
      history.push("/");
    }
  }, [time, countdownTimeOut]); // eslint-disable-line

  function handleHomeButton() {
    history.push("/");
  }

  return (
    <Box className={classes.container}>
      <Card>
        <CardContent className={classes.card}>
          <Typography variant="h1">404</Typography>
          <Typography variant="h3">Page Not Found</Typography>
          <Typography variant="p" className={classes.sorryText}>
            Sorry, but the page you are looking for has not been found.
          </Typography>
          <Button variant="contained" color="primary" className={classes.buttonHome} onClick={handleHomeButton}>
            Back to home (00:0{time})
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

export default PageNotFound;
