import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  ErrorUnexpected,
  OperationSuccessfully,
} from "../../../../../const/Messages";
import * as OrganisationService from "../../../../../service/OrganisationService";

/**
  @author Guilherme Almeida
  @date 10/02/2021
 */
export default function ManagementOrganisationList() {
  const history = useHistory();
  const [organisation, setOrganisation] = useState([]);

  useEffect(() => {
    OrganisationService.findAll()
      .then((organisationList) => {
        setOrganisation(
          organisationList.sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          })
        );
      })
      .catch(() => {
        toast.error(ErrorUnexpected);
      });
  }, []); // eslint-disable-line

  function handleDelete(id) {
    Swal.fire({
      icon: "warning",
      title: "Confirmation",
      text: "Are you sure you want to delete?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: true,
    }).then((result) => {
      if (result.isConfirmed) {
        OrganisationService.deleteById(id)
          .then(() => {
            OrganisationService.findAll()
              .then((organisationList) => {
                setOrganisation(organisationList);
                toast.success(OperationSuccessfully);
              })
              .catch(() => {
                toast.error(ErrorUnexpected);
              });
          })
          .catch(() => {
            toast.error(ErrorUnexpected);
          });
      }
    });
  }

  const columns = [
    {
      title: "ORGANISATION",
      field: "name",
    },
  ];

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title d-flex justify-content-between align-items-center">
            <h5>Organisation - List</h5>
            <div>
              <Link
                className="btn btn-primary"
                to={{
                  pathname: "/ir21/settings/organisations/reg",
                  state: { id: "" },
                }}
              >
                New Organisation
              </Link>
            </div>
          </div>
          <div className="ibox-content">
            <MaterialTable
              title=""
              columns={columns}
              data={organisation}
              components={{
                Container: (props) => <Paper {...props} elevation={0} />,
              }}
              options={{
                pageSize: 10,
                pageSizeOptions: [5, 10, 15, 20],
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                searchFieldAlignment: "left",
                toolbarButtonAlignment: "right",
                exportFileName: "organisation",
                headerStyle: {
                  textTransform: "uppercase",
                },
              }}
              actions={[
                {
                  icon: () => <FiEdit size={16} color="#007bff" />,
                  tooltip: "EDIT",
                  onClick: (event, rowData) =>
                    history.push("/ir21/settings/organisations/reg", {
                      id: rowData.id,
                    }),
                },
                {
                  icon: () => <FiTrash2 size={16} color="#007bff" />,
                  tooltip: "DELETE",
                  onClick: (event, rowData) => handleDelete(rowData.id),
                },
              ]}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}
