import React from "react";
import LogoAlta from "../../assets/logo_jpg_alta.png";
import BackgroundLogin from "../../assets/wallpaper_login.jpg";
import { makeStyles } from "@material-ui/core";
import { CopyRightMessage } from "../../const/Messages";
import { findByLabelText } from "@testing-library/react";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: "100vw",
      minHeight: "700px",
      height: "100%",
    },
    topBox: {
      height: "100px",
      width: "100%",
      background: "#FFFFFF",

      "& img": {
        maxHeight: "98px",
        height: "100%",
        position: "absolute",

        [theme.breakpoints.up("sm")]: {
          left: "calc(100vw - 90%)",
        },
        [theme.breakpoints.down("xs")]: {
          left: "calc(100vw - 50%)",
          transform: "translateX(-50%)",
        },
      },
    },
    box: {
      height: "calc(100% - 100px)",
      width: "100%",

      background: `url(${BackgroundLogin}) no-repeat center center fixed`,
      backgroundSize: "cover",
    },
    footerBar: {
      padding: "5px 15px",

      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      display: "flex",
      justifyContent: "center",

      color: "#000",
      background: "#FFF",
    },
    logo: {
      width: "250px",
      objectFit: "cover"
    },
  };
});
function LoginLayout({children}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.topBox}>
        <img src={LogoAlta} className={classes.logo} alt="Logo vertis" />
      </div>
      <div className={classes.box}>{children}</div>
      <div className={classes.footerBar}>
        <span>{CopyRightMessage}</span>
      </div>
    </div>
  );
}

export default LoginLayout;
