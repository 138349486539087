import { useState, useEffect } from "react";
import api from "../service/api";
import * as UserService from "../service/UserService";
import { toast } from "react-toastify";

export default function useAuth() {
  const tokenKey = "@VRAT-TOKEN";
  const userInitialValue = {
    id: null,
    name: "",
    email: "",
    roles: [],
  };

  const [signed, setSigned] = useState(false);
  const [user, setUser] = useState(userInitialValue);
  const [loading, setLoading] = useState(true);
  const [sessionExpiredInterceptor, setSessionExpiredInterceptor] = useState("");

  useEffect(() => {
    const localToken = localStorage.getItem(tokenKey);

    if (localToken) {
      // Adiciona o Token como default HEADER de todas as requisições
      api.defaults.headers.Authorization = `Bearer ${localToken}`;

      // Define caso ocorra algum erro de 403 faz logout da aplicação.
      setSessionExpiredInterceptor(api.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          if (error && error.response && error.response.status && error.response.status === 403) {
            toast.error("The session has expired", {toastId : "SESSION_EXPIRED"});
            handleLogout();
          }
          return Promise.reject(error);
        }
      ));

      // Verifica se o Token ainda é valido.
      UserService.validUser()
        .then((user) => {
          // Define usuario como logado
          setSigned(true);

          // Adiciona o user na Session (Auth Provider)
          setUser(user);

          // Termina a execução.
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      // Termina a execução, como não tem Token.
      setLoading(false);
    }
  }, []); // eslint-disable-line

  function handleLogin(email, password, recaptchaToken) {
    return new Promise((resolve, reject) => {
      api({
        method: "post",
        url: "/auth",
        data: {
          email,
          password,
        },
        headers: {
          "g-recaptcha-response": recaptchaToken,
        },
      })
        .then((response) => {
          const { token, userAccount } = response.data;

          // Adiciona o Token no Local Storage
          localStorage.setItem(tokenKey, token);

          // Adiciona o Token como default HEADER de todas as requisições
          api.defaults.headers.Authorization = `Bearer ${token}`;

          // Define caso ocorra algum erro de 403 faz logout da aplicação.
          setSessionExpiredInterceptor(api.interceptors.response.use(
            function (response) {
              return response;
            },
            function (error) {
              if (error && error.response && error.response.status && error.response.status === 403) {
                toast.error("The session has expired", {toastId : "SESSION_EXPIRED"});
                handleLogout();
              }
              return Promise.reject(error);
            }
          ));

          // Define usuario como logado
          setSigned(true);

          // Adiciona o user na Session (Auth Provider)
          setUser(userAccount);

          // Finaliza a função executando o callback passando o usuario como parametro
          resolve(userAccount);

          // Termina a execução.
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          reject(error);
        });
    });
  }

  function handleLogout() {
    return new Promise((resolve, reject) => {
      // Define usuario deslogado
      setSigned(false);

      // Remove o user da Session (Auth Provider)
      setUser({});

      // Remove o Token do Local Storage
      localStorage.removeItem(tokenKey);

      // Remove o Token do HEADER das requisições
      api.defaults.headers.Authorization = undefined;

      // Remove o interceptor de SESSION_EXPIRED
      api.interceptors.response.eject(sessionExpiredInterceptor);

      // Chama o resolve para finalizar a função.
      resolve();
    });
  }

  return { signed, user, setUser, loading, handleLogin, handleLogout };
}
