import React, { useState } from "react";
import { Box, IconButton, makeStyles, TextField, Tooltip } from "@material-ui/core";
import { Field, useFormikContext } from "formik";
import { FiPlus, FiMinus } from "react-icons/fi";
import * as Yup from "yup";

export const globalTitleSchema = Yup.object().shape({
  e164NumberSeriesList: Yup.array()
    .of(
      Yup.object().shape({
        cc: Yup.string()
          .test(
            "invalid",
            "CC must be a valid value, between 0 and 9999999",
            (value) => !value || (Number.isInteger(Number(value)) && Number(value) >= 0 && Number(value) <= 9999999)
          )
          .test("invalid", "CC is a required field", function (value) {
            return (!value && !this.parent.ndc) || (value && this.parent.ndc) || (value && !this.parent.ndc);
          })
          .label("CC"),
        ndc: Yup.string()
          .test(
            "invalid",
            "NDC must be a valid value, between 0 and 9999999",
            (value) => !value || (Number.isInteger(Number(value)) && Number(value) >= 0 && Number(value) <= 9999999)
          )
          .test("invalid", "NDC is a required field", function (value) {
            return (!value && !this.parent.cc) || (value && this.parent.cc) || (value && !this.parent.cc);
          })
          .label("NDC"),
        rangeStart: Yup.string()
          .nullable()
          .test(
            "invalid",
            "RANGE START must be a valid numeric value",
            (value) => !value || value.length === 0 || Number.isInteger(Number(value))
          )
          .test("invalid", "CC and NDC are required fields", function (value) {
            return !value || (this.parent.cc && this.parent.ndc);
          })
          .label("RANGE START"),
        rangeStop: Yup.string()
          .nullable()
          .test(
            "invalid",
            "RANGE STOP must be a valid numeric value",
            (value) => !value || value.length === 0 || Number.isInteger(Number(value))
          )
          .test("invalid", "CC and NDC are required fields", function (value) {
            return !value || (this.parent.cc && this.parent.ndc);
          })
          .label("RANGE STOP"),
      })
    ),
});

export const globalTitleInitialValues = {
  e164NumberSeriesList: [{ cc: "", ndc: "", rangeStart: "", rangeStop: "" }],
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",

    "& div": {
      flex: 1,
      marginLeft: 5,
    },

    "& + div": {
      marginTop: 15,
    },
  },
  input: {
    "& + div": {
      marginTop: 15,
    },
  },
});

function Ir21TemplateGlobalTitle() {
  const { errors, values, setFieldValue } = useFormikContext();
  const classes = useStyles();
  const [extraInputs, setExtraInputs] = useState(
    values?.e164NumberSeriesList?.length > 0 ? values.e164NumberSeriesList.length - 1 : 0
  );

  function handleAddInput() {
    const array = values.e164NumberSeriesList;
    array.push({ cc: "", ndc: "", rangeStart: "", rangeStop: "" });

    setFieldValue("e164NumberSeriesList", array);
    setExtraInputs((current) => current + 1);
  }

  function handleRemoveInput() {
    const array = values.e164NumberSeriesList;
    array.pop();

    setFieldValue("e164NumberSeriesList", array);
    setExtraInputs((current) => current - 1);
  }

  function hasError(arrayIndex, key) {
    if (errors) {
      if (errors.e164NumberSeriesList) {
        if (errors.e164NumberSeriesList[arrayIndex] === "string") {
          return true;
        } else if (errors.e164NumberSeriesList[arrayIndex] && errors.e164NumberSeriesList[arrayIndex][key]) {
          return true;
        }
      }
    }
    return false;
  }

  function getErrorMessage(arrayIndex, key) {
    if (errors) {
      if (errors.e164NumberSeriesList) {
        if (typeof errors.e164NumberSeriesList === "string") {
          return errors.e164NumberSeriesList;
        } else if (errors.e164NumberSeriesList[arrayIndex] && errors.e164NumberSeriesList[arrayIndex][key]) {
          return errors.e164NumberSeriesList[arrayIndex][key];
        }
      }
    }

    return "";
  }

  return (
    <>
      <TextField
        label="TADIG"
        defaultValue=" "
        value={values.plmnName}
        className={classes.input}
        disabled
        variant="outlined"
      />
      <Box className={classes.container}>
        <Field
          as={TextField}
          label="CC"
          name="e164NumberSeriesList[0].cc"
          variant="outlined"
          error={hasError(0, "cc")}
          helperText={getErrorMessage(0, "cc")}
        />
        <Field
          as={TextField}
          label="NDC"
          name="e164NumberSeriesList[0].ndc"
          variant="outlined"
          error={hasError(0, "ndc")}
          helperText={getErrorMessage(0, "ndc")}
        />
        <Field
          as={TextField}
          label="RANGE START"
          name="e164NumberSeriesList[0].rangeStart"
          variant="outlined"
          error={hasError(0, "rangeStart")}
          helperText={getErrorMessage(0, "rangeStart")}
        />
        <Field
          as={TextField}
          label="RANGE STOP"
          name="e164NumberSeriesList[0].rangeStop"
          variant="outlined"
          error={hasError(0, "rangeStop")}
          helperText={getErrorMessage(0, "rangeStop")}
        />
        <Tooltip title="ADD MORE APNS">
          <IconButton onClick={handleAddInput}>
            <FiPlus size="1.5rem" />
          </IconButton>
        </Tooltip>
      </Box>
      {[...Array(Number(extraInputs))].map((value, index) => (
        <Box key={index} className={classes.container} style={{ marginRight: index + 1 === extraInputs ? 0 : 48 }}>
          <Field
            as={TextField}
            label="CC"
            name={`e164NumberSeriesList[${index + 1}].cc`}
            variant="outlined"
            error={hasError(index + 1, "cc")}
            helperText={getErrorMessage(index + 1, "cc")}
          />
          <Field
            as={TextField}
            label="NDC"
            name={`e164NumberSeriesList[${index + 1}].ndc`}
            variant="outlined"
            error={hasError(index + 1, "ndc")}
            helperText={getErrorMessage(index + 1, "ndc")}
          />
          <Field
            as={TextField}
            label="RANGE START"
            name={`e164NumberSeriesList[${index + 1}].rangeStart`}
            variant="outlined"
            error={hasError(index + 1, "rangeStart")}
            helperText={getErrorMessage(index + 1, "rangeStart")}
          />
          <Field
            as={TextField}
            label="RANGE STOP"
            name={`e164NumberSeriesList[${index + 1}].rangeStop`}
            variant="outlined"
            error={hasError(index + 1, "rangeStop")}
            helperText={getErrorMessage(index + 1, "rangeStop")}
          />
          {index + 1 === extraInputs && (
            <Tooltip title="REMOVE APN">
              <IconButton onClick={handleRemoveInput}>
                <FiMinus size="1.5rem" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ))}
    </>
  );
}

export default Ir21TemplateGlobalTitle;
