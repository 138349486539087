import { Box } from "@material-ui/core";
import { ListItem, Link } from "@material-ui/core"
import RoleControl from "../security/RoleControl";
import { CategoryTitle } from "./categoryTitle.js";

export function Category({ roles, title, links, path }) {
    return (
        <RoleControl roles={roles}>
            <ListItem>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                    <CategoryTitle title={title} />
                    {links ?
                        links.map((link, index) => (
                            <Link to={link.path} style={{ textDecoration: 'none', cursor: 'pointer' }}>
                                <a href={link.path} style={{ color: "gray", fontFamily: "'Quicksand', sans-serif", fontSize: "12px", marginLeft: "10px" }}>{`${link.text}`}</a>
                            </Link>
                        ))
                        :
                        <Link to={path} style={{ textDecoration: 'none', cursor: 'pointer' }}>
                            <a href={path} style={{ color: "gray", fontFamily: "'Quicksand', sans-serif", fontSize: "12px", marginLeft: "10px" }}>{`${title}`}</a>
                        </Link>
                    }
                </Box>
            </ListItem>
        </RoleControl>
    );
}





