import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { FiAlertTriangle, FiCrosshair, FiRefreshCw, FiZoomIn, FiZoomOut } from "react-icons/fi";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { ComposableMap, Geographies, Geography, Graticule, Marker, Sphere, ZoomableGroup } from "react-simple-maps";
import ReactTooltip from "react-tooltip";
import LoadingGif from "../../assets/loading.gif";
import * as CountryService from "../../service/CountryService";
import * as ElementTypeService from "../../service/ElementTypeService";
import * as HomeService from "../../service/HomeService";
import WorldMap from "./WorldMap.json";

/**
 * @author Guilherme Almeida
 * @date 25/02/2021
 */
let countdownTimeOut = null;
const timeToRefresh = 5 * 60;

export default function MapChart() {
  const history = useHistory();

  // Erros para exibição
  const [data, setData] = useState([]);

  // Filtros
  const [elementTypes, setElementTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [filters, setFilters] = useState({
    roamingType: "",
    accessType: "",
    domain: "",
    elementTypeId: "",
  });

  // Carregamento
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");

  // Map Label
  const [tooltipContent, setTooltipContent] = useState("");

  // Config Color
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  // Zoom Config
  const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });

  // Automatic/ Manual Update
  const [time, setTime] = useState(timeToRefresh);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (!isActive) {
      if (!loading) {
        setLoading(true);
        setErrors("");

        HomeService.getDashboardWorldData(filters)
          .then((resultList) => {
            setData(resultList);

            let maxValueTemp = Number.MIN_SAFE_INTEGER;
            let minValueTemp = Number.MAX_SAFE_INTEGER;
            resultList.forEach(({ errors }) => {
              if (errors > maxValueTemp) maxValueTemp = errors;
              if (errors < minValueTemp) minValueTemp = errors;
            });

            setMaxValue(maxValueTemp);
            setMinValue(minValueTemp);
          })
          .catch(() => setErrors("Something went wrong. Try again!!"))
          .finally(() => {
            clearTimeout(countdownTimeOut);
            setIsActive(true);
            setTime(timeToRefresh);
            setLastUpdate(new Date());
            setLoading(false);
          });
      }
    } else if (isActive && time > 0) {
      countdownTimeOut = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    } else if (isActive && time === 0 && !loading) {
      setIsActive(false);
    }

    return () => {
      clearTimeout(countdownTimeOut);
    };
  }, [isActive, time]); // eslint-disable-line

  // Carrega as listas de países e element types.
  useEffect(() => {
    CountryService.findAll()
      .then((list) => setCountries(list))
      .catch(() => {});

      ElementTypeService.findAll()
      .then(list => setElementTypes(list))
      .catch( () => {});

  }, []); // eslint-disable-line

  // Caso o filtro sofra uma alteração é preciso fazer o Refresh do Mapa.
  useEffect(() => {
    setTime(0);
  }, [filters]); // eslint-disable-line

  // Separa os minutos em duas unidades,colocando 0 no inicio quando necessario.
  const minuteLeftRight = String(Math.floor(time / 60))
    .padStart(2, "0")
    .split("");

  // Separa os segundos em duas unidades,colocando 0 no inicio quando necessario.
  const secondsLeftRight = String(time % 60)
    .padStart(2, "0")
    .split("");

  function colorOpacityLevel(value) {
    let opacityLevel = 1;

    if (maxValue - minValue !== 0) {
      opacityLevel = 0.4 + (1.5 * (value - minValue)) / (maxValue - minValue);
    }

    return opacityLevel;
  }

  function handleZoomIn() {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  }

  function handleCenter() {
    setPosition((pos) => ({ ...pos, coordinates: [0, 0], zoom: 0.9 }));
  }

  function handleMoveEnd(position) {
    setPosition(position);
  }

  function handleChangeFilters(ev) {
    setFilters({ ...filters, [ev.target.name]: ev.target.value });
  }

  function handleSelectedCountry(ev) {
    if (ev.target.value) {
      setSelectedCountry(countries.find((country) => country.id === ev.target.value));
    }
  }

  return (
    <div className="col">
      <div className="ibox">
        <div className="ibox-title d-flex flex-column">
          <h5 className="mb-1">Countries with issues</h5>
          <span className="text-muted" style={{ fontSize: "60%" }}>
            Last Update: <Moment format="DD/MM/yyyy HH:mm:ss">{lastUpdate}</Moment>
          </span>
        </div>
        <div className="ibox-title">
          <FormControl>
            <InputLabel>Element Type</InputLabel>
            <Select className="minw-200" value={filters.elementTypeId} name="elementTypeId" onChange={handleChangeFilters}>
              <MenuItem value="">All</MenuItem>
              {elementTypes.map( elementType => (
                <MenuItem value={elementType.id}>{elementType.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className="ml-xl-4">
            <InputLabel>Roaming Type</InputLabel>
            <Select className="minw-200" value={filters.roamingType} name="roamingType" onChange={handleChangeFilters}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="INBOUND">INBOUND</MenuItem>
              <MenuItem value="OUTBOUND">OUTBOUND</MenuItem>
            </Select>
          </FormControl>
          {/* <FormControl className="ml-xl-4">
            <InputLabel>Access Type</InputLabel>
            <Select className="minw-200" value={filters.accessType} name="accessType" onChange={handleChangeFilters}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="DOISG_TRESG">2G/3G</MenuItem>
              <MenuItem value="QUATROG">4G</MenuItem>
            </Select>
          </FormControl>
          <FormControl className="ml-xl-4">
            <InputLabel>Domain</InputLabel>
            <Select className="minw-200" value={filters.domain} name="domain" onChange={handleChangeFilters}>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="PACKET">PACKET</MenuItem>
              <MenuItem value="CIRCUIT">CIRCUIT</MenuItem>
            </Select>
          </FormControl> */}
          <FormControl className="ml-xl-4">
            <InputLabel>Country</InputLabel>
            <Select className="minw-200" value={selectedCountry.id} name="countryId" onChange={handleSelectedCountry}>
              <MenuItem value="">All</MenuItem>
              {countries.map((country) => (
                <MenuItem value={country.id} key={country.id}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="ibox-content">
          {loading ? (
            <div className="text-center pt-5 pb-5">
              <img src={LoadingGif} alt="Loading" width="100px" />
            </div>
          ) : (
            <>
              <ComposableMap data-tip="" width={1400} height={500} style={{ width: "100%", height: "auto" }}>
                <ZoomableGroup zoom={position.zoom} center={position.coordinates} onMoveEnd={handleMoveEnd}>
                  <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
                  <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
                  <Geographies geography={WorldMap}>
                    {({ geographies }) =>
                      geographies.map((geo) => {
                        const country = data.find((s) => s.country === geo.properties.ISO_A2);

                        return (
                          <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            fill={country ? "#db3522" : "#1baf2a"}
                            fillOpacity={country ? colorOpacityLevel(country.errors) : "1"}
                            onMouseEnter={() => {
                              setTooltipContent(` ${geo.properties.NAME} - ${country ? country.errors : 0}`);
                            }}
                            onMouseLeave={() => {
                              setTooltipContent("");
                            }}
                            onClick={() => {
                              if (country) {
                                history.push("/report/country-view", { countryId: country.countryId, filters });
                              }
                            }}
                            style={{
                              default: {
                                outline: "none",
                              },
                              hover: {
                                fill: "#ccc",
                                outline: "none",
                                cursor: country ? "pointer" : "",
                              },
                              pressed: {
                                fill: "#E42",
                                outline: "none",
                              },
                            }}
                          />
                        );
                      })
                    }
                  </Geographies>
                  <Marker key={selectedCountry.name} coordinates={[selectedCountry.longitude, selectedCountry.latitude]}>
                    <g
                      fill="none"
                      stroke="blue"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      transform="translate(-12, -24)"
                    >
                      <circle cx="12" cy="10" r="3" />
                      <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                    </g>
                    <text textAnchor="middle" y={-30} style={{ fontFamily: "system-ui", fill: "black" }}>
                      {selectedCountry.name}
                    </text>
                  </Marker>
                </ZoomableGroup>
              </ComposableMap>
              <ReactTooltip>{tooltipContent}</ReactTooltip>
            </>
          )}
          <div className="row">
            <div className="col">
              {minValue !== Number.MAX_SAFE_INTEGER && maxValue !== Number.MIN_SAFE_INTEGER && (
                <div className="w-50">
                  <div className="d-flex justify-content-between">
                    <strong>{minValue}</strong>
                    <strong>{maxValue}</strong>
                  </div>
                  <div
                    className="legend-bar"
                    style={{
                      height: 10,
                      backgroundImage: `linear-gradient(to right, rgba(219,53,34,${colorOpacityLevel(minValue)}), rgba(219,53,34,${colorOpacityLevel(maxValue)}))`,
                    }}
                  />
                </div>
              )}
            </div>
            <div className="col">
              {errors && (
                <div className="text-center">
                  <span className="text-danger">
                    <FiAlertTriangle size="30" /> {errors}
                  </span>
                </div>
              )}
              <div className="controls d-flex justify-content-center">
                <button onClick={handleZoomIn} className="btn btn-primary mr-2" data-tip data-for="zoomin">
                  <FiZoomIn />
                  <ReactTooltip id="zoomin" type="dark">
                    <span>Zoom in</span>
                  </ReactTooltip>
                </button>
                <button onClick={handleZoomOut} className="btn btn-primary mr-2" data-tip data-for="zoomout">
                  <FiZoomOut />
                  <ReactTooltip id="zoomout" type="dark">
                    <span>Zoom Out</span>
                  </ReactTooltip>
                </button>
                <button onClick={handleCenter} className="btn btn-primary mr-2" data-tip data-for="centralize">
                  <FiCrosshair />
                  <ReactTooltip id="centralize" type="dark">
                    <span>Centralize</span>
                  </ReactTooltip>
                </button>
                <button onClick={() => setTime(0)} className="btn btn-primary" data-tip data-for="refresh">
                  <FiRefreshCw />
                  <ReactTooltip id="refresh" type="dark">
                    <span>
                      Refresh ({`${minuteLeftRight[0]}${minuteLeftRight[1]}:${secondsLeftRight[0]}${secondsLeftRight[1]}`})
                    </span>
                  </ReactTooltip>
                </button>
              </div>
            </div>
            <div className="col"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
