import React from "react";
import Routes from "./routes/Routes";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/Context";
import { ToastContainer } from "react-toastify";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-perfect-scrollbar/dist/css/styles.css";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#017BFE" },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
