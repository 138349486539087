import React from "react";
import ReactTooltip from "react-tooltip";
import { Card, CardContent, CardHeader, makeStyles } from "@material-ui/core";
import { FiArrowLeft, FiCheckCircle } from "react-icons/fi";
import { Link } from "react-router-dom";
import LoginLayout from "../../../components/LoginLayout/LoginLayout";

const useStyles = makeStyles((theme) => {
  return {
    box: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    card: {
      maxWidth: 600,
      maxHeight: 375,
      width: "96%",
      height: "100%",
    },
    cardContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",

      "& p":{
        textAlign: "center",
      }
    },
  };
});
function GenerateTokenSuccess() {
  const classes = useStyles();

  return (
    <LoginLayout>
      <div className={classes.box}>
        <Card className={classes.card}>
          <CardHeader
            title={
              <div>
                <Link to="/login" data-for="main" data-tip="BACK TO HOME">
                  <FiArrowLeft size={30} />
                </Link>
                <ReactTooltip id="main" effect="solid" place="bottom" />
              </div>
            }
          />
          <CardContent className={classes.cardContent}>
            <FiCheckCircle size={175} color="green" />
            <h1>Success!</h1>
            <p>Instructions for resetting your password have been sent to your email, check your inbox.</p>
          </CardContent>
        </Card>
      </div>
    </LoginLayout>
  );
}

export default GenerateTokenSuccess;
