import React, { useEffect, useState, useRef } from 'react';
import Loading from "../../../Common/Loading/Loading";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import { ErrorUnexpected, OperationCancelled, OperationSuccessfully } from "../../../../const/Messages";
import * as Yup from "yup";
import * as Ir21AutomaticSetupService from "../../../../service/Ir21AutomaticSetupService";
import {IoMdAlert} from "react-icons/io";
import { IconTooltip } from '../../../../components/Utils/IconTooltip';

/**
 *  @author Thiago Guimarães
 *  @date 05/07/2021
 */
function Ir21AutomaticSetup() {
  const history = useHistory();
  const formikRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editApiKey, setEditApiKey] = useState(false);
  const [editUserToken, setEditUserToken] = useState(false);
  const [values, setValues] = useState({
    apiKey: "",
    userToken: "",
    executionHour: "",
    userName: "",
    tadig: "",
    enabled: "false",
    notificationEmail: "",
  });


  useEffect(() => {
    Ir21AutomaticSetupService.find()
      .then((setup) => {
        const data = { ...setup, enabled: setup.enabled ? "true" : "false" };
        setValues(data);
      })
      .catch((error) => {

        if(error?.response?.status === 404 ){
          setEditApiKey(true);
          setEditUserToken(true);
        }else{
          toast.error(ErrorUnexpected)
        }
      })
      .finally(() => setIsLoading(false));
  }, []);

  const schema = Yup.object().shape({
    enabled: Yup.string().required("Enabled is a required field"),
    executionHour: Yup.number()
      .min(0, "Daily execution at time must be greater than or equal to 0")
      .max(23, "Daily execution at time must be less than or equal to 23")
      .required("Daily execution at time is a required field"),
    apiKey: Yup.string().required("API Key is a required field"),
    userToken: Yup.string().required("User Token is a required field"),
    userName: Yup.string().required("User Name is a required field"),
    tadig: Yup.string().required("MAIN TADIG is a required field"),
    notificationEmail: Yup.string().email("Must be a valid email.").nullable(),
  });

  function handleSave(values, actions) {
    Ir21AutomaticSetupService.merge(values)
      .then(() => {
        history.go(0);
        toast.success(OperationSuccessfully);
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          toast.error(ErrorUnexpected);
        } else if (error.response.data.fields) {
          error.response.data.fields.forEach((field) => {
            actions.setFieldError(field.name, field.message);
          });
        } else if (error.response.data.title) {
          actions.setFieldError("apiKey", error.response.data.title);
        } else {
          toast.error(ErrorUnexpected);
        }
      })
      .finally(() => actions.setSubmitting(false));
  }

  function handleEditApiKey(){
    formikRef.current.setFieldValue('apiKey', "");
    setEditApiKey(true);
  }

  function handleEditUserKey(){
    formikRef.current.setFieldValue('userToken', "");
    setEditUserToken(true);
  }

  function handleCancel(){
    history.push("/", {message: OperationCancelled, messageType: "info"});
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="ibox">
      <div className="ibox-title">
        <h5>IR.21 - Automatic Sync Setup</h5>
      </div>
      <div className="ibox-content">
        <Formik innerRef={formikRef} initialValues={values} validationSchema={schema} onSubmit={handleSave}>
          {({ isSubmitting }) => (
            <Form autocomplete="off">
              <div className="form-group row">
                <label htmlFor="elementType" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                  Enabled:
                </label>
                <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                  <div className="form-check form-check-inline">
                    <Field type="radio" name="enabled" value="true" id="boxTrue" className="form-check-input"></Field>
                    <label className="form-check-label" htmlFor="boxTrue">
                      TRUE
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <Field type="radio" name="enabled" value="false" id="boxFalse" className="form-check-input"></Field>
                    <label className="form-check-label" htmlFor="boxFalse">
                      FALSE
                    </label>
                  </div>
                  <span className="text-danger">
                    <ErrorMessage name="enabled" />
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="apiKey" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                  Daily execution at time:
                </label>
                <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                  <Field name="executionHour" className="form-control" type="number" placeholder="(0 - 23)"></Field>
                  <span className="text-danger">
                    <ErrorMessage name="executionHour" />
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="apiKey" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                  API Key:
                </label>
                <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                  {editApiKey ? (
                    <Field name="apiKey" className="form-control" type="password" autoComplete="off"></Field>
                  ) : (
                    <Button onClick={handleEditApiKey} variant="link">
                      Change Api Key
                    </Button>
                  )}
                  <span className="text-danger">
                    <ErrorMessage name="apiKey" />
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="userToken" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                  User Token:
                </label>
                <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                  {editUserToken ? (
                    <Field name="userToken" className="form-control" type="password" autoComplete="off"></Field>
                  ) : (
                    <Button onClick={handleEditUserKey} variant="link">
                      Change User Token
                    </Button>
                  )}
                  <span className="text-danger">
                    <ErrorMessage name="userToken" />
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="userName" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                  User name:
                </label>
                <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                  <Field name="userName" className="form-control" autoComplete="off"></Field>
                  <span className="text-danger">
                    <ErrorMessage name="userName" />
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="tadig" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                  TADIG:
                </label>
                <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                  <Field name="tadig" className="form-control"></Field>
                  <span className="text-danger">
                    <ErrorMessage name="tadig" />
                  </span>
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="notificationEmail"
                  className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                  style={{ verticalAlign: "center" }}
                >
                  <IconTooltip
                    icon={<IoMdAlert color="#2CB3DE" size="16px" />}
                    title="Email used to report communication issues with the GSMA "
                  />
                  Alert email:
                </label>
                <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                  <Field name="notificationEmail" className="form-control"></Field>
                  <span className="text-danger">
                    <ErrorMessage name="notificationEmail" />
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button variant="primary" className="mr-2" onClick={handleCancel} disabled={isSubmitting}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Ir21AutomaticSetup;
