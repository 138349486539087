import React, { useState, useEffect } from "react";
import Loading from "../../../../../Common/Loading/Loading";
import { Col, Row, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as EricssonMscDefaultParametersService from "../../../../../../service/EricssonMscDefaultParametersService";
import { ErrorUnexpected, OperationSuccessfully } from "../../../../../../const/Messages";

const initialValues = {
  id: "",
  natureOfAddress: "",
  oba: "",
  bo: "",
  plmn: "",
  inoper: "",
  camel: "",
  camelSenderTadig: false,
  mapver: "",
  mapverOkayIfLogVersionGreaterThanRaexVersion: false,
  onlyCheckWithIR21: false,
  ignoredCasesLog: false,
  ignoredCasesIr21: false,
  
};

/**
  @author Guilherme Almeida
  @date 03/05/2021
 */
export default function EricssonMscDefaultParameters() {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
      EricssonMscDefaultParametersService.getEricssonMscDefaultParameters()
        .then((mscDefault) => {
          setValues(mscDefault);
          setLoading(false);
        })
        .catch(() => {
          history.push("/", { message: ErrorUnexpected, messageType: "error" });
        });
  }, []); // eslint-disable-line

  const schema = Yup.object().shape({
    natureOfAddress: Yup.number().required("Nature Of Address is a required field"),
    oba: Yup.number().required("OBA is a required field"),
    bo: Yup.number().required("BO is a required field"),
    plmn: Yup.number().required("Plmn is a required field"),
    inoper: Yup.number().required("Inoper is a required field"),
  });

  function handleSave(values, actions) {

    EricssonMscDefaultParametersService.update(values)
      .then(() => {
        history.push("/settings/report/template/msc/ericsson/default", { message: OperationSuccessfully, messageType: "success" });
      })
      .catch((error) => {
        if (!error || !error.response || !error.response.data) {
          actions.setFieldError("natureOfAddress", ErrorUnexpected);
        } else if (error.response.data.title) {
          actions.setFieldError("natureOfAddress", error.response.data.title);
        }
        actions.setSubmitting(false);
      });
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Row>
      <Col>
        <div className="ibox">
          <div className="ibox-title">
            <h5>Ericsson MSC Default Parameters</h5>
          </div>
          <div className="ibox-content">
            <Formik initialValues={values} validationSchema={schema} onSubmit={handleSave}>
              {() => (
                <Form>
                  <div className="form-group row">
                    <label
                      htmlFor="natureOfAddress"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Nature Of Address:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field
                        className="form-control"
                        name="natureOfAddress"
                        type="number"
                        placeholder="Nature Of Address"
                      />
                      <span className="text-danger">
                        <ErrorMessage name="natureOfAddress" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="oba" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      OBA:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field className="form-control" name="oba" type="number" placeholder="OBA" />
                      <span className="text-danger">
                        <ErrorMessage name="oba" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="bo" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      BO:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field className="form-control" name="bo" type="number" placeholder="BO" />
                      <span className="text-danger">
                        <ErrorMessage name="bo" />
                      </span>
                    </div>
                  </div> 
                  <div className="form-group row">
                    <label htmlFor="plmn" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      PLMN:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field className="form-control" name="plmn" type="number" placeholder="PLMN" />
                      <span className="text-danger">
                        <ErrorMessage name="plmn" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="inoper" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      INOPER:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field className="form-control" name="inoper" type="number" placeholder="INOPER" />
                      <span className="text-danger">
                        <ErrorMessage name="inoper" />
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="mapverOkayIfLogVersionGreaterThanRaexVersion"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      MAPVER:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field type="checkbox" name="mapverOkayIfLogVersionGreaterThanRaexVersion" />
                      <label className="form-check-label pl-2">
                        Check MAPVER OK when log version greater than ir21 version.
                      </label>
                      <span className="text-danger">
                        <ErrorMessage name="mapverOkayIfLogVersionGreaterThanRaexVersion" />
                      </span>
                    </div>
                  </div>
                  <div class="hr-line-dashed"></div>
                  <div class="pt-2 pb-2"><label>The following fields are verified from IR21 values. If any of these fields do not exist or are not filled in the  IR.21, the default values here defined will be assumed as a reference.</label></div>
                  <div className="form-group row">
                    <label htmlFor="camel" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      CAMEL:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <div>
                        <Field type="checkbox" name="camelSenderTadig" />
                        <label className="form-check-label pl-2 mb-2">
                            If exists, set SENDER TADIG value as default.
                        </label>
                        <span className="text-danger">
                          <ErrorMessage name="camelSenderTadig" />
                        </span>
                      </div>
                      <div>
                        <Field className="form-control" name="camel" type="number" placeholder="CAMEL" />
                        <span className="text-danger">
                          <ErrorMessage name="camel" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="mapver" className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1">
                      MAPVER:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field className="form-control" name="mapver" type="number" placeholder="MAPVER" />
                      <span className="text-danger">
                        <ErrorMessage name="mapver" />
                      </span>
                    </div>
                  </div>
                  <div class="hr-line-dashed"></div>
                  <div className="form-group row">
                    <label
                      htmlFor="onlyCheckWithIR21"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      IR.21:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <Field type="checkbox" name="onlyCheckWithIR21" />
                      <label className="form-check-label pl-2">
                        Check MSC fields only if IR21 file exists.
                      </label>
                      <span className="text-danger">
                        <ErrorMessage name="onlyCheckWithIR21" />
                      </span>
                    </div>
                  </div>
                  <div class="hr-line-dashed"></div>
                  <div className="form-group row">
                    <label
                      htmlFor="ignoredCases"
                      className="col-form-label col-sm-12 col-md-4 col-lg-4 col-xl-3 col-xxl-1"
                    >
                      Ignored Cases:
                    </label>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                      <div className="d-flex align-items-baseline">
                        <Field type="checkbox" name="ignoredCasesLog"/>
                          <label className="form-check-label pl-2">
                            Ignore IMSI value from LOG.
                          </label>
                        <span className="text-danger">
                          <ErrorMessage name="ignoredCasesLog" />
                        </span>
                      </div>
                      <div className="d-flex align-items-baseline">
                        <Field type="checkbox" name="ignoredCasesIr21" />
                        <label className="form-check-label pl-2">
                          Ignore IMSI Value from IR21.
                        </label>
                        <span className="text-danger">
                          <ErrorMessage name="ignoredCasesIr21" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Link className="mr-1 btn btn-primary" to="/settings">
                      Cancel
                    </Link>
                    <Button variant="primary" type="submit">
                      Update
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Col>
    </Row>
  );
}
