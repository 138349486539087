import api from "./api";

export function getAvailableLogsByElement(elementId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/logs/element/${elementId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function list() {
  return new Promise((resolve, reject) => {
    api
      .get("/logs")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function download(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/logs/download/${id}`, { responseType: "blob" })
      .then((response) => {
        let fileName = "file.txt";
        if (response.headers && response.headers["content-disposition"]) {
          fileName = response.headers["content-disposition"].split("filename=")[1].replaceAll('"', "").replaceAll("%20", " ");
        }

        const blob = new Blob([response.data], {
          type: "text/plain",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function downloadLastLogByElement(elementId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/logs/download/element/${elementId}`, { responseType: "blob" })
      .then((response) => {
        let fileName = "file.txt";
        if (response.headers && response.headers["content-disposition"]) {
          fileName = response.headers["content-disposition"].split("filename=")[1].replaceAll('"', "").replaceAll("%20", " ");
        }

        const blob = new Blob([response.data], {
          type: "text/plain",
        });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function upload(data) {
  return new Promise((resolve, reject) => {
    api
      .post("/logs/upload", data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function uploadBatch(data) {
  return new Promise((resolve, reject) => {
    api
      .post("/logs/upload/batch", data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function rerunLogById(id) {
  return new Promise((resolve, reject) => {
    api
      .put(`/logs/rerun/${id}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}

export function downloadSampleLogById(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/logs/sample/download/${id}`, { responseType: "blob" })
      .then((response) => {

        let fileName = "file.txt";
        if (response.headers && response.headers["content-disposition"]) {
          fileName = response.headers["content-disposition"].split("filename=")[1].replaceAll('"', "").replaceAll("%20", " ");
        }

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        // converte o blob em uma url
        const url = window.URL.createObjectURL(blob);

        // cria uma tag a para clicar e fazer o download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);

        // aciona o link
        link.click();

        // remove do dom
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        resolve();
      })
      .catch((error) => reject(error));
  });
}

export function findAllSample() {
  return new Promise((resolve, reject) => {
    api
      .get("/logs/sample")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
}
